import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { formatTime, subtractHours, moment, download } from '@utils';
import App from '@app';
import { Body } from '@components';
import { PageHeader, Button, Text, Dropdown, Menu, AudioPlayer, Column, Icon, Overlay } from '@components-teammove';
import { getHistory, sendMessage, getTemplates, resetHistory } from '@ducks/whatsapp';
import {
    ChatHeader, ChatMessages, ChatWrapper, UserName,
    UserProfilePicture, MessageBubble, MessageTimeAndStatus,
    MessageTime, MessageStatus, ChatInputContainer, ChatInput,
    ChatMessagesContainer, TemplateContainer, StyledImage, StyledVideo, TextImage,
    AudioMessageContainer, MessageTimeAndStatusAudio, AudioPlayerWrapper,
    MessageTimeAndStatusImage, ModalOverlay, ModalImage, ModalVideo, CloseButton,
    VideoContainer, PlayButton, PdfLink
} from './styles';

function WhatsApp() {
    const dispatch = useDispatch();
    const [newMessage, setNewMessage] = useState('');
    const [templateSid, setTemplateSid] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const infoUsuario = useSelector(({ login }) => login.get('infoUsuario'));
    const theme = useSelector(({ app }) => app.get('theme'));
    const messages = useSelector(({ whatsapp }) => whatsapp.get('chats'));
    const templates = useSelector(({ whatsapp }) => whatsapp.get('templates'));
    const [firstRender, setFirstRender] = useState(true);

  const location = useLocation();
  const { whatsAppInfo } = location.state || {};

  const messagesEndRef = useRef(null);

  useEffect(() => {
    dispatch(getHistory(infoUsuario.cdUsuario, whatsAppInfo.idContact, 40));
    dispatch(getTemplates());

    return () => dispatch(resetHistory());
  }, []);

  useEffect(() => {
    if (messagesEndRef.current && firstRender) {
      setTimeout(() => messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' }), 500);
      // setTimeout(() => messagesEndRef.current.scrollIntoView({ behavior: 'instant', block: 'end' }), 500); USAR COM WEBSOCKET
      // setFirstRender(false);
      // messagesEndRef.current.scrollIntoView({ behavior: 'instant', block: 'end' });
    }
  }, [messages]);

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            const messageBody = {
                userId: infoUsuario.cdUsuario,
                contactId: whatsAppInfo.idContact,
                userNumber: formatContactNumber(whatsAppInfo.phoneUser),
                contactNumber: formatContactNumber(whatsAppInfo.numberContact),
                content: newMessage.trim(),
                templateSid: templateSid || undefined,
                nameContact: whatsAppInfo.nameContact
            };

      dispatch(sendMessage(messageBody));
      setNewMessage('');
      setTemplateSid('');
    } else {
      dispatch(getHistory(infoUsuario.cdUsuario, whatsAppInfo.idContact, 40));
    }
  };

  const formatContactNumber = (number) => {
    const formattedNumber = number.startsWith('489') ? number.replace(/^489/, '48') : number;
    return `whatsapp:+55${formattedNumber}`;
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case 'queued':
        return 'clock-time-eight-outline';
      case 'sent':
        return 'check';
      case 'delivered':
      case 'read':
        return 'check-all';
      default:
        return null;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

    const handleTemplateSelect = (templateBody, sid) => {
        setNewMessage(templateBody);
        setTemplateSid(sid);
    };
  
  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  const handleVideoClick = (url) => {
    setSelectedVideo(url);
    setSelectedImage(null);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setSelectedVideo(null);
  };

  const menu = (
    <Menu>
      {templates.map((template, index) => (
        <Menu.Item
          key={index}
          onClick={() => handleTemplateSelect(template.types['twilio/text'].body, template.sid)}
        >
          {template.types['twilio/text'].body}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleDownloadPDF = (urlPdf, fileName) => {
    download(urlPdf,`${fileName}.pdf`); 
  };

    return (
        <App bgColor={theme['@global-background-color']}>
          <Body>
            <PageHeader title="Whatsapp" onBack={() => window.history.back()}>
              <ChatWrapper>
                <ChatHeader align="center">
                  <UserProfilePicture src="https://via.placeholder.com/50" alt="User" />
                  <UserName>{whatsAppInfo?.nameContact}</UserName>
      
                  <TemplateContainer>
                    <Dropdown overlay={menu} trigger={['click']}>
                      <Text>Templates</Text>
                    </Dropdown>
                  </TemplateContainer>
                </ChatHeader>
      
                <ChatMessagesContainer>
                  <ChatMessages>
                    {messages.map((message) => {
                      const isAudioMessage =
                        message.attachments &&
                        message.attachments.some((attachment) => attachment.type === 'audio');
      
                      if (isAudioMessage) {
                        const audioAttachment = message.attachments.find(
                          (attachment) => attachment.type === 'audio'
                        );
      
                        return (
                            <AudioMessageContainer key={message.id}>
                                <AudioPlayerWrapper>
                                    <AudioPlayer
                                      showOptions={false}
                                      widthContainer="100%"
                                      src={audioAttachment.url}
                                      downloadFileName={`Audio - ${moment().format('DD-MM-YYYY HH:mm:ss')}.mp3`}
                                    />
                                    <MessageTimeAndStatusAudio>
                                        <MessageTime>
                                            {subtractHours(formatTime(message.createdAt), 3)}
                                        </MessageTime>
                                        {message.direction === 'outbound-api' && (
                                            <MessageStatus
                                              status={message.receiptStatus}
                                              type={renderStatusIcon(message.receiptStatus)}
                                            />
                                        )}
                                    </MessageTimeAndStatusAudio>
                                </AudioPlayerWrapper>
                            </AudioMessageContainer>
                        );
                      }
      
                      return (
                        <MessageBubble
                          key={message.id}
                          isSender={message.direction === 'outbound-api'}
                          isImage={message.attachments && message.attachments.length > 0}
                        >
                          {message.attachments &&
                            message.attachments.length > 0 &&
                            message.attachments.map((attachment) => {
                              const { type, url, contentType } = attachment;
      
                              if (type === 'image') {
                                return (<StyledImage key={attachment.id} src={url} alt="Imagem anexada" onClick={() => handleImageClick(url)}/>);
                              }
                              if (type === 'video') {
                                return (
                                  <VideoContainer key={attachment.id}>
                                    <StyledVideo onClick={() => handleVideoClick(url)}>
                                      <source src={url}/>
                                      <track kind="captions" srcLang="pt" label="Portuguese captions" />
                                      Seu navegador não suporta o elemento de vídeo.
                                    </StyledVideo>
                                    <PlayButton onClick={() => handleVideoClick(url) }>▶</PlayButton>
                                  </VideoContainer>
                                );
                              }
                              if (contentType === 'application/pdf' || contentType === 'document') {
                                return (
                                  <Column gap='12px' align='center' justify='center' onClick={() => handleDownloadPDF(url, message.content)}	>
                                      <Icon.MDI type='file-pdf-box'/>
                                  </Column>
                                );
                              }
                              return null;
                            })}
      
                          {!message.attachments || message.attachments.length === 0 ? (
                            <Text>{message.content}</Text>
                          ) : message.content ? (
                            <TextImage>{message.content}</TextImage>
                          )
                          : null}

                          {message.attachments &&
                          message.attachments.length > 0 &&
                          message.attachments.some(
                            (attachment) =>
                              attachment.type === 'image' || attachment.type === 'video'
                          ) ? (
                            <MessageTimeAndStatusImage
                              hasAttachmentAndContent={!!message.content}
                            >
                              <MessageTime>
                                {subtractHours(formatTime(message.createdAt), 3)}
                              </MessageTime>
                              {message.direction === 'outbound-api' && (
                                <MessageStatus
                                  status={message.receiptStatus}
                                  type={renderStatusIcon(message.receiptStatus)}
                                />
                              )}
                            </MessageTimeAndStatusImage>
                          ) : (      
                          <MessageTimeAndStatus>
                            <MessageTime>
                              {subtractHours(formatTime(message.createdAt), 3)}
                            </MessageTime>
                            {message.direction === 'outbound-api' && (
                              <MessageStatus
                                status={message.receiptStatus}
                                type={renderStatusIcon(message.receiptStatus)}
                              />
                            )}
                          </MessageTimeAndStatus>
                        )}
                        </MessageBubble>
                      );
                    })}
                    <div ref={messagesEndRef} />
                  </ChatMessages>
                </ChatMessagesContainer>
      
                <ChatInputContainer>
                  <ChatInput
                    placeholder="Digite uma mensagem"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <Button onClick={handleSendMessage}>Enviar</Button>
                </ChatInputContainer>
            </ChatWrapper>
            {(selectedImage || selectedVideo) && (
              <Overlay onClick={closeModal}>
                {selectedImage && <ModalImage src={selectedImage} alt="Imagem Selecionada" />}
                {selectedVideo && (
                  <ModalVideo controls autoPlay>
                    <source src={selectedVideo} />
                    Seu navegador não suporta o elemento de vídeo.
                  </ModalVideo>
                )}
                <CloseButton onClick={closeModal}>Fechar</CloseButton>
              </Overlay>
            )}
            </PageHeader>
          </Body>
        </App>
      );      
}

export default WhatsApp;
