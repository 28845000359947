import styled from 'styled-components';
import { StatusTag, View, Button, Column, Icon } from '@components-teammove';

export const FillView = styled(View)`
  flex: 1;
  width: 70%;
`;

export const Container = styled(Column)`
  gap: 26px;
`;

export const ActivityTypeIconWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  padding: ${({ size }) => size === 'small' ? '8px' : '16px'};
`;

export const ActivityTypeIcon = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const Subtitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  margin-bottom: ${({ noneMargin })=> noneMargin ? '' : '12px'};
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ContentContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: ${({ theme, usePrimaryColor }) => usePrimaryColor ? theme.darkPrimaryColor : theme.darkSecondaryColor};
  min-width: 279px;
`;

export const InfoContainer = styled(ContentContainer)`
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  flex: 1;
`;

export const AbsoluteStatusTag = styled(StatusTag)`
  position: absolute;
  top: 20px;
  right: 20px;

  .ant-typography {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.textContrastColor};
  }
`;

export const CompanyName = styled.span`
  font-size: 28px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
  white-space: nowrap;
`;

export const NameTypography = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const SecondaryTypography = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};

  strong {
    font-weight: 600;
  }
  opacity: ${({ lowOpacity })=> lowOpacity ? lowOpacity : 1};
`;

export const InfoText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};

  strong {
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 7px;
  }
`;

export const ContentText = styled(InfoText)`
  white-space: pre-wrap;
  line-height: 24px;
`;

export const UpdatesContainer = styled(Column)`
  gap: 12px;
  max-height: 546px;
  overflow-y: scroll;
  padding-right: 7px;
`;

export const UpdateContainer = styled(Column)`
  padding: 20px;
  gap: 14px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 8px;
  position: relative;
`;

export const UpdateDate = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  white-space: nowrap;
`;

export const UpdateTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
`;

export const UpdateDescription = styled(ContentText)`
  opacity: .5;
`;

export const UpdateDescriptionLabel = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  line-height: 24px;
  opacity: .5;
`;

export const ChecklistContainer = styled(ContentContainer)`
  padding: ${({ padding })=> padding ? padding : '34px'};
  height: ${({ height })=> height ? height : ''};
`;

export const ChecklistName = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const StartButton = styled(Button)`
  min-width: 288px;
`;

export const Go = styled(Icon.Bootstrap)`
  position: absolute;
  right: 18.75px;
`;

export const ChecklistIcon = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const ActivityTitleWrapper = styled.div`
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ActivityDueDateTimeContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 16px;
  padding: 14px;
`;

export const MarginleftSubtitle = styled(Subtitle)`
  margin-bottom: 0;
  margin-left: 16px;
`;

export const ContentSummaryContainer = styled(ContentContainer)`
  padding: 30px;
`;

export const IconStyle = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const ButtonStyle = styled(Button)`
  border: 1px solid var(--idle-color) !important;
  background-color: transparent !important;
  width: ${({ fullWidth })=> fullWidth && '100%' } !important;
  &.ant-btn {
    :disabled {
      background-color: transparent !important;
      border-color: var(--idle-color) !important;
      opacity: .5;
    }
  }
`;

export const ButtonFinish = styled(Button)`
  border: 1px solid var(--danger-color) !important;
  background-color: transparent !important;
  &.ant-btn {
    :disabled {
      background-color: transparent !important;
      border-color: var(--danger-color) !important;
      opacity: .5;
    }
  }
`;

export const TimeMarker = styled.div`
  border-radius: 8px;
  padding: 16px 60px 16px 16px;
  border: 1px solid var(--success-color);
  
`;