export const getTextCard = (status) => {
  switch (status) {
    case 'RELEVANTE':
      return {
        text: 'Relevante',
        apiText: 'Relevante',
        icon: 'thumbs-up',
        iconColor: 'var(--success-color)'
      };
    case 'NAO_RELEVANTE':
      return {
        text: 'Não Relevante',
        apiText: 'Nao_Relevante',
        icon: 'thumbs-down',
        iconColor: 'var(--danger-color)'
      };
    case 'ERRO':
      return {
        text: 'Erro',
        apiText: 'Erro',
        icon: 'alert-triangle',
        iconColor: 'var(--warning-color)'
      };
    case 'SEM_CONTATO':
      return {
        text: 'Sem Contato',
        apiText: 'Sem_Contato',
        icon: 'user-x',
        iconColor: null
      };
    default:
      return {
        text: 'Total',
        apiText: '',
        icon: 'home',
        iconColor: null
      };
  }
};

