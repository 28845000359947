import styled from 'styled-components';
import Input from '../Input';
import Icon from '../Icon';

export const StyledSearchBar = styled(Input.Search)`
  background-color: ${(props) => props.theme.darkPrimaryColor} !important;
  border-radius: 8px !important;
  height: 38px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: ${({ $marginLess }) => !$marginLess && '18px'};
  border: none !important;

  input {
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    color: ${(props) => props.theme.textColor} !important;
    font-size: 16px;
    height: 22px;
    display: flex;
    align-items: center;

    ::placeholder {
      color: ${(props) => props.theme.textColor} !important;
      opacity: .5;
    }
  }

  :focus-within {
    border: 2px solid ${(props) => props.theme.actionColor} !important;
    border-right-width: 2px !important;
    box-shadow: none !important;

    :hover {
      border-right-width: 2px !important;
    }
  }
  font-weight: 400;
  color: ${(props) => props.theme.textColor};

  .ant-input-suffix {
    color: ${(props) => props.theme.textColor};
    opacity: .5;
    height: 24px;

    .anticon-search {
      display: none;
      color: red;
    }
  }

  .ant-input-prefix {
    margin-right: 0;
    height: 1.5rem;
    color: ${(props) => props.theme.backgroundColor};
  }
`;

export const CloseIcon = styled(Icon.Feather)`
  cursor: pointer;
  opacity: .5;
`;