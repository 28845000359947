import React, { useEffect, useState } from 'react';
import { Card } from '../styles';
import { TitleText, SettingsIcon } from './styles';
import { Grid, Input, Form, Select, Row, Skeleton } from '@components-teammove';
import { optionsStrategyActions } from './rules';
import { useSelector } from 'react-redux';

const AcoesEstrategicasChecklist = ({ form }) => {
  const loadingChecklistTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('loadingChecklistTemplate'));
  const [opcaoEstrategica, setOpcaoEstrategica] = useState('N');

  useEffect(() => {
    setOpcaoEstrategica(form.getFieldValue('acao_estrategica'));
  }, [form.getFieldValue('acao_estrategica')]);

  return loadingChecklistTemplate ? (
    <Skeleton.Card/>
  ) : (
    <Card>
      <Row justify='space-between' width='100%' align='center' margin='0 0 20px 0'>
        <TitleText>Ações estratégicas</TitleText>
        <SettingsIcon type='settings'/>
      </Row>
      <Form form={form} layout='vertical'>
        <Grid numberOfColumns={2} gap='0 20px' width='100%'>
          <Form.Item label="Utiliza ação estratégica" name="acao_estrategica" rules={[{ required: true, message: 'Por favor, insira este campo' }]}>
            <Select placeholder='Escolha uma opção' options={optionsStrategyActions} onChange={(value) => setOpcaoEstrategica(value)}/>
          </Form.Item>
          <Form.Item hidden={opcaoEstrategica === 'N'} label="Nome da ação estratégica" name="nome_plano_acao">
            <Input placeholder='visão do consultor'/>
          </Form.Item>
        </Grid>
      </Form>
    </Card>
  );
};

export default AcoesEstrategicasChecklist;