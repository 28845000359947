import { get, put, post } from '../api';

export const getProductsByCompany = (companyId) => {
  return get('/api/products' + (companyId || companyId === 0 ? `?companyId=${companyId}` : ''));
};

export const getProductRegister = (productId) => {
  return get(`/api/products/registerData?${productId ? `productId=${productId}` : ''}`);
};

export const postProductRegister = (product)=>{
  return post('/api/products', product);
};

export const putProductRegister = (product)=>{
  return put('/api/products', product);
};

export const getDetailedProduct = (productId) => {
  return get(`/api/products/${productId}/detailed`);
};

export const getExtraFields = () => {
  return get('/api/products/extraFields');
};

export const getCategoriesFamilies = () =>{ 
  return get('/api/products/categories/families');
};

export const changeOrdemCategoriesFamilies = (id, parentId, ordem ) => {
  return put(`/api/products/categories/${id}/reorder?categoryId=${parentId}`, ordem);
};

export const changeOrdemCategoriesProducts = (id, ordem) => {
  return put(`/api/products/families/${id}/reorder`, ordem);
};

export const importProducts = ({ fileUrl, listsSuffix }) => {
  return post('/api/products/import', { fileUrl, listsSuffix }, {}, true);
};