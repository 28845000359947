import React from 'react';
import { moment, capitalize } from '@utils';
import { getUnidades } from '@sdk/Unidades';
import { IconStar } from './styles';

export const createInitialModalFilters = (companyNomenclature, ticketNomenclatures) => [
  {
    type: 'TREE_SELECT',
    name: 'idCategory',
    label: 'Departamento/Categoria',
    placeholder: 'Selecione o departamento/categoria',
    options: [],
    switch: true,
  },
  {
    type: 'SELECT',
    name: 'idCompanies',
    label: companyNomenclature.plural,
    placeholder: `Selecione ${companyNomenclature.artigo}s ${companyNomenclature.plural?.toLowerCase()} `,
    optionLabelProp: 'title',
    action: getUnidades,
  },
  {
    type: 'SELECT',
    name: 'idUserCreation',
    label: 'Usuário Criação',
    placeholder: 'Selecione os Usuários',
    options: []
  },
  {
    type: 'SELECT',
    name: 'idUserResponsible',
    label: 'Usuário responsável',
    placeholder: 'Selecione o usuário responsável',
    options: []
  },
  {
    type: 'SELECT',
    name: 'idProfile',
    label: 'Perfil do usuário',
    placeholder: 'Selecione o perfil do usuário',
    options: []
  },
  {
    type: 'SELECT',
    name: 'ticketsStatus',
    label: 'Status',
    placeholder: `Selecione os status d${ticketNomenclatures.artigo}s ${ticketNomenclatures.plural?.toLowerCase()}`,
    options: [
      { value:'AB', label: 'Aberto' },
      { value:'EA', label: 'Em Atendimento' },
      { value:'CO', label: 'Concluído' },
      { value:'FE', label: 'Fechado' },
      { value:'AA', label: 'Aguardando Avaliação' },
      { value:'RE', label: 'Reaberto' },
      { value:'CA', label: 'Cancelado' },
      { value:'AR', label: 'Aguardando Pendência' },
      { value:'BR', label: 'Aguardando Briefing' },
    ],
  },
  {
    type: 'SELECT',
    label: 'Avaliação',
    name: 'avaliations',
    placeholder: 'Selecione as Avaliações',
    options: [
      { value: '1', title: '1', label: (
        <>
          {new Array(parseInt(1)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'1' + index}/>
          ))}
        </>
      ) },
      { value: '2', title: '2', label: (
        <>
          {new Array(parseInt(2)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'2' + index}/>
          ))}
        </>
      ) },
      { value: '3', title: '3', label: (
        <>
          {new Array(parseInt(3)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'3' + index}/>
          ))}
        </>
      ) },
      { value: '4', title: '4', label: (
        <>
          {new Array(parseInt(4)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'4' + index}/>
          ))}
        </>
      ) },
      { value: '5', title: '5', label: (
        <>
          {new Array(parseInt(5)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'5' + index}/>
          ))}
        </>
      ) },
    ],
  },
  {
    type: 'SELECT',
    name: 'idsClassifications',
    label: 'Tipo de classificação',
    placeholder: 'Selecione o tipo de classificação',
    options: []
  }
];

export const getFinalDateByInitialDateAndPeriod = (initialDate, period) => {
  const timeUnit = swithTimePeriod(period);

  return period === 'SEMESTRAL' ? 
    moment(initialDate).add(5, 'months').endOf('month') :
    moment(initialDate).endOf(timeUnit.substring(0, timeUnit.length));
};

export const getOldFiltersWithNewFilters = (oldFilters, newFilters) => {    
  const filtros = { 
    categoria: newFilters.idCategory || oldFilters?.categoria,
    dataCriacao: newFilters.initialDate ? 
      [moment(newFilters.initialDate), getFinalDateByInitialDateAndPeriod(newFilters.initialDate, newFilters.typePeriod)] : 
      oldFilters?.dataCriacao,
    usuarioCriacao: newFilters.idUserCreation || oldFilters?.usuarioCriacao, 
    unidade: newFilters.idCompanies || oldFilters?.unidade,
    responsavel: newFilters.idUserResponsible || oldFilters?.responsavel,
    status: newFilters.ticketsStatus || oldFilters?.status,
    descFiltros: { 
      ...(oldFilters?.descFiltros || {}), 
      'categoria': (newFilters?.descFiltros?.idCategory?.length > 0) ? 
        newFilters.descFiltros.idCategory : 
        oldFilters?.descFiltros?.categoria || [],
      'dataCriacao': newFilters.initialDate ? 
        [moment(newFilters.initialDate).format('DD/MM/YYYY'), getFinalDateByInitialDateAndPeriod(newFilters.initialDate, newFilters.typePeriod).format('DD/MM/YYYY')] : 
        oldFilters?.descFiltros?.dataCriacao || [],
      'usuarioCriacao': (newFilters?.descFiltros?.idUserCreation?.length > 0) ? 
        newFilters.descFiltros.idUserCreation : 
        oldFilters?.descFiltros?.usuarioCriacao || [],
      'unidade': (newFilters?.descFiltros?.idCompanies?.length > 0) ? 
        newFilters.descFiltros.idCompanies : 
        oldFilters?.descFiltros?.unidade || [],
      'responsavel': (newFilters?.descFiltros?.idUserResponsible?.length > 0) ? 
        newFilters.descFiltros.idUserResponsible : 
        oldFilters?.descFiltros?.responsavel || [],
      'perfil': (newFilters?.descFiltros?.idProfile?.length > 0) ? 
        newFilters.descFiltros.idProfile : 
        oldFilters?.descFiltros?.perfil || [],
      'status': (newFilters?.descFiltros?.ticketsStatus?.length > 0) ? 
        newFilters.descFiltros.ticketsStatus : 
        oldFilters?.descFiltros?.status || [],
    }
  };

  if (filtros?.descFiltros) {
    Object.keys(filtros.descFiltros).forEach((key) => {
      if (!filtros.descFiltros[key] || filtros.descFiltros[key].length === 0) {
        delete filtros.descFiltros[key];
      }
    });
  }
  
  return { ...oldFilters, ...filtros };
};

export const getInitialDateByPeriod = (period) => {
  const currentDate = moment();
  let initialDate = '';

  const getStartDate = (month, year) => {
    return moment(`01-${month}-${year}`, 'DD-MM-YYYY').format('YYYY-MM-DD');
  };

  const semesterStartMonth = currentDate.month() < 6 ? 1 : 7;
  const trimesterStartMonth = Math.floor((currentDate.month() - 1) / 3) * 3 + 1;

  switch (period) {
    case 'DIARIO':
      initialDate = currentDate.clone().startOf('day').format('YYYY-MM-DD');
      break;
    case 'SEMANAL':
      initialDate = currentDate.clone().startOf('week').format('YYYY-MM-DD');
      break;
    case 'MENSAL':
      initialDate = currentDate.clone().startOf('month').format('YYYY-MM-DD');
      break;
    case 'ANUAL':
      initialDate = currentDate.clone().startOf('year').format('YYYY-MM-DD');
      break;
    case 'TRIMESTRAL':
      initialDate = getStartDate(trimesterStartMonth, currentDate.year());
      break;
    case 'SEMESTRAL':
      initialDate = getStartDate(semesterStartMonth, currentDate.year());
      break;
    case 'TODOS':
      initialDate = currentDate.clone().startOf('year').format('YYYY-MM-DD');
      break;
    default:
      initialDate = currentDate.clone().startOf('day').format('YYYY-MM-DD');
      break;
  }
  return initialDate;
};

export const getRangeDate = (period) => {
  const currentDate = moment();
  const type = () => {
    if(period === 'SEMANAL') {
      return 'week';
    } else if(period === 'MENSAL') {
      return 'month';
    } else if(period === 'ANUAL') {
      return 'year';
    } else if(period === 'SEMESTRAL') {
      return 'semester';
    } else if(period === 'TRIMESTRAL') {
      return 'quarter';
    } else if(period === 'DIARIO') {
      return 'day';
    } else if(period === 'TODOS') {
      return 'year';
    }
  };

  const formatoInicio = currentDate.clone().startOf(type()).format('D');
  const formatoFim = currentDate.clone().endOf(type()).format('D MMMM, YYYY');
  
  if(type() === 'month' || type() === 'year' || type() === 'quarter') {
    if(type() === 'month') {
      return currentDate.clone().startOf(type()).format('MMMM, YYYY').replace(/^\w/, (index) => index.toUpperCase());
    } else if(type() === 'year') {
      return currentDate.clone().startOf(type()).format('YYYY');
    } else if(type() === 'quarter') {
      const quarterNumber = Math.floor((currentDate.month() + 3) / 3);
      if (quarterNumber === 1) { 
        return `1 janeiro a 31 março, ${currentDate.year()}`;
      } else if (quarterNumber === 2) {
        return `1 abril a 30 junho, ${currentDate.year()}`;
      } else if (quarterNumber === 3) {
        return `1 julho a 30 setembro, ${currentDate.year()}`;
      } else {
        return `1 outubro a 31 dezembro, ${currentDate.year()}`;
      }
    }
  } else if(type() === 'week' ) {
    return `${formatoInicio} a ${formatoFim}`; 
  } else if(type() === 'semester') {
    if (currentDate.month() < 6) { 
      return `1 janeiro a 30 junho, ${currentDate.year()}`;
    } else { 
      return `1 julho a 31 dezembro, ${currentDate.year()}`;
    }
  } else if(type() === 'day') {
    return currentDate.clone().format('D MMMM, YYYY');
  }
};

export const swithTimePeriod = (period)=> {
  let initialDate = '';

  if (period === 'DIARIO') {
    initialDate = 'days';
  } else if (period === 'SEMANAL') {
    initialDate = 'weeks';
  } else if (period === 'MENSAL') {
    initialDate = 'months';
  } else if (period === 'ANUAL') {
    initialDate = 'years';
  } else if (period === 'TRIMESTRAL') {
    initialDate = 'quarters';
  }else if (period === 'SEMESTRAL') {
    initialDate = 'semesters';
  }else if (period === 'TODOS') {
    initialDate = 'years';
  }
  return initialDate;
};

export const handleChangeRangeDate = (startDate, period) => {
  const currentDate = moment(startDate, 'YYYY-MM-DD');

  let formatedRangeDate = '';
  
  const timeUnit = swithTimePeriod(period);

  const currenEndDate = moment(currentDate).endOf(timeUnit.substring(0, timeUnit.length));
  const endSemesterDate = moment(currentDate).add(5, 'months').endOf('month');
  
  switch (period) {
    case 'DIARIO':
      formatedRangeDate = currentDate.format('DD [de] MMMM, YYYY');
      break;
    case 'SEMANAL':
      formatedRangeDate = currentDate.format('DD [a]') + ' ' + currenEndDate.format('DD [de] MMMM YYYY');
      break;
    case 'MENSAL':
      formatedRangeDate = capitalize(currentDate.format('MMMM, YYYY'));
      break;
    case 'ANUAL':
      formatedRangeDate = currentDate.format('YYYY');
      break;
    case 'TRIMESTRAL':
      formatedRangeDate = currentDate.format(`DD MMMM [a] [${currenEndDate.format('DD MMMM')}], YYYY`);
      break;
    case 'SEMESTRAL':
      formatedRangeDate = currentDate.format(`DD MMMM [a] [${endSemesterDate.format('DD MMMM')}], YYYY`);
      break;
    case 'TODOS':
      formatedRangeDate = 'Todos';
      break;
    default:
      formatedRangeDate = '';
      break;
  }
  
  return formatedRangeDate;
  
};

export const handleChangeInitialDate = (startDate, action, period) => {
  const currentDate = moment(startDate, 'YYYY-MM-DD');
  let initialDate = '';

  if (period === 'DIARIO') {
    initialDate = 'days';
  } else if (period === 'SEMANAL') {
    initialDate = 'weeks';
  } else if (period === 'MENSAL') {
    initialDate = 'months';
  } else if (period === 'ANUAL') {
    initialDate = 'years';
  } else if (period === 'TRIMESTRAL') {
    initialDate = 'quarters';
  } else if (period === 'SEMESTRAL') {
    if (action === 'progredir') {
      currentDate.add(6, 'months');
    } else {
      currentDate.subtract(6, 'months');
    }
    const currentMonth = currentDate.month();
    const startOfCurrentSemester = currentMonth < 6 ? 0 : 6;
    currentDate.startOf('year').add(startOfCurrentSemester, 'months');
    return currentDate.format('YYYY-MM-DD');
  }else if(period === 'TODOS') {
    initialDate = 'years';
  }

  if (action === 'progredir') {
    currentDate.add(1, initialDate);
  } else {
    currentDate.subtract(1, initialDate);
  }

  return currentDate.format('YYYY-MM-DD');
};