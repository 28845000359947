import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification , getCurrentLatLng, moment } from '@utils';
import {
  getChecklistPreview as getChecklistPreviewSdk, 
  getChecklistQuestionGroups as getChecklistQuestionGroupsSdk,
  getAnswersHistoric as getAnswersHistoricSdk,
  answerQuestion as postAnswersSdk,
  getChecklistFinishPreview as getChecklistFinishPreviewSdk,
  updateChecklistFinishPreview as updateChecklistFinishPreviewSdk,
  deleteChecklistActionPlan as deleteChecklistActionPlanSdk,
  finishChecklist as finishChecklistSdk,
  getFinishedChecklist as getFinishedChecklistSdk,
  getChecklistUploads as getChecklistUploadsSdk
} from '@sdk/Checklists';

import { getActivityCheckInOut, postCheckInOut } from '@sdk/AtividadesCheckInOut';

export const FILTERS_KEY = 'CHECKLISTS';

//Action Types
export const Types = {
  GET_CHECKLIST_PREVIEW: 'CHECKLISTS/GET_CHECKLIST_PREVIEW',
  GET_CHECKLIST_PREVIEW_SUCCESS: 'CHECKLISTS/GET_CHECKLIST_PREVIEW_SUCCESS',
  GET_CHECKLIST_PREVIEW_ERROR: 'CHECKLISTS/GET_CHECKLIST_PREVIEW_ERROR',

  GET_CHECKLIST_QUESTION_GROUPS: 'CHECKLISTS/GET_CHECKLIST_QUESTION_GROUPS',
  GET_CHECKLIST_QUESTION_GROUPS_SUCCESS: 'CHECKLISTS/GET_CHECKLIST_QUESTION_GROUPS_SUCCESS',
  GET_CHECKLIST_QUESTION_GROUPS_ERROR: 'CHECKLISTS/GET_CHECKLIST_QUESTION_GROUPS_ERROR',

  GET_ANSWERS_HISTORIC: 'CHECKLISTS/GET_ANSWERS_HISTORIC',
  GET_ANSWERS_HISTORIC_SUCCESS: 'CHECKLISTS/GET_ANSWERS_HISTORIC_SUCCESS',
  GET_ANSWERS_HISTORIC_ERROR: 'CHECKLISTS/GET_ANSWERS_HISTORIC_ERROR',

  POST_ANSWERS: 'CHECKLISTS/POST_ANSWERS',
  POST_ANSWERS_SUCCESS: 'CHECKLISTS/POST_ANSWERS_SUCCESS',
  POST_ANSWERS_ERROR: 'CHECKLISTS/POST_ANSWERS_ERROR',

  GET_CHECKLIST_FINISH_PREVIEW: 'CHECKLISTS/GET_CHECKLIST_FINISH_PREVIEW',
  GET_CHECKLIST_FINISH_PREVIEW_SUCCESS: 'CHECKLISTS/GET_CHECKLIST_FINISH_PREVIEW_SUCCESS',
  GET_CHECKLIST_FINISH_PREVIEW_ERROR: 'CHECKLISTS/GET_CHECKLIST_FINISH_PREVIEW_ERROR',

  UPDATE_CHECKLIST_FINISH_PREVIEW: 'CHECKLISTS/UPDATE_CHECKLIST_FINISH_PREVIEW',
  UPDATE_CHECKLIST_FINISH_PREVIEW_SUCCESS: 'CHECKLISTS/UPDATE_CHECKLIST_FINISH_PREVIEW_SUCCESS',
  UPDATE_CHECKLIST_FINISH_PREVIEW_ERROR: 'CHECKLISTS/UPDATE_CHECKLIST_FINISH_PREVIEW_ERROR',

  DELETE_CHECKLIST_ACTION_PLAN: 'CHECKLISTS/DELETE_CHECKLIST_ACTION_PLAN',
  DELETE_CHECKLIST_ACTION_PLAN_SUCCESS: 'CHECKLISTS/DELETE_CHECKLIST_ACTION_PLAN_SUCCESS',
  DELETE_CHECKLIST_ACTION_PLAN_ERROR: 'CHECKLISTS/DELETE_CHECKLIST_ACTION_PLAN_ERROR',

  FINISH_CHECKLIST: 'CHECKLISTS/FINISH_CHECKLIST',
  FINISH_CHECKLIST_SUCCESS: 'CHECKLISTS/FINISH_CHECKLIST_SUCCESS',
  FINISH_CHECKLIST_ERROR: 'CHECKLISTS/FINISH_CHECKLIST_ERROR',

  GET_FINISHED_CHECKLIST: 'CHECKLISTS/GET_FINISHED_CHECKLIST',
  GET_FINISHED_CHECKLIST_SUCCESS: 'CHECKLISTS/GET_FINISHED_CHECKLIST_SUCCESS',
  GET_FINISHED_CHECKLIST_ERROR: 'CHECKLISTS/GET_FINISHED_CHECKLIST_ERROR',

  GET_CHECKLIST_UPLOADS: 'CHECKLISTS/GET_CHECKLIST_UPLOADS',
  GET_CHECKLIST_UPLOADS_SUCCESS: 'CHECKLISTS/GET_CHECKLIST_UPLOADS_SUCCESS',
  GET_CHECKLIST_UPLOADS_ERROR: 'CHECKLISTS/GET_CHECKLIST_UPLOADS_ERROR',

  RESET_SAVE_SUCCESSES: 'CHECKLISTS/RESET_SAVE_SUCCESSES',
  RESET_ANSWERS_HISTORIC: 'CHECKLISTS/RESET_ANSWERS_HISTORIC',
  RESET_FINISH_ERROR: 'CHECKLISTS/RESET_FINISH_ERROR',
  RESET: 'CHECKLISTS/RESET',
};

//Action Creators
export const getChecklistPreview = (idChecklist) => ({ type: Types.GET_CHECKLIST_PREVIEW, idChecklist });
export const getChecklistPreviewSuccess = (checklist) => ({ type: Types.GET_CHECKLIST_PREVIEW_SUCCESS, checklist });
export const getChecklistPreviewError = (error) => ({ type: Types.GET_CHECKLIST_PREVIEW_ERROR, error }); 

export const getChecklistQuestionGroups = (idChecklist) => ({ type: Types.GET_CHECKLIST_QUESTION_GROUPS, idChecklist });
export const getChecklistQuestionGroupsSuccess = (checklist) => ({ type: Types.GET_CHECKLIST_QUESTION_GROUPS_SUCCESS, checklist });
export const getChecklistQuestionGroupsError = (error) => ({ type: Types.GET_CHECKLIST_QUESTION_GROUPS_ERROR, error }); 

export const getAnswersHistoric = (idChecklist, idQuestion) => ({ type: Types.GET_ANSWERS_HISTORIC, idChecklist, idQuestion });
export const getAnswersHistoricSuccess = (historic) => ({ type: Types.GET_ANSWERS_HISTORIC_SUCCESS, historic });
export const getAnswersHistoricError = (error) => ({ type: Types.GET_ANSWERS_HISTORIC_ERROR, error }); 

export const postAnswers = (idChecklist, idQuestion, answers) => ({ type: Types.POST_ANSWERS, idChecklist, idQuestion, answers });
export const postAnswersSuccess = (question) => ({ type: Types.POST_ANSWERS_SUCCESS, question });
export const postAnswersError = (error) => ({ type: Types.POST_ANSWERS_ERROR, error }); 

export const getChecklistFinishPreview = (idChecklist) => ({ type: Types.GET_CHECKLIST_FINISH_PREVIEW, idChecklist });
export const getChecklistFinishPreviewSuccess = (checklist) => ({ type: Types.GET_CHECKLIST_FINISH_PREVIEW_SUCCESS, checklist });
export const getChecklistFinishPreviewError = (error) => ({ type: Types.GET_CHECKLIST_FINISH_PREVIEW_ERROR, error }); 

export const updateChecklistFinishPreview = (idChecklist, newPreview) => ({ type: Types.UPDATE_CHECKLIST_FINISH_PREVIEW, idChecklist, newPreview });
export const updateChecklistFinishPreviewSuccess = (newPreview) => ({ type: Types.UPDATE_CHECKLIST_FINISH_PREVIEW_SUCCESS, newPreview });
export const updateChecklistFinishPreviewError = (error) => ({ type: Types.UPDATE_CHECKLIST_FINISH_PREVIEW_ERROR, error }); 

export const deleteChecklistActionPlan = (idActionPlanActivity) => ({ type: Types.DELETE_CHECKLIST_ACTION_PLAN, idActionPlanActivity });
export const deleteChecklistActionPlanSuccess = (actionPlan) => ({ type: Types.DELETE_CHECKLIST_ACTION_PLAN_SUCCESS, actionPlan });
export const deleteChecklistActionPlanError = (error) => ({ type: Types.DELETE_CHECKLIST_ACTION_PLAN_ERROR, error }); 

export const finishChecklist = (idActivity,idChecklist, checklist) => ({ type: Types.FINISH_CHECKLIST, idChecklist, checklist, idActivity });
export const finishChecklistSuccess = (checklist) => ({ type: Types.FINISH_CHECKLIST_SUCCESS, checklist });
export const finishChecklistError = (error) => ({ type: Types.FINISH_CHECKLIST_ERROR, error }); 

export const getFinishedChecklist = (idChecklist, headers) => ({ type: Types.GET_FINISHED_CHECKLIST, idChecklist, headers });
export const getFinishedChecklistSuccess = (checklist) => ({ type: Types.GET_FINISHED_CHECKLIST_SUCCESS, checklist });
export const getFinishedChecklistError = (error) => ({ type: Types.GET_FINISHED_CHECKLIST_ERROR, error }); 

export const getChecklistUploads = (idChecklist) => ({ type: Types.GET_CHECKLIST_UPLOADS, idChecklist });
export const getChecklistUploadsSuccess = (uploads) => ({ type: Types.GET_CHECKLIST_UPLOADS_SUCCESS, uploads });
export const getChecklistUploadsError = (error) => ({ type: Types.GET_CHECKLIST_UPLOADS_ERROR, error }); 

export const resetSaveSuccesses = () => ({ type: Types.RESET_SAVE_SUCCESSES });
export const resetAnswersHistoric = () => ({ type: Types.RESET_ANSWERS_HISTORIC });
export const resetFinishError = () => ({ type: Types.RESET_FINISH_ERROR });
export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetChecklistPreview(action) {
  try {
    const { idChecklist } = action;
    const checklist = yield call(getChecklistPreviewSdk, idChecklist);
    yield put(getChecklistPreviewSuccess(checklist));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistPreviewError(err));
  }
}

function* fetchGetChecklistQuestionGroups(action) {
  try {
    const { idChecklist } = action;
    const checklist = yield call(getChecklistQuestionGroupsSdk, idChecklist);
    yield put(getChecklistQuestionGroupsSuccess(checklist));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistQuestionGroupsError(err));
  }
}

function* fetchGetAnswersHistoric(action) {
  try {
    const { idChecklist, idQuestion } = action;
    const historic = yield call(getAnswersHistoricSdk, idChecklist, idQuestion);
    yield put(getAnswersHistoricSuccess(historic));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAnswersHistoricError(err));
  }
}

function* fetchPostAnswers(action) {
  try {
    const { idChecklist, idQuestion, answers } = action;
     
    const answersResponse = yield call(postAnswersSdk, idChecklist, idQuestion, answers);
    yield put(postAnswersSuccess(answersResponse));
  } catch (err) {
    Notification.error(err.message);
    yield put(postAnswersError(err));
  }
}

function* fetchGetChecklistFinishPreview(action) {
  try {
    const { idChecklist } = action;
    const checklist = yield call(getChecklistFinishPreviewSdk, idChecklist);
    yield put(getChecklistFinishPreviewSuccess(checklist));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistFinishPreviewError(err));
  }
}

function* fetchUpdateChecklistFinishPreview(action) {
  try {
    const { idChecklist, newPreview } = action;
    const savedPreview = yield call(updateChecklistFinishPreviewSdk, idChecklist, newPreview);
    yield put(updateChecklistFinishPreviewSuccess(savedPreview));
  } catch (err) {
    Notification.error(err.message);
    yield put(updateChecklistFinishPreviewError(err));
  }
}

function* fetchDeleteChecklistActionPlan(action) {
  try {
    const { idActionPlanActivity } = action;
    const actionPlan = yield call(deleteChecklistActionPlanSdk, idActionPlanActivity);
    yield put(deleteChecklistActionPlanSuccess(actionPlan));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteChecklistActionPlanError(err));
  }
}

function* fetchFinishChecklist(action) {
  try {
    const { idActivity, idChecklist, checklist } = action;
    const resp = yield call(getActivityCheckInOut);

    const getCheckInOutData = (coords) => {
      const data = {
        data: moment().format('YYYY-MM-DD HH:mm:ss.SSS')
      };

      if (resp && resp.checkinType === 'G' && coords) {
        data.latitude = coords.latitude;
        data.longitude = coords.longitude;
      }

      return data;
    };

    const postCheckout = function* (coords) {
      yield call(postCheckInOut, 'out', idActivity, getCheckInOutData(coords));
    };

    let shouldPostCheckout = false;
    let coords = {};

    if (resp?.id?.toString() === idActivity) {
      shouldPostCheckout = true;
      if (resp.checkinType === 'G' || resp.checkinType === 'O') {
        coords = yield call(getCurrentLatLng);
        if (Object.keys(coords).length === 0) {
          throw new Error('User denied Geolocation');
        } 
      }
    }
    const newChecklist = yield call(finishChecklistSdk, idChecklist, checklist);
    if (newChecklist && shouldPostCheckout) {
      yield call(postCheckout, Object.keys(coords).length === 0 ? coords : undefined);
    }
    yield put(finishChecklistSuccess(newChecklist));
  } catch (err) {
    let errorMessage = err.message;
    if(err.message === 'User denied Geolocation') {
      errorMessage = 'Verifique se as permissões de localização estão ativas';
    }else if(err.message.includes('Only secure origins are allowed')) {
      errorMessage = 'Erro referente ao ambiente de teste http';
    }
    Notification.error(errorMessage);
    yield put(finishChecklistError(err));
  }
}

function* fetchGetFinishedChecklist(action) {
  try {
    const { idChecklist, headers } = action;
    const checklist = yield call(getFinishedChecklistSdk, idChecklist, headers);
    yield put(getFinishedChecklistSuccess(checklist));
  } catch (err) {
    Notification.error(err.message);
    yield put(getFinishedChecklistError(err));
  }
}

function* fetchGetChecklistUploads(action) {
  try {
    const { idChecklist } = action;
    const checklist = yield call(getChecklistUploadsSdk, idChecklist);
    yield put(getChecklistUploadsSuccess(checklist));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistUploadsError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CHECKLIST_PREVIEW, fetchGetChecklistPreview),
  takeLatest(Types.GET_CHECKLIST_QUESTION_GROUPS, fetchGetChecklistQuestionGroups),
  takeLatest(Types.GET_ANSWERS_HISTORIC, fetchGetAnswersHistoric),
  takeLatest(Types.POST_ANSWERS, fetchPostAnswers),
  takeLatest(Types.GET_CHECKLIST_FINISH_PREVIEW, fetchGetChecklistFinishPreview),
  takeLatest(Types.UPDATE_CHECKLIST_FINISH_PREVIEW, fetchUpdateChecklistFinishPreview),
  takeLatest(Types.DELETE_CHECKLIST_ACTION_PLAN, fetchDeleteChecklistActionPlan),
  takeLatest(Types.FINISH_CHECKLIST, fetchFinishChecklist),
  takeLatest(Types.GET_FINISHED_CHECKLIST, fetchGetFinishedChecklist),
  takeLatest(Types.GET_CHECKLIST_UPLOADS, fetchGetChecklistUploads),
];

// Reducer
const initialState = Map({
  checklistPreview: {},
  loadingChecklistPreview: false,
  successChecklistPreview: false,
  errorChecklistPreview: false,
  checklistQuestionGroups: {},
  loadingChecklistQuestionGroups: false,
  successChecklistQuestionGroups: false,
  errorChecklistQuestionGroups: false,
  answersHistoric: [],
  loadingAnswersHistoric: false,
  successAnswersHistoric: false,
  errorAnswersHistoric: false,
  loadingSaveAnswers: false,
  successSaveAnswers: false,
  errorSaveAnswers: false,
  checklistFinishPreview: {},
  loadingChecklistFinishPreview: false,
  successChecklistFinishPreview: false,
  errorChecklistFinishPreview: false,
  loadingSaveChecklistFinishPreview: false,
  successSaveChecklistFinishPreview: false,
  errorSaveChecklistFinishPreview: false,
  loadingDeleteChecklistActionPlan: false,
  successDeleteChecklistActionPlan: false,
  errorDeleteChecklistActionPlan: false,
  loadingFinishChecklist: false,
  successFinishChecklist: false,
  errorFinishChecklist: false,
  finishedChecklist: {},
  loadingFinishedChecklist: false,
  successFinishedChecklist: false,
  errorFinishedChecklist: false,
  uploads: {},
  loadingUploads: false,
  successUploads: false,
  errorUploads: false,
});

const handleGetChecklistPreview = (state, action) => {
  return state.set('loadingChecklistPreview', true).set('successChecklistPreview', false).set('errorChecklistPreview', false);
};

const handleGetChecklistPreviewSuccess = (state, action) => {
  const { checklist } = action;

  return state.set('checklistPreview', checklist).set('loadingChecklistPreview', false).set('successChecklistPreview', true).set('errorChecklistPreview', false);
};

const handleGetChecklistPreviewError = (state, action) => {
  return state.set('loadingChecklistPreview', false).set('successChecklistPreview', false).set('errorChecklistPreview', action.error);
};

const handleGetChecklistQuestionGroups = (state, action) => {
  return state.set('loadingChecklistQuestionGroups', true).set('successChecklistQuestionGroups', false).set('errorChecklistQuestionGroups', false);
};

const handleGetChecklistQuestionGroupsSuccess = (state, action) => {
  const { checklist } = action;

  return state.set('checklistQuestionGroups', checklist).set('loadingChecklistQuestionGroups', false).set('successChecklistQuestionGroups', true).set('errorChecklistQuestionGroups', false);
};

const handleGetChecklistQuestionGroupsError = (state, action) => {
  return state.set('loadingChecklistQuestionGroups', false).set('successChecklistQuestionGroups', false).set('errorChecklistQuestionGroups', action.error);
};

const handleGetAnswersHistoric = (state, action) => {
  return state.set('loadingAnswersHistoric', true).set('successAnswersHistoric', false).set('errorAnswersHistoric', false);
};

const handleGetAnswersHistoricSuccess = (state, action) => {
  const { historic } = action;

  return state.set('answersHistoric', historic).set('loadingAnswersHistoric', false).set('successAnswersHistoric', true).set('errorAnswersHistoric', false);
};

const handleGetAnswersHistoricError = (state, action) => {
  return state.set('loadingAnswersHistoric', false).set('successAnswersHistoric', false).set('errorAnswersHistoric', action.error);
};

const handleSaveAnswers = (state, { answers, idQuestion }) => {
  const foundGroup = state.get('checklistQuestionGroups').groups.find(({ asks }) => asks.find(({ id }) => id === idQuestion));

  const newValue = { ...state.get('checklistQuestionGroups'), groups: state.get('checklistQuestionGroups').groups.map((group) => group.idGroup === foundGroup.idGroup ? ({ ...group, asks: group.asks.map((ask) => ask.id === idQuestion ? ({ ...ask, answers }) : ({ ...ask })) }) : ({ ...group })) };

  return state.set('loadingSaveAnswers', true).set('successSaveAnswers', false).set('errorSaveAnswers', false).set('checklistQuestionGroups', newValue);
};

const handleSaveAnswersSuccess = (state, { question }) => {
  const foundGroup = state.get('checklistQuestionGroups').groups.find(({ asks }) => asks.find(({ id }) => id === question.id));

  const newValue = { ...state.get('checklistQuestionGroups'), groups: state.get('checklistQuestionGroups').groups.map((group) => group.idGroup === foundGroup.idGroup ? ({ ...group, asks: group.asks.map((ask) => ask.id === question.id ? ({ ...ask, answers: question.answers }) : ({ ...ask })) }) : ({ ...group })) };

  return state.set('loadingSaveAnswers', false).set('successSaveAnswers', true).set('errorSaveAnswers', false).set('checklistQuestionGroups', newValue);
};

const handleSaveAnswersError = (state, action) => {
  return state.set('loadingSaveAnswers', false).set('successSaveAnswers', false).set('errorSaveAnswers', action.error);
};

const handleGetChecklistFinishPreview = (state, action) => {
  return state.set('loadingChecklistFinishPreview', true).set('successChecklistFinishPreview', false).set('errorChecklistFinishPreview', false);
};

const handleGetChecklistFinishPreviewSuccess = (state, action) => {
  const { checklist } = action;

  return state.set('checklistFinishPreview', checklist).set('loadingChecklistFinishPreview', false).set('successChecklistFinishPreview', true).set('errorChecklistFinishPreview', false);
};

const handleGetChecklistFinishPreviewError = (state, action) => {
  return state.set('loadingChecklistFinishPreview', false).set('successChecklistFinishPreview', false).set('errorChecklistFinishPreview', action.error);
};

const handleUpdateChecklistFinishPreview = (state, action) => {
  return state.set('loadingSaveChecklistFinishPreview', true).set('successSaveChecklistFinishPreview', false).set('errorSaveChecklistFinishPreview', false);
};

const handleUpdateChecklistFinishPreviewSuccess = (state, action) => {
  const { newPreview } = action;

  return state.set('checklistFinishPreview', { ...state.get('checklistFinishPreview'), ...newPreview }).set('loadingSaveChecklistFinishPreview', false).set('successSaveChecklistFinishPreview', true).set('errorSaveChecklistFinishPreview', false);
};

const handleUpdateChecklistFinishPreviewError = (state, action) => {
  return state.set('loadingSaveChecklistFinishPreview', false).set('successSaveChecklistFinishPreview', false).set('errorSaveChecklistFinishPreview', action.error);
};

const handleDeleteChecklistActionPlan = (state, action) => {
  return state.set('loadingDeleteChecklistActionPlan', true).set('successDeleteChecklistActionPlan', false).set('errorDeleteChecklistActionPlan', false);
};

const handleDeleteChecklistActionPlanSuccess = (state, action) => {
  return state.set('loadingDeleteChecklistActionPlan', false).set('successDeleteChecklistActionPlan', true).set('errorDeleteChecklistActionPlan', false);
};

const handleDeleteChecklistActionPlanError = (state, action) => {
  return state.set('loadingDeleteChecklistActionPlan', false).set('successDeleteChecklistActionPlan', false).set('errorDeleteChecklistActionPlan', action.error);
};

const handleFinishChecklist = (state, action) => {
  return state.set('loadingFinishChecklist', true).set('successFinishChecklist', false).set('errorFinishChecklist', false);
};

const handleFinishChecklistSuccess = (state, action) => {
  return state.set('loadingFinishChecklist', false).set('successFinishChecklist', true).set('errorFinishChecklist', false);
};

const handleFinishChecklistError = (state, action) => {
  return state.set('loadingFinishChecklist', false).set('successFinishChecklist', false).set('errorFinishChecklist', action.error);
};

const handleGetFinishedChecklist = (state, action) => {
  return state.set('loadingFinishedChecklist', true).set('successFinishedChecklist', false).set('errorFinishedChecklist', false);
};

const handleGetFinishedChecklistSuccess = (state, action) => {
  const { checklist } = action;
  return state.set('finishedChecklist', checklist).set('loadingFinishedChecklist', false).set('successFinishedChecklist', true).set('errorFinishedChecklist', false);
};

const handleGetFinishedChecklistError = (state, action) => {
  return state.set('loadingFinishedChecklist', false).set('successFinishedChecklist', false).set('errorFinishedChecklist', action.error);
};

const handleGetChecklistUploads = (state, action) => {
  return state.set('loadingUploads', true).set('successUploads', false).set('errorUploads', false);
};

const handleGetChecklistUploadsSuccess = (state, action) => {
  const { uploads } = action;
  return state.set('uploads', uploads).set('loadingUploads', false).set('successUploads', true).set('errorUploads', false);
};

const handleGetChecklistUploadsError = (state, action) => {
  return state.set('loadingUploads', false).set('successUploads', false).set('errorUploads', action.error);
};

const handleResetSaveSuccesses = (state, action) => {
  return state
    .set('successSaveAnswers', initialState.get('successSaveAnswers'))
    .set('successSaveChecklistFinishPreview', initialState.get('successSaveChecklistFinishPreview'))
    .set('successDeleteChecklistActionPlan', initialState.get('successDeleteChecklistActionPlan'));
};

const handleResetAnswersHistoric = (state, action) => {
  return state.set('answersHistoric', initialState.get('answersHistoric'));
};

const handleResetFinishError = (state, action) => {
  return state.set('errorFinishChecklist', initialState.get('errorFinishChecklist'));
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHECKLIST_PREVIEW:
      return handleGetChecklistPreview(state, action);
    case Types.GET_CHECKLIST_PREVIEW_SUCCESS:
      return handleGetChecklistPreviewSuccess(state, action);
    case Types.GET_CHECKLIST_PREVIEW_ERROR:
      return handleGetChecklistPreviewError(state, action);

    case Types.GET_CHECKLIST_QUESTION_GROUPS:
      return handleGetChecklistQuestionGroups(state, action);
    case Types.GET_CHECKLIST_QUESTION_GROUPS_SUCCESS:
      return handleGetChecklistQuestionGroupsSuccess(state, action);
    case Types.GET_CHECKLIST_QUESTION_GROUPS_ERROR:
      return handleGetChecklistQuestionGroupsError(state, action);

    case Types.GET_ANSWERS_HISTORIC:
      return handleGetAnswersHistoric(state, action);
    case Types.GET_ANSWERS_HISTORIC_SUCCESS:
      return handleGetAnswersHistoricSuccess(state, action);
    case Types.GET_ANSWERS_HISTORIC_ERROR:
      return handleGetAnswersHistoricError(state, action);

    case Types.POST_ANSWERS:
      return handleSaveAnswers(state, action);
    case Types.POST_ANSWERS_SUCCESS:
      return handleSaveAnswersSuccess(state, action);
    case Types.POST_ANSWERS_ERROR:
      return handleSaveAnswersError(state, action);

    case Types.GET_CHECKLIST_FINISH_PREVIEW:
      return handleGetChecklistFinishPreview(state, action);
    case Types.GET_CHECKLIST_FINISH_PREVIEW_SUCCESS:
      return handleGetChecklistFinishPreviewSuccess(state, action);
    case Types.GET_CHECKLIST_FINISH_PREVIEW_ERROR:
      return handleGetChecklistFinishPreviewError(state, action);

    case Types.UPDATE_CHECKLIST_FINISH_PREVIEW:
      return handleUpdateChecklistFinishPreview(state, action);
    case Types.UPDATE_CHECKLIST_FINISH_PREVIEW_SUCCESS:
      return handleUpdateChecklistFinishPreviewSuccess(state, action);
    case Types.UPDATE_CHECKLIST_FINISH_PREVIEW_ERROR:
      return handleUpdateChecklistFinishPreviewError(state, action);

    case Types.DELETE_CHECKLIST_ACTION_PLAN:
      return handleDeleteChecklistActionPlan(state, action);
    case Types.DELETE_CHECKLIST_ACTION_PLAN_SUCCESS:
      return handleDeleteChecklistActionPlanSuccess(state, action);
    case Types.DELETE_CHECKLIST_ACTION_PLAN_ERROR:
      return handleDeleteChecklistActionPlanError(state, action);

    case Types.FINISH_CHECKLIST:
      return handleFinishChecklist(state, action);
    case Types.FINISH_CHECKLIST_SUCCESS:
      return handleFinishChecklistSuccess(state, action);
    case Types.FINISH_CHECKLIST_ERROR:
      return handleFinishChecklistError(state, action);

    case Types.GET_FINISHED_CHECKLIST:
      return handleGetFinishedChecklist(state, action);
    case Types.GET_FINISHED_CHECKLIST_SUCCESS:
      return handleGetFinishedChecklistSuccess(state, action);
    case Types.GET_FINISHED_CHECKLIST_ERROR:
      return handleGetFinishedChecklistError(state, action);

    case Types.GET_CHECKLIST_UPLOADS:
      return handleGetChecklistUploads(state, action);
    case Types.GET_CHECKLIST_UPLOADS_SUCCESS:
      return handleGetChecklistUploadsSuccess(state, action);
    case Types.GET_CHECKLIST_UPLOADS_ERROR:
      return handleGetChecklistUploadsError(state, action);

    case Types.RESET_SAVE_SUCCESSES:
      return handleResetSaveSuccesses(state, action);
    case Types.RESET_ANSWERS_HISTORIC:
      return handleResetAnswersHistoric(state, action);
    case Types.RESET_FINISH_ERROR:
      return handleResetFinishError(state, action);
    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}