import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import {
  getClassificationContacts as getClassificationContactsSdk,
  getClassificationContactById as getClassificationContactByIdSdk,
  putClassificationContact as putClassificationContactSdk,
  delClassificationContact as delClassificationContactSdk,
} from '@sdk/Contatos';

//Action Types
export const Types = {
  GET_CLASSIFICATION_CONTACTS: 'GET_CLASSIFICATION_CONTACTS',
  GET_CLASSIFICATION_CONTACTS_SUCCESS: 'GET_CLASSIFICATION_CONTACTS_SUCCESS',
  GET_CLASSIFICATION_CONTACTS_ERROR: 'GET_CLASSIFICATION_CONTACTS_ERROR',

  GET_CLASSIFICATION_CONTACT_BY_ID: 'GET_CLASSIFICATION_CONTACT_BY_ID',
  GET_CLASSIFICATION_CONTACT_BY_ID_SUCCESS: 'GET_CLASSIFICATION_CONTACT_BY_ID_SUCCESS',
  GET_CLASSIFICATION_CONTACT_BY_ID_ERROR: 'GET_CLASSIFICATION_CONTACT_BY_ID_ERROR',

  PUT_CLASSIFICATION_CONTACT: 'PUT_CLASSIFICATION_CONTACT',
  PUT_CLASSIFICATION_CONTACT_SUCCESS: 'PUT_CLASSIFICATION_CONTACT_SUCCESS',
  PUT_CLASSIFICATION_CONTACT_ERROR: 'PUT_CLASSIFICATION_CONTACT_ERROR',

  DEL_CLASSIFICATION_CONTACT: 'DEL_CLASSIFICATION_CONTACT',
  DEL_CLASSIFICATION_CONTACT_SUCCESS: 'DEL_CLASSIFICATION_CONTACT_SUCCESS',
  DEL_CLASSIFICATION_CONTACT_ERROR: 'DEL_CLASSIFICATION_CONTACT_ERROR',
};

//Action Creators
export const getClassificationContacts = () => ({ type: Types.GET_CLASSIFICATION_CONTACTS });
export const getClassificationContactsSuccess = (classificationContacts) => ({ type: Types.GET_CLASSIFICATION_CONTACTS_SUCCESS, classificationContacts });
export const getClassificationContactsError = (error) => ({ type: Types.GET_CLASSIFICATION_CONTACTS_ERROR, error });

export const getClassificationContactById = (id) => ({ type: Types.GET_CLASSIFICATION_CONTACT_BY_ID, id });
export const getClassificationContactByIdSuccess = (classificationContact) => ({ type: Types.GET_CLASSIFICATION_CONTACT_BY_ID_SUCCESS, classificationContact });
export const getClassificationContactByIdError = (error) => ({ type: Types.GET_CLASSIFICATION_CONTACT_BY_ID_ERROR, error });

export const putClassificationContact = (classification) => ({ type: Types.PUT_CLASSIFICATION_CONTACT, classification });
export const putClassificationContactSuccess = (updatedClassifications) => ({ type: Types.PUT_CLASSIFICATION_CONTACT_SUCCESS, updatedClassifications });
export const putClassificationContactError = (error) => ({ type: Types.PUT_CLASSIFICATION_CONTACT_ERROR, error });

export const delClassificationContact = (id) => ({ type: Types.DEL_CLASSIFICATION_CONTACT, id });
export const delClassificationContactSuccess = () => ({ type: Types.DEL_CLASSIFICATION_CONTACT_SUCCESS });
export const delClassificationContactError = (error) => ({ type: Types.DEL_CLASSIFICATION_CONTACT_ERROR, error });

//saga
function* fetchGetClassificationContacts(action) {
  try {
    const classificationContacts = yield call(getClassificationContactsSdk);
    
    yield put(getClassificationContactsSuccess(classificationContacts));
  } catch (err) {
    Notification.error(err.message);
    yield put(getClassificationContactsError(err));
  }
}

function* fetchGetClassificationContactById(action) {
  try {
    const { id } = action;
    const classificationContact = yield call(getClassificationContactByIdSdk, id);
    yield put(getClassificationContactByIdSuccess(classificationContact));
  } catch (err) {
    Notification.error(err.message);
    yield put(getClassificationContactByIdError(err));
  }
}

function* fetchPutClassificationContact(action) {
  try {
    const { classification } = action;
    const resp = yield call(putClassificationContactSdk, classification);
    Notification.success('Classificação de contato salva com sucesso');
    yield put(putClassificationContactSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(putClassificationContactError(err));
  }
}

function* fetchDelClassificationContact(action) {
  try {
    const { id } = action;
    yield call(delClassificationContactSdk, id);
    Notification.success('Classificação de contato excluída com sucesso');
    yield put(delClassificationContactSuccess());
  } catch (err) {
    Notification.error('Não é possível excluir uma classificação que está em uso');
    yield put(delClassificationContactError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CLASSIFICATION_CONTACTS, fetchGetClassificationContacts),
  takeLatest(Types.GET_CLASSIFICATION_CONTACT_BY_ID, fetchGetClassificationContactById),
  takeLatest(Types.PUT_CLASSIFICATION_CONTACT, fetchPutClassificationContact),
  takeLatest(Types.DEL_CLASSIFICATION_CONTACT, fetchDelClassificationContact),
];

// Reducer
const initialState = Map({
  classificationContacts: Map(),
  loadingClassificationContacts: false,
  successClassificationContacts: false,
  errorClassificationContacts: false,

  classificationContact: Map(),
  loadingClassificationContact: false,
  successClassificationContact: false,
  errorClassificationContact: false,

  loadingPutClassificationContact: false,
  successPutClassificationContact: false,
  errorPutClassificationContact: false,

  loadingDelClassificationContact: false,
  successDelClassificationContact: false,
  errorDelClassificationContact: false,
});

const handleGetClassificationContacts = (state, action) => {
  return state
    .set('loadingClassificationContacts', true)
    .set('successClassificationContacts', false)
    .set('errorClassificationContacts', false);
};

const handleGetClassificationContactsSuccess = (state, action) => {
  const { classificationContacts } = action;

  return state
    .set('classificationContacts', classificationContacts)
    .set('loadingClassificationContacts', false)
    .set('successClassificationContacts', true)
    .set('errorClassificationContacts', false);
};

const handleGetClassificationContactsError = (state, action) => {
  return state
    .set('loadingClassificationContacts', false)
    .set('successClassificationContacts', false)
    .set('errorClassificationContacts', action.error);
};

const handleGetClassificationContactById = (state, action) => {
  return state
    .set('loadingClassificationContact', true)
    .set('successClassificationContact', false)
    .set('errorClassificationContact', false);
};

const handleGetClassificationContactByIdSuccess = (state, action) => {
  const { classificationContact } = action;

  return state
    .set('classificationContact', classificationContact)
    .set('loadingClassificationContact', false)
    .set('successClassificationContact', true)
    .set('errorClassificationContact', false);
};

const handleGetClassificationContactByIdError = (state, action) => {
  return state
    .set('loadingClassificationContact', false)
    .set('successClassificationContact', false)
    .set('errorClassificationContact', action.error);
};

const handlePutClassificationContact = (state, action) => {
  return state
    .set('loadingPutClassificationContact', true)
    .set('successPutClassificationContact', false)
    .set('errorPutClassificationContact', false);
};

const handlePutClassificationContactSuccess = (state, action) => {
  const { updatedClassifications } = action;

  const newClassificationContacts = [
    ...updatedClassifications.createdClassifications,
    ...updatedClassifications.updatedClassifications
  ];

  return state
    .set('classificationContacts', newClassificationContacts.sort((a, b) => a.order - b.order))
    .set('loadingPutClassificationContact', false)
    .set('successPutClassificationContact', true)
    .set('errorPutClassificationContact', false);
};

const handlePutClassificationContactError = (state, action) => {
  return state
    .set('loadingPutClassificationContact', false)
    .set('successPutClassificationContact', false)
    .set('errorPutClassificationContact', action.error);
};

const handleDelClassificationContact = (state, action) => {
  return state
    .set('loadingDelClassificationContact', true)
    .set('successDelClassificationContact', false)
    .set('errorDelClassificationContact', false);
};

const handleDelClassificationContactSuccess = (state, action) => {
  return state
    .set('loadingDelClassificationContact', false)
    .set('successDelClassificationContact', true)
    .set('errorDelClassificationContact', false);
};

const handleDelClassificationContactError = (state, action) => {
  return state
    .set('loadingDelClassificationContact', false)
    .set('successDelClassificationContact', false)
    .set('errorDelClassificationContact', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CLASSIFICATION_CONTACTS: return handleGetClassificationContacts(state, action);
    case Types.GET_CLASSIFICATION_CONTACTS_SUCCESS: return handleGetClassificationContactsSuccess(state, action);
    case Types.GET_CLASSIFICATION_CONTACTS_ERROR: return handleGetClassificationContactsError(state, action);

    case Types.GET_CLASSIFICATION_CONTACT_BY_ID: return handleGetClassificationContactById(state, action);
    case Types.GET_CLASSIFICATION_CONTACT_BY_ID_SUCCESS: return handleGetClassificationContactByIdSuccess(state, action);
    case Types.GET_CLASSIFICATION_CONTACT_BY_ID_ERROR: return handleGetClassificationContactByIdError(state, action);
    
    case Types.PUT_CLASSIFICATION_CONTACT: return handlePutClassificationContact(state, action);
    case Types.PUT_CLASSIFICATION_CONTACT_SUCCESS: return handlePutClassificationContactSuccess(state, action);
    case Types.PUT_CLASSIFICATION_CONTACT_ERROR: return handlePutClassificationContactError(state, action);

    case Types.DEL_CLASSIFICATION_CONTACT: return handleDelClassificationContact(state, action);
    case Types.DEL_CLASSIFICATION_CONTACT_SUCCESS: return handleDelClassificationContactSuccess(state, action);
    case Types.DEL_CLASSIFICATION_CONTACT_ERROR: return handleDelClassificationContactError(state, action);
    
    default: return state;
  }
}
