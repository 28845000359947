import { get, post } from '../api';
import { getQueryParamsUnidadesAdimplencia } from './rules';
import { InfoUsuario } from '@utils';

export const saveDelinquenciesFromExcel = (excelUrl) => {
  return post('/integration/company-delinquency/', { fileUrl: excelUrl }, {}, true);
};

export const getCompaniesDelinquencyList = (params) => {
  const searchByCnpjParam = InfoUsuario.get('senhaMaster') || InfoUsuario.perm('unidadesCNPJ') ? '&searchByCnpj=true' : '';
  const queryParams = getQueryParamsUnidadesAdimplencia(params);
  return get(
    `/api/companies/delinquency/list?loggedUser=${InfoUsuario.get('cdUsuario')}${searchByCnpjParam}${queryParams}`, 
    {}, 
    {}, 
    true
  );
};

export const getCompaniesDelinquencyIndicators = (params) => {
  const searchByCnpjParam = InfoUsuario.get('senhaMaster') || InfoUsuario.perm('unidadesCNPJ') ? '&searchByCnpj=true' : '';
  const queryParams = getQueryParamsUnidadesAdimplencia(params);
  return get(
    `/api/companies/delinquency?loggedUser=${InfoUsuario.get('cdUsuario')}${searchByCnpjParam}${queryParams}`, 
    {}, 
    {}, 
    true
  );
};

export const getCompaniesDelinquentsTotals = (params) => {
  const searchByCnpjParam = InfoUsuario.get('senhaMaster') || InfoUsuario.perm('unidadesCNPJ') ? '&searchByCnpj=true' : '';
  const queryParams = getQueryParamsUnidadesAdimplencia(params);
  return get(
    `/api/companies/delinquents/totals?loggedUser=${InfoUsuario.get('cdUsuario')}${searchByCnpjParam}${queryParams}`, 
    {}, 
    {}, 
    true
  );
};