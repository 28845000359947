import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getChecklistTemplates as getChecklistTemplatesSdk,
  getChecklistTemplate as getChecklistTemplateSdk,
  putChecklistTemplate as putChecklistTemplateSdk,
  delChecklistTemplate as delChecklistTemplateSdk,
} from '@sdk/ChecklistTemplates';

export const FILTERS_KEY = 'FILTROS/CHECKLIST_TEMPLATES';

//Action Types
export const Types = {
  GET_CHECKLIST_TEMPLATES: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATES',
  GET_CHECKLIST_TEMPLATES_SUCCESS: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATES_SUCCESS',
  GET_CHECKLIST_TEMPLATES_ERROR: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATES_ERROR',

  GET_CHECKLIST_TEMPLATE: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATE',
  GET_CHECKLIST_TEMPLATE_SUCCESS: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATE_SUCCESS',
  GET_CHECKLIST_TEMPLATE_ERROR: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATE_ERROR',

  PUT_CHECKLIST_TEMPLATE: 'CHECKLIST_TEMPLATES/PUT_CHECKLIST_TEMPLATE',
  PUT_CHECKLIST_TEMPLATE_SUCCESS: 'CHECKLIST_TEMPLATES/PUT_CHECKLIST_TEMPLATE_SUCCESS',
  PUT_CHECKLIST_TEMPLATE_ERROR: 'CHECKLIST_TEMPLATES/PUT_CHECKLIST_TEMPLATE_ERROR',

  DEL_CHECKLIST_TEMPLATE: 'CHECKLIST_TEMPLATES/DEL_CHECKLIST_TEMPLATE',
  DEL_CHECKLIST_TEMPLATE_SUCCESS: 'CHECKLIST_TEMPLATES/DEL_CHECKLIST_TEMPLATE_SUCCESS',
  DEL_CHECKLIST_TEMPLATE_ERROR: 'CHECKLIST_TEMPLATES/DEL_CHECKLIST_TEMPLATE_ERROR',

  RESET: 'CHECKLIST_TEMPLATES/RESET',
};

//Action Creators
export const getChecklistTemplates = (params) => ({ type: Types.GET_CHECKLIST_TEMPLATES, params });
export const getChecklistTemplatesSuccess = (response) => ({ type: Types.GET_CHECKLIST_TEMPLATES_SUCCESS, response });
export const getChecklistTemplatesError = (error) => ({ type: Types.GET_CHECKLIST_TEMPLATES_ERROR, error }); 

export const getChecklistTemplate = (id) => ({ type: Types.GET_CHECKLIST_TEMPLATE, id });
export const getChecklistTemplateSuccess = (response) => ({ type: Types.GET_CHECKLIST_TEMPLATE_SUCCESS, response });
export const getChecklistTemplateError = (error) => ({ type: Types.GET_CHECKLIST_TEMPLATE_ERROR, error });

export const putChecklistTemplate = (params) => ({ type: Types.PUT_CHECKLIST_TEMPLATE, params });
export const putChecklistTemplateSuccess = (response) => ({ type: Types.PUT_CHECKLIST_TEMPLATE_SUCCESS, response });
export const putChecklistTemplateError = (error) => ({ type: Types.PUT_CHECKLIST_TEMPLATE_ERROR, error });

export const delChecklistTemplate = (id) => ({ type: Types.DEL_CHECKLIST_TEMPLATE, id });
export const delChecklistTemplateSuccess = (response) => ({ type: Types.DEL_CHECKLIST_TEMPLATE_SUCCESS, response });
export const delChecklistTemplateError = (error) => ({ type: Types.DEL_CHECKLIST_TEMPLATE_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetChecklistTemplates(action) {
  try {
    const { params } = action;
    const checklistTemplates = yield call(getChecklistTemplatesSdk, params);
    yield put(getChecklistTemplatesSuccess(checklistTemplates));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistTemplatesError(err));
  }
}

function* fetchGetChecklistTemplate(action) {
  try {
    const { id } = action;
    const checklistTemplate = yield call(getChecklistTemplateSdk, id);
    yield put(getChecklistTemplateSuccess(checklistTemplate));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistTemplateError(err));
  }
}

function* fetchPutChecklistTemplate(action) {
  try {
    const { params } = action;
    const checklistTemplate = yield call(putChecklistTemplateSdk, params);
    yield put(putChecklistTemplateSuccess(checklistTemplate));
  } catch (err) {
    Notification.error(err.message);
    yield put(putChecklistTemplateError(err));
  }
}

function* fetchDelChecklistTemplate(action) {
  try {
    const { id } = action;
    const checklistTemplate = yield call(delChecklistTemplateSdk, id);
    yield put(delChecklistTemplateSuccess(checklistTemplate));
    Notification.success('Checklist Template excluido com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(delChecklistTemplateError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CHECKLIST_TEMPLATES, fetchGetChecklistTemplates),
  takeLatest(Types.GET_CHECKLIST_TEMPLATE, fetchGetChecklistTemplate),
  takeLatest(Types.PUT_CHECKLIST_TEMPLATE, fetchPutChecklistTemplate),
  takeLatest(Types.DEL_CHECKLIST_TEMPLATE, fetchDelChecklistTemplate),
];

// Reducer
const initialState = Map({
  checklistTemplates: List(),
  total: 0,
  loading: false,
  success: false,
  error: false,

  checklistTemplate: Map(),
  loadingChecklistTemplate: false,
  successChecklistTemplate: false,
  errorChecklistTemplate: false,

  loadingSaveChecklistTemplate: false,
  successSaveChecklistTemplate: false,
  errorSaveChecklistTemplate: false,

  loadingDelChecklistTemplate: false,
  successDelChecklistTemplate: false,
  errorDelChecklistTemplate: false,
});

const handleGetChecklistTemplates = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetChecklistTemplatesSuccess = (state, action) => {
  const { response } = action;

  return state.set('checklistTemplates', response.templates).set('total', response.total)
    .set('loading', false).set('success', true).set('error', false);
};

const handleGetChecklistTemplatesError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleGetChecklistTemplate = (state, action) => {
  return state.set('loadingChecklistTemplate', true).set('successChecklistTemplate', false).set('errorChecklistTemplate', false);
};

const handleGetChecklistTemplateSuccess = (state, action) => {
  return state.set('checklistTemplate', action.response).set('loadingChecklistTemplate', false).set('successChecklistTemplate', true).set('errorChecklistTemplate', false);
};

const handleGetChecklistTemplateError = (state, action) => {
  return state.set('loadingChecklistTemplate', false).set('successChecklistTemplate', false).set('errorChecklistTemplate', action.error);
};

const handlePutChecklistTemplate = (state, action) => {
  return state.set('loadingSaveChecklistTemplate', true).set('successSaveChecklistTemplate', false).set('errorSaveChecklistTemplate', false);
};

const handlePutChecklistTemplateSuccess = (state, action) => {
  return state.set('loadingSaveChecklistTemplate', false).set('successSaveChecklistTemplate', true).set('errorSaveChecklistTemplate', false);
};

const handlePutChecklistTemplateError = (state, action) => {
  return state.set('loadingSaveChecklistTemplate', false).set('successSaveChecklistTemplate', false).set('errorSaveChecklistTemplate', action.error);
};

const handleDelChecklistTemplate = (state, action) => {
  return state.set('loadingDelChecklistTemplate', true).set('successDelChecklistTemplate', false).set('errorDelChecklistTemplate', false);
};

const handleDelChecklistTemplateSuccess = (state, action) => {
  return state.set('loadingDelChecklistTemplate', false).set('successDelChecklistTemplate', true).set('errorDelChecklistTemplate', false);
};

const handleDelChecklistTemplateError = (state, action) => {
  return state.set('loadingDelChecklistTemplate', false).set('successDelChecklistTemplate', false).set('errorDelChecklistTemplate', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHECKLIST_TEMPLATES:
      return handleGetChecklistTemplates(state, action);
    case Types.GET_CHECKLIST_TEMPLATES_SUCCESS:
      return handleGetChecklistTemplatesSuccess(state, action);
    case Types.GET_CHECKLIST_TEMPLATES_ERROR:
      return handleGetChecklistTemplatesError(state, action);

    case Types.GET_CHECKLIST_TEMPLATE:
      return handleGetChecklistTemplate(state, action);
    case Types.GET_CHECKLIST_TEMPLATE_SUCCESS:
      return handleGetChecklistTemplateSuccess(state, action);
    case Types.GET_CHECKLIST_TEMPLATE_ERROR:
      return handleGetChecklistTemplateError(state, action); 

    case Types.PUT_CHECKLIST_TEMPLATE:
      return handlePutChecklistTemplate(state, action);
    case Types.PUT_CHECKLIST_TEMPLATE_SUCCESS:
      return handlePutChecklistTemplateSuccess(state, action);
    case Types.PUT_CHECKLIST_TEMPLATE_ERROR:
      return handlePutChecklistTemplateError(state, action);

    case Types.DEL_CHECKLIST_TEMPLATE:
      return handleDelChecklistTemplate(state, action);
    case Types.DEL_CHECKLIST_TEMPLATE_SUCCESS:
      return handleDelChecklistTemplateSuccess(state, action);
    case Types.DEL_CHECKLIST_TEMPLATE_ERROR:
      return handleDelChecklistTemplateError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}
