import styled from 'styled-components';
import { Image, Row, Text, Icon, Input, Column } from '@components-teammove';

export const ChatHeader = styled(Row)`
  padding: 10px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
`;

export const UserProfilePicture = styled(Image)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const UserName = styled(Text)`
  font-size: 1.2em;
  color: white;
`;

export const ChatMessagesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  overflow-y: auto;
  max-height: 550px;
  min-height: 550px;
`;

export const ChatMessages = styled(Column)`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
`;

export const MessageBubble = styled(Row)`
  max-width: 70%;
  margin: 2px;
  padding: ${({ isImage }) => (isImage ? '4px' : '10px')};
  border-radius: 10px;
  background-color: ${({ isSender, theme }) => (isSender ? '#005C4B' : theme.backgroundColor)};
  color: white;
  align-self: ${({ isSender }) => (isSender ? 'flex-end' : 'flex-start')};
  flex-direction: column;
  gap: 5px;
  word-wrap: break-word;
  position: relative;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 250px;
  width: 220px;
  height: auto;
  min-width: 150px;
  min-height: 100px;
  border-radius: 10px;
  object-fit: cover;
`;

export const StyledVideo = styled.video`
  max-width: 100%;
  max-height: 250px;
  width: 220px;
  height: auto;
  min-width: 150px;
  min-height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 4px;
`;

export const VideoContainer = styled.div`
  position: relative;
  display: inline-block;
  max-width: 100%;
  max-height: 250px;
  width: 220px;
  height: auto;
  min-width: 150px;
  min-height: 100px;
`;

export const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const TextImage = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  word-wrap: break-word;
`;

export const MessageTimeAndStatus = styled(Row)`
  margin-top: 10px;
`;

export const MessageTime = styled(Text)`
  opacity: 0.5;
  font-size: 0.8em;
  color: #ccc;
  margin-right: 8px;
`;

export const MessageStatus = styled(Icon.MDI)`
  width: 16px !important;
  height: 16px !important;
  color: ${(props) => (props.status === 'read' ? '#4fc3f7' : '#ccc')};
`;

export const ChatInputContainer = styled.div`
  display: flex;
  padding: 10px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
`;

export const TemplateContainer = styled.div`
  margin-left: 350px;
  display: flex;
  padding: 10px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.actionColor};
`;

export const ChatInput = styled(Input)`
  flex: 1;
  padding: 10px;
  border: none !important;
  border-radius: 20px;
  margin-right: 10px;
  outline: none;
  color: white;
  background-color: ${({ theme }) => theme.darkSecondaryColor} !important;

  input {
    background-color: ${({ theme }) => theme.darkSecondaryColor} !important;
    color: white;

    ::placeholder {
      color: #F5F5F580;
    }
  }
`;

export const ChatWrapper = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 10px;
`;

export const PdfLink = styled.a`
  color: #1890ff;
  text-decoration: underline;
  display: block;
  margin: 10px 0;
`;

export const AudioMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  position: relative;
`;

export const AudioPlayerWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const MessageTimeAndStatusAudio = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
  padding-right: 15px;
  box-sizing: border-box;
`;

export const MessageTimeAndStatusImage = styled(Row)`
  position: absolute;
  bottom: ${({ hasAttachmentAndContent }) => (hasAttachmentAndContent ? '5px' : '30px')};
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

export const ModalVideo = styled.video`
  width: 80%;
  height: 80%;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;