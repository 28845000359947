export const optionsConfigurationAttachment = [
  { value: 'I', label: 'Imagem' },
  { value: 'V', label: 'Video' },
  { value: 'IV', label: 'Imagem/Video' },
];

export const optionsAttachments = [
  { value: 'camera', label: 'Câmera' },
  { value: 'galeria', label: 'Galeria' },
  { value: 'audio', label: 'Audio' },
  { value: 'documento', label: 'Documento' },
];