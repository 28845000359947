import styled from 'styled-components';
import { Card, View, Title } from '@components';
import { Text, Button, Row } from '@components-teammove';

export const ScrollableRow = styled(Row)`
  overflow-x: scroll;
  padding-bottom: 12px;
`;

export const MarginAuto = styled(View)`
  margin: auto;
`;

export const StyledCard = styled(Card)`
  width: fit-content;
  height: 310px;
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  &.ant-card {
    border: none;
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    color: ${({ theme }) => theme.textColor};
    border-radius: 18px;
  }
  
  .tabela-indicadores{
    overflow-y: auto !important;
  }

  .ant-card-body{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    gap: 8px;

    ::before, ::after {
      display: none;
    }
  }

  .CircularProgressbar-text {
    fill: yellow;
    margin: auto;
  }

  ${({ isProgress }) => isProgress && `
    .ant-card-body > div{
      height: 128px;

      div > div{
        justify-content: flex-end !important;
      }
    }
  `}
`;

export const CardTitle = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const ContentWrapper = styled(View)`
  flex: 1;
  justify-content: center;
  min-width: 300px;
`;

export const ChartInnerContent = styled(View)`
  justify-self: flex-end;
`;

export const CurrentValue = styled(Title)`
  &.ant-typography {
    margin-bottom: 0;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const CenteredButton = styled(Button)`
  margin-top: auto;
`;

export const MarginTopButton = styled(Button)`
  margin: 1.5rem auto 0;
`;

export const DetailCard = styled(Card)`
  &.ant-card {
    margin: 1rem;
    text-align: center;
    background-color: ${({ theme }) => theme.darkSecondaryColor};

    .tabela-indicadores{
      overflow-y: auto !important;
    }

    .ant-card-head-title, .ant-card-extra, .tabela-indicadores {
      color: ${({ theme }) => theme.textColor};
    }

    border-color: ${({ theme }) => theme.darkHighlightColor};

    .ant-card-head {
      border-color: ${({ theme }) => theme.darkHighlightColor};
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
`;