import React, { useEffect, useState } from 'react';
import { Column, View, Text, Form, Switch, Input, Grid, Skeleton, Table, Divider } from '@components-teammove';
import { authorization } from '@sdk/api';
import { InfoUsuario, Notification } from '@utils';
import { ComponentEmptyCover, UploadEdge, UploadIcon, UploadText } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { uploadInadimplencia, reset } from '@ducks/companyDelinquency';
import { getDelinquencyConfiguration } from '@ducks/companyDelinquencyConfiguration';

const { REACT_APP_API } = window.env;

export const DelinquencyImport = ({ form }) => {
  const dispatch = useDispatch();

  const delinquencyConfiguration = useSelector(({ companyDelinquencyConfiguration }) => companyDelinquencyConfiguration.get('delinquencyConfiguration'));
  const loadingDelinquencyConfiguration = useSelector(({ companyDelinquencyConfiguration }) => companyDelinquencyConfiguration.get('loadingDelinquencyConfiguration'));
  const successDelinquencyConfiguration = useSelector(({ companyDelinquencyConfiguration }) => companyDelinquencyConfiguration.get('successDelinquencyConfiguration'));
  const successDelinquencyConfigurationSave = useSelector(({ companyDelinquencyConfiguration }) => companyDelinquencyConfiguration.get('successDelinquencyConfigurationSave'));

  const successUploadInadimplencia = useSelector(({ companyDelinquency }) => companyDelinquency.get('successUploadInadimplencia'));
  const uploadInadimplenciaResponse = useSelector(({ companyDelinquency }) => companyDelinquency.get('uploadInadimplenciaResponse'));

  const [excelUrl, setExcelUrl] = useState();
  const [isBoletoFieldEnabled, setIsBoletoFieldEnabled] = useState(false);

  const [aliases, setAliases] = useState({
    observation: '',
    document: '',
    identifier: '',
    deadline: '',
    amount: '',
    ticketUrl: ''
  });
  
  useEffect(() => {
    dispatch(getDelinquencyConfiguration());

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (successDelinquencyConfiguration || successDelinquencyConfigurationSave) {
      form.setFieldsValue(delinquencyConfiguration);
      setAliases({
        observation: delinquencyConfiguration.alias_observacao,
        document: delinquencyConfiguration.alias_nr_documento,
        identifier: delinquencyConfiguration.alias_identificador,
        deadline: delinquencyConfiguration.alias_vencimento,
        amount: delinquencyConfiguration.alias_valor,
        ticketUrl: delinquencyConfiguration.alias_boleto
      });
    }
  }, [successDelinquencyConfiguration, successDelinquencyConfigurationSave]);

  useEffect(() => {
    if (excelUrl) {
      dispatch(uploadInadimplencia(excelUrl));
    }
  }, [excelUrl]);

  useEffect(() => {
    if (successUploadInadimplencia) {
      Notification.success('Tabela importada com sucesso!');

      if (uploadInadimplenciaResponse.missingCompanies.length) {
        Notification.warning(
          `Os seguintes valores na coluna ${aliases.identifier} não foram encontrados: ${uploadInadimplenciaResponse.missingCompanies.join(', ')}`
        );
      }
    }
  }, [successUploadInadimplencia, uploadInadimplenciaResponse]);

  const handleChangeAttachment = (info) => {
    if (info.file.status === 'done') {
      setExcelUrl(info.file.response);
    }
  };

  return (
    <Column gap='20px'>
      
      {loadingDelinquencyConfiguration ? (
        <Skeleton.Form />
      )
        : (
          <>
            <Form form={form}>
              <Form.Item name='id' hidden/>
              <Form.Item name='utiliza_boleto' label='Usa boleto' labelSide='right' colon={false} valuePropName='checked'>
                <Switch onChange={(checked) => setIsBoletoFieldEnabled(checked)}/>
              </Form.Item>
              <Form.Item name='utiliza_coluna_de_observacao' label='Mostrar coluna observação' valuePropName='checked' labelSide='right' colon={false}>
                <Switch/>
              </Form.Item>
            </Form>

            <Form form={form} layout='vertical'>    
              <Grid numberOfColumns={4} gap='0 12px'>
                <Form.Item name='alias_vencimento' label='Nomenclatura da coluna Vencimento'>
                  <Input placeholder='Vencimento' onBlur={(event) => setAliases((oldAliases) => ({ ...oldAliases, deadline: event.target?.value }))}/>
                </Form.Item>
                <Form.Item name='alias_nr_documento' label='Nomenclatura da coluna Documento' >
                  <Input placeholder='Documento' onBlur={(event) => setAliases((oldAliases) => ({ ...oldAliases, document: event.target?.value }))}/>
                </Form.Item>
                <Form.Item name='alias_valor' label='Nomenclatura da coluna Valor'>
                  <Input placeholder='Valor' onBlur={(event) => setAliases((oldAliases) => ({ ...oldAliases, amount: event.target?.value }))}/>
                </Form.Item>
                <Form.Item name='alias_observacao' label='Nomenclatura da coluna Observação'>
                  <Input placeholder='Obs' onBlur={(event) => setAliases((oldAliases) => ({ ...oldAliases, observation: event.target?.value }))}/>
                </Form.Item>
                <Form.Item name='alias_identificador' label='Nomenclatura da coluna Identificador'>
                  <Input placeholder='Identificador' onBlur={(event) => setAliases((oldAliases) => ({ ...oldAliases, identifier: event.target?.value }))}/>
                </Form.Item>
                <Form.Item name='alias_boleto' label='Nomenclatura da coluna Boleto'>
                  <Input placeholder='Boleto' onBlur={(event) => setAliases((oldAliases) => ({ ...oldAliases, ticketUrl: event.target?.value }))} disabled={!isBoletoFieldEnabled}/>
                </Form.Item>
              </Grid>
            </Form>
          </>
        )}
      <Divider/>
      <Column gap='20px'>
        <Column gap='20px'>
          <Text size='28px' weight='700'>Upload da planilha</Text>
          <Text size='18px' weight='400'>Esperamos que ela venha nesse formato:</Text>
          <Table
            columns={Object.entries(aliases).map(([key, value]) => ({ title: value, dataIndex: key }))}
            dataSource={[
              { observation: '1000', document: '10000000', identifier: '000000001', deadline: '02/10/2024', amount: '1000,00' },
              { observation: '2000', document: '10000001', identifier: '000000002', deadline: '02/10/2024', amount: '1500,00' },
            ]}
          />
        </Column>
        <UploadEdge
          name="file"
          showUploadList={false}
          headers={authorization()}
          folder={`importacao/company-delinquency/${InfoUsuario.get('cdCliente')}`}
          URI={REACT_APP_API}
          accept=".xls, .xlsx"
          onChange={handleChangeAttachment}
        >
          <View>
            <ComponentEmptyCover>
              <UploadIcon type='upload' />
              <UploadText>Clique ou arraste para anexar</UploadText>
            </ComponentEmptyCover>
          </View>
        </UploadEdge>
      </Column>
    </Column>
  );
};