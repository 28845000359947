import styled from 'styled-components';
import { Table } from '@components-teammove';

export const StyledTable = styled(Table)`
  &.ant-table {
    .ant-table-column-sorters {
      justify-content: center;
    }
  }
`;

export const TotalCell = styled.div`
  text-align: center;
  font-weight: bold;
`;