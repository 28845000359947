import React from 'react';
import { formatTime } from '@utils';
import Item from './Item';
import { getActionTypeFromMessage, ACTION, getLabelStatusToOpen, getIconChangeResponsible, getLabelChangeResponsible, getLabelChangeCategory, getLabelChangeTime, getLabelDetailsWaitingAnswer, getLabelDetailsResponseWaitingAnswer } from './rules';
import { ActionText } from './styles';

export default function Action({ historic }) {
  const ACTION_FROM_MESSAGE = getActionTypeFromMessage(historic.mensagem);

  switch (ACTION_FROM_MESSAGE) {
    case ACTION.CHANGE_STATUS_TO_OPEN:
      return (
        <Item 
          icon='send-outline'
          message={`<span>${getLabelStatusToOpen(historic)}</span>`}
          date={historic.dtHistorico}
        />
      );
    case ACTION.CHANGE_STATUS_TO_IN_ATTENDANCE:
      return (
        <Item 
          icon='play-circle-outline'
          message={`<span>${historic.nmUsuario} <b>iniciou</b> o atendimento</span>`}
          date={historic.dtHistorico}
        />
      );
    case ACTION.CHANGE_STATUS_TO_AWAITING_EVALUATION: 
      return (
        <Item 
          icon='check-circle-outline'
          message={`<span>${historic.nmUsuario} <b>finalizou</b> o atendimento</span>`}
          date={historic.dtHistorico}
        />
      );
    case ACTION.CHANGE_STATUS_TO_REOPENED:
      return (
        <Item 
          icon='reload'
          message={`<span>${historic.nmUsuario} <b>reabriu</b> o ticket</span>`}
          date={historic.dtHistorico}
        />
      );
    case ACTION.CHANGE_STATUS_TO_CLOSED:
      return (
        <Item 
          icon='handshake'
          message={'<span>Atendimento <b>fechado</b> com sucesso</span>'}
          date={historic.dtHistorico}
        />
      );
    case ACTION.CHANGE_RESPONSIBLE:
      return (
        <Item 
          icon={getIconChangeResponsible(historic)}
          message={`<span>${getLabelChangeResponsible(historic)}</span>`}
          date={historic.dtHistorico}
        />
      );
    case ACTION.CHANGE_CATEGORY:
      return (
        <Item 
          icon='elevator'
          message={`<span>${getLabelChangeCategory(historic)}</span>`}
          date={historic.dtHistorico}
        />
      );
    case ACTION.CHANGE_TIME:
      return (
        <Item 
          icon='clock-outline'
          message={`<span>${getLabelChangeTime(historic)}</span>`}
          date={historic.dtHistorico}
        />
      );
    case ACTION.WAITING_FOR_ANSWER:
      return (
        <Item 
          icon='alert-outline'
          message={`<span>${historic.nmUsuario} está <b>aguardando</b> pendência </span>`}
          date={historic.dtHistorico}
          details={getLabelDetailsWaitingAnswer(historic)}
        />
      );
    case ACTION.RESPONSE_WAITING_FOR_ANSWER:
      return (
        <Item 
          icon='alert-circle-check-outline'
          message={`<span>${historic.nmUsuario} <b>respondeu</b> pendência </span>`}
          date={historic.dtHistorico}
          details={getLabelDetailsResponseWaitingAnswer(historic)}
        />
      );
    case ACTION.CHANGE_STATUS_TO_CANCEL:
      return (
        <Item 
          icon='close-circle-outline'
          message={`<span>Ticket <b>cancelado</b> por: ${historic.nmUsuario}</span>`}
          date={historic.dtHistorico}
          // details={getLabelDetailsResponseCancelAnswer(historic)}
        />
      );
    case ACTION.CHANGE_STEP:
      return (
        <Item 
          icon='flag-variant-outline'
          message={`<span>${historic.nmUsuario} <b>adicionou</b> uma etapa<br />${historic.mensagem}</span>`}
          date={historic.dtHistorico}
        />
      );
    case ACTION.CHANGE_FINISHING_STEP:
      return (
        <Item 
          icon='flag-variant-outline'
          message={`<span>${historic.nmUsuario} <b>finalizou</b> uma etapa<br />${historic.mensagem}</span>`}
          date={historic.dtHistorico}
        />
      );
    default:
      return (
        <ActionText>{historic.mensagem} - Por: {historic.nmUsuario} às {formatTime(historic.dtHistorico)}</ActionText>
      );
  }

}