import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Column, Row, Icon } from '@components-teammove';

import Pergunta from './Pergunta/Pergunta';
import { getNewId, typesRespostas } from './rules';
import { arrayReorder , Notification } from '@utils';
import styled from 'styled-components';
import { NewQuestionButton, QuestionsContainer, InputText, Label, ContentSizedSwitch } from './styles';

const FlexDivRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledText = styled.span`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.textColor};
`;

const QuestionActionButton = styled(Button)`
  &.ant-btn {
    margin: .25rem;
    padding: 6px 12px;
  }
`;

export default function QuestionarioModal({ data, visible, onCancel, onOk, tipoQuestionario }) {
  const [questionario, setQuestionario] = useState(data);
  const [perguntas, setPerguntas] = useState(questionario.perguntas || []);
  const [perguntasAtivas, setPerguntasAtivas] = useState(perguntas.filter((perg) => perg.ativo)); // i just need the last active question index
  const [idList, setIdList] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [unsavedQuestion, setUnsavedQuestion] = useState({});
  const [ isNewQuestion, setIsNewQuestion ] = useState(false); 

  const [form] = Form.useForm();

  const originalQuestionario = data;

  useEffect(() => {
    if (perguntas.filter((perg) => perg.ativo).length !== perguntasAtivas.length) {
      setPerguntasAtivas(perguntas.filter((perg) => perg.ativo).map((perg, index) => ({ ...perg, ordem: index })));
    }
  }, [perguntas]);

  useEffect(() => {
    if(perguntas.length > 0) {
      setIdList(perguntas.filter((perg) => perg.ativo).map((perg) => ({ id: perg.id || perg.tempId })));
    }
  }, []);

  const handleOk = () => {
    if (editing) {
      setEditing(false);
      return;
    }

    if(perguntas.length > 0) {
      setQuestionario(() => {
        const newQuestionario = {
          ...questionario,
          perguntas: perguntas.map((perg) => {
            const indexInActivesArray = perguntas.filter((perg) => perg.ativo)
              .findIndex((pergAtiva) => pergAtiva.id ? pergAtiva.id === perg.id : pergAtiva.tempId === perg.tempId);
  
            return {
              ...perg, 
              ordem: indexInActivesArray
            };
          }),
          tipoQuestionario: tipoQuestionario
        };
        
        onOk(newQuestionario);
        return newQuestionario;
      });
    }else {
      onOk();
    }
    
  };

  const handleCancel = () => {
    if(isNewQuestion) {
      idList.pop();
      setIdList([...idList]);
      form.setFieldsValue();
      setCurrentQuestion({});
      setIsNewQuestion(false);
      setUnsavedQuestion({});
    }
    if (editing) {
      setEditing(false);
      return;
    }

    onCancel();
  };

  const handleEdit = (question) => {
    setEditing(true);
    setCurrentQuestion({ ...question });
  };

  const handleSave = () => {
    form.validateFields().then(() => {
      if ((unsavedQuestion.tipo === typesRespostas.MULTIPLA_ESCOLHA || unsavedQuestion.tipo === typesRespostas.SOMATORIA) && unsavedQuestion.opcoes.filter((opt) => opt.ativo).length < 2) {
        Notification.error('Insira ao menos duas opções');
        return;
      }
      const hasId = !!unsavedQuestion.id;

      if (isNewQuestion) {
        const id = idList.length;

        const pergunta = { ...unsavedQuestion, tempId: id };
        const newPerguntas = [...perguntas, pergunta];
        setPerguntas(newPerguntas);
        setIsNewQuestion(false);
      }
      else {
        setPerguntas(perguntas.map((perg) => {
          if (hasId) {
            if (perg.id === unsavedQuestion.id) {
              return { ...unsavedQuestion };
            }
            return { ...perg };
          }
          else{
            if (perg.tempId === unsavedQuestion.tempId) {
              return { ...unsavedQuestion };
            }
            return { ...perg };
          }
        }));
      }
      
      setEditing(false);
    });
  };

  const handleDelete = (itemToDelete) => {
    if (itemToDelete.id) {
      setPerguntas(perguntas.map((pergunta) => pergunta.id === itemToDelete.id ? ({ ...pergunta, ativo: false }) : ({ ...pergunta })));
      setIdList(idList.filter((item) => item.id !== itemToDelete.id));
    }
    else{
      setPerguntas(
        perguntas.filter((pergunta) => pergunta.tempId !== itemToDelete.tempId)
          .map((perg, index) => ({ ...perg, ordem: index }))
      );
      setIdList(idList.filter((item) => item.id !== itemToDelete.tempId));
    }
  };

  const handleDuplicate = (itemToCopy) => {
    const id = getNewId(idList);
    setIdList([...idList, { id }]);

    const pergunta = {
      ativo: true,
      titulo: itemToCopy.titulo,
      tipo: itemToCopy.tipo,
      ordem: perguntas.length ,
      obrigatorio: itemToCopy.obrigatorio,
      opcoes: itemToCopy.opcoes,
      tempId: id
    };

    setPerguntas([...perguntas, pergunta]);
  };

  const handleNew = () => {
    const id = getNewId(idList);
    setIdList([...idList, { id }]);

    const pergunta = { ativo: true, titulo: '', tipo: typesRespostas.TEXT, tempId: id, ordem: perguntas.length, obrigatorio: true, opcoes: [] };
    setIsNewQuestion(true);
    setEditing(true);
    setCurrentQuestion({ ...pergunta });
  };

  const handleReorder = (pergunta, newPergunta) => {
    setPerguntas(() => {
      const newPerguntas = 
        arrayReorder(perguntas, pergunta.ordem, newPergunta.ordem)
          .map((perg, index) => ({ ...perg, ordem: index }));
        
      return [...newPerguntas];
    });
  };

  const onBeforeCancel = () => {
    const isFieldTouched = form.isFieldsTouched() || Object.keys(form.getFieldsValue()).some((key) => form.getFieldValue(key));
    let questionarioAskDifferent = false;
    if(originalQuestionario.perguntas) {
      questionarioAskDifferent = originalQuestionario.perguntas.some((perg, index) => {
        return perguntas[index] !== perg;
      });
    }
    const isQuestionarioDifferent = questionario !== originalQuestionario;
    return isFieldTouched || isQuestionarioDifferent || questionarioAskDifferent;
  };

  return( 
    <Modal 
      visible={visible} 
      width={720}
      onCancel={handleCancel}
      onBeforeCancel={onBeforeCancel}
      onOk={editing ? handleSave : handleOk}
      okText={editing ? 'Salvar pergunta' : 'Salvar'} 
      title={editing ? (questionario.title || (`Titulo do ${tipoQuestionario === 'BRIEFING' ? 'Briefing' : 'Report'}`)) : (tipoQuestionario === 'BRIEFING' ? 'Briefing' : 'Report')}
    >
      <Column gap='8px'>
        {!editing && (
          <>
            <Label>Ativo</Label>
            <ContentSizedSwitch checked={questionario.ativo} onChange={(checked) => setQuestionario({ ...questionario, ativo: checked })}/>
            <Label>{`Título do ${tipoQuestionario === 'BRIEFING' ? 'briefing' : 'report'}`}</Label>
            <Input placeholder={`Título do ${tipoQuestionario === 'BRIEFING' ? 'briefing' : 'report'}`} value={questionario.title || ''} onChange={(e) => setQuestionario({ ...questionario, title: e.target.value })} autoFocus/>
            <Row gap='20px'>
              <div>
                <Label>Linha de impressão 1</Label>
                <Input placeholder='Linha de impressão 1' value={questionario.campoTexto1 || ''} onChange={(e) => setQuestionario({ ...questionario, campoTexto1: e.target.value })} autoFocus/>
              </div>
              <div>
                <Label>Linha de impressão 2</Label>
                <Input placeholder='Linha de impressão 2' value={questionario.campoTexto2 || ''} onChange={(e) => setQuestionario({ ...questionario, campoTexto2: e.target.value })} autoFocus/>
              </div>
            </Row>
            <Label>Linha de impressão 3</Label>
            <InputText placeholder='Linha de impressão 3' value={questionario.campoTexto3 || ''} onChange={(e) => setQuestionario({ ...questionario, campoTexto3: e.target.value })} autoFocus/>
          </>
        )}
        <Form.Provider onFormFinish={() => handleOk()}>
          {!editing && (
            <>
              <QuestionsContainer>
                {perguntas.filter((perg) => perg.ativo).map((item, index) => (
                  <>
                    <FlexDivRow key={item.id || item.tempId}>
                      <StyledText>{item.titulo}</StyledText>
                
                      <QuestionActionButton type='secondary' size='small' icon={<Icon.UpOutlined/>} title='Mover para cima' disabled={index === 0} onClick={() => handleReorder(item, { ...item, ordem: item.ordem - 1 })}/>
                      <QuestionActionButton type='secondary' size='small' icon={<Icon.DownOutlined/>} title='Mover para baixo' disabled={index === perguntasAtivas.length - 1} onClick={() => handleReorder(item, { ...item, ordem: item.ordem + 1 })}/>
                      <QuestionActionButton type='secondary' size='small' icon={<Icon.CopyOutlined/>} title='Duplicar' onClick={() => handleDuplicate(item)}/>
                      <QuestionActionButton type='secondary' size='small' icon={<Icon.EditOutlined/>} title='Editar' onClick={() => handleEdit(item)}/>
                      <QuestionActionButton type='secondary' size='small' icon={<Icon.DeleteOutlined/>} title='Excluir' onClick={() => handleDelete(item)}/>
                    </FlexDivRow>
                  </>
                ))}
              </QuestionsContainer>
              <NewQuestionButton type='link' onClick={() => handleNew()}>
                <Icon.PlusOutlined/>
                Nova Pergunta
              </NewQuestionButton>
            </>
          )}

          {editing && (
            <Pergunta 
              pergunta={currentQuestion} 
              setPergunta={setUnsavedQuestion}
              form={form}
            />
          )}
        </Form.Provider>
      </Column>
    </Modal>
  );
}