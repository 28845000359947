import styled from 'styled-components';

export const Card = styled.div`
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    ${({ hasGroupsToRender }) => hasGroupsToRender ? `
      border-bottom-right-radius: 18px;
      border-bottom-left-radius: 18px;
      padding: 20px;
    ` : `
      border-radius: 18px;
      padding: 20px 20px 0 20px;
    `}
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 860px;
    width: 100%;
`;

export const InputStyledNoBox = styled.input`
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ title }) => title ? '32px' : '18px'};
  font-weight: ${({ title }) => title ? 700 : 400};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  width: 100%;
  min-width: 140px;
  ::placeholder {
    color: ${({ theme }) => theme.textColor};
    opacity: 50%;
    font-weight: 400;
  }
  border: 2px solid ${({ theme }) => theme.darkHighlightColor};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  padding: 10px;  
`;