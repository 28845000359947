import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View } from '@components';
import { Icon , Dropdown, Menu, Button, Column, Row, Tooltip } from '@components-teammove';

import { formatDateTime, InfoUsuario, TelasNomenclaturas } from '@utils';
import PrazoStatusItem from '../PrazoStatusItem';
import StatusItem from '../StatusItem';
import { AssuntoDetalheText, AssuntoText, ContentTitle, DetailProtocolText, DetalheText, FillView, Container, IconWarning, MenuButton, RowSpaceBetween, UnseenMessagesIndicator, LoadMoreButton, StyledTable, NumberStepsTag, VerticalLine, ContainerButton, HoverableContainer, TextTable, RowClassificacao, RowAssunto, RowTicket, AlertIcon } from './styles';
import { STATUS } from '../rules';
import { changeStatus , changeVisibleBriefingReply, changeVisibleBriefingReplyEtapas } from '@ducks/chamadosHistorico';
import { getChamados, setChamadoId, resetChamados } from '@ducks/chamadosTickets';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ClassificacoesTag from '../ClassificacoesTag';
import ModalViewSteps from '../Chat/MenuItem/Steps/ModalViewSteps';
import ModalQuestionarioReply from '../Questionario/Reply';

export default function List({ chamado, setChamado, orderBy, setOrderBy, filtros, busca, setLoading, setVisibleChangeResponsavel, setVisibleChangeCategoria, setVisibleBriefingReply, typeTicketForFiltrosParams, seeSteps, ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef(null);
  const chamados = useSelector(({ chamadosTickets }) => chamadosTickets.get('chamados').toArray());
  const loading = useSelector(({ chamadosTickets }) => chamadosTickets.get('loading'));
  const success = useSelector(({ chamadosTickets }) => chamadosTickets.get('success'));

  const visibleBriefingReplyEtapas = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('visibleBriefingReplyEtapas'));
  const loadingStatus = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingStatus'));
  const successStatus = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successStatus'));
  const total = useSelector(({ chamadosTickets }) => chamadosTickets.get('total'));
  const usuario = InfoUsuario.get();
  const administrador = InfoUsuario.perm('chamadosAdministrador', usuario);
  const permiteAtendimento = InfoUsuario.perm('chamadosAtendimento', InfoUsuario.get());
  const permiteAcoesRapidas = InfoUsuario.perm('chamadosAcoesRapidas', InfoUsuario.get());

  const loadingSteps = useSelector(({ chamadosEtapas }) => chamadosEtapas.get('loadingSteps'));

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const [loadingMore, setLoadingMore] = useState(false);
  const [visibleEtapas, setVisibleEtapas] = useState(false);
  const [chamadoSelecionado, setChamadoSelecionado] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    const storedOrderBy = sessionStorage.getItem('chamadosTickets/Order');
    const parsedStoredOrderBy = JSON.parse(storedOrderBy);
    if (storedOrderBy) {
      setOrderBy(parsedStoredOrderBy);
    }

    return () => {
      dispatch(resetChamados());
    };
  }, []);

  useEffect(() => {
    if(success && loadingMore) {
      setLoadingMore(false);
    }
  }, [success]);

  useEffect(() => {
    if(successStatus) {
      dispatch(getChamados(InfoUsuario.get('cdUsuario'), filtros, chamados.length, busca, orderBy));
    }
  }, [successStatus]);

  const chamadosList = useMemo(() => {
    if(chamados.length > 0) {
      return seeSteps ? chamados : chamados.filter((chamado) => !chamado.idChamadoPai);
    }
  }, [chamados, seeSteps]);

  const handleOpenChat = (id) => {
    if(!busca) dispatch(setChamadoId(id));
    sessionStorage.setItem('chamadosTickets/Limit', JSON.stringify(chamados.length));
    history.push(`/chamados/tickets/${id}`);
  };
  
  const handleOpenChangeResponsavel = (chamado) => { 
    setChamado(chamado); 
    setVisibleChangeResponsavel(true); 
  };  

  const handleOpenChangeCategoria = (chamado) => {
    setChamado(chamado); 
    setVisibleChangeCategoria(true);
  };

  const handleResolverAtendimento = (chamado) => {
    dispatch(changeStatus(chamado.id, STATUS.AGUARDANDO_AVALIACAO));    
  };

  const handleReabrirAtendimento = (chamado) => {
    dispatch(changeStatus(chamado.id, STATUS.REABERTO));    
  };

  const handleCancelarAtendimento = (chamado) => {
    dispatch(changeStatus(chamado.id, STATUS.CANCELADO));    
  };

  const handleShowOptions = (chamado) => {
    return permiteAtendimento && chamado.status !== STATUS.CONCLUIDO && (handleShowOptionDataChange(chamado) || handleShowOptionMarkResolved(chamado) || handleShowOptionReopen(chamado));
  };

  const handleShowOptionDataChange = (chamado) => {
    return permiteAcoesRapidas && permiteAtendimento && (chamado.status === STATUS.ABERTO || chamado.status === STATUS.EM_ATENDIMENTO || chamado.status === STATUS.REABERTO);
  };

  const handleShowOptionMarkResolved = (chamado) => {
    return permiteAtendimento && (chamado.status === STATUS.EM_ATENDIMENTO || chamado.status === STATUS.REABERTO);
  };

  const handleShowOptionReopen = (chamado) => {
    return permiteAtendimento && (chamado.status === STATUS.FECHADO || chamado.status === STATUS.AGUARDANDO_AVALIACAO || chamado.status === STATUS.CANCELADO);
  };
  
  const handleShowOptionCancel = (chamado) => {
    return permiteAtendimento && (chamado.status !== STATUS.FECHADO && chamado.status !== STATUS.CANCELADO) && administrador;
  };

  const handleClickOnRow = (chamado) => {
    if (chamado.status === STATUS.AGUARDANDO_BRIEFING && chamado.idUsuario === InfoUsuario.get('cdUsuario')) {
      handleRedirectToBriefingReply(chamado);
    } else {
      handleOpenChat(chamado.id);
    }
  };

  const handleRedirectToBriefingReply = (chamado) => {
    dispatch(changeVisibleBriefingReply(true));
    history.push(`?ticket=${chamado.id}`); 
  };

  useEffect(() => {
    if (orderBy) {
      sessionStorage.setItem('chamadosTickets/Order', JSON.stringify(orderBy));
    } else {
      sessionStorage.removeItem('chamadosTickets/Order');
    }
    const storedLimit = sessionStorage.getItem('chamadosTickets/Limit');
    const parsedStoredLimit = JSON.parse(storedLimit);
    dispatch(getChamados(InfoUsuario.get('cdUsuario'), { ...filtros, ...typeTicketForFiltrosParams }, parsedStoredLimit ? parsedStoredLimit : 50, busca, orderBy));
  }, [orderBy]);

  useEffect(() => {
    if(!chamadoSelecionado) return;
    setVisibleEtapas(true);
  }, [chamadoSelecionado]);

  const handleOpenModalEtapa = (e, chamado) => {
    e.stopPropagation();
    setChamadoSelecionado(chamado);
  };

  const columns = [
    {
      title: 'Assunto',
      dataIndex: 'assunto',
      defaultSortOrder: orderBy && orderBy.field === 'assunto' ? orderBy.order + 'end' : false,
      sorter: true,
      width: 500,
      render: (text, chamado) => {
        const allChildrenClosed = chamado.chamadosFilhos && chamado.chamadosFilhos.every((child) => child.status === STATUS.FECHADO);
        const isPendingTicket = chamado.status !== STATUS.FECHADO && allChildrenClosed;
        return (
          <RowAssunto align='center' justify='space-between'>
          
            <RowTicket gap='14px' align='center'>
              {chamado.idChamadoPai && <VerticalLine /> }
            
              <Column>
                <DetailProtocolText cancelado={chamado.status === 'CANCELADO'}>{chamado.protocolo}</DetailProtocolText>
                <ContentTitle>
                  <AssuntoText cancelado={chamado.status === 'CANCELADO'}>{chamado.assunto}</AssuntoText>
                  {chamado.totalMensagensPendentes > 0 && <UnseenMessagesIndicator>{chamado.totalMensagensPendentes}</UnseenMessagesIndicator>}
                </ContentTitle>
                <AssuntoDetalheText cancelado={chamado.status === 'CANCELADO'}>{chamado.nmCategoriaCompleto}</AssuntoDetalheText>
              </Column>
            
            </RowTicket>
          
            {chamado.quantidadeFilhos > 0 && (
              <Tooltip title={isPendingTicket ? 'Todas etapas estao concluídas' : ''}>
                <NumberStepsTag isPendingTicket={isPendingTicket} onClick={(e) => handleOpenModalEtapa(e, chamado)}>
                  <Row gap='4px'>
                    {`${chamado.quantidadeFilhos} ${chamado.quantidadeFilhos > 1 ? 'Etapas' : 'Etapa'}`}          
                  </Row>
                </NumberStepsTag>
              </Tooltip>
            )}
          </RowAssunto>
        );}
    },
    {
      title: `${companyNomenclature.nomenclatura}`,
      dataIndex: 'nmUnidade',
      defaultSortOrder: orderBy && orderBy.field === 'nmUnidade' ? orderBy.order + 'end' : false,
      sorter: true,
      width: 20,
      render: (text, chamado) => (
        <View>
          <TextTable cancelado={chamado.status === 'CANCELADO'}>{text}</TextTable>
        </View>
      )
    },
    {
      title: 'Criado em',
      dataIndex: 'dtCriacao',
      defaultSortOrder: orderBy && orderBy.field === 'dtCriacao' ? orderBy.order + 'end' : false,
      sorter: 'date',
      width: 20,
      render: (text, chamado) => (
        <View>
          <TextTable cancelado={chamado.status === 'CANCELADO'}>{formatDateTime(text)}</TextTable>       
          <DetalheText cancelado={chamado.status === 'CANCELADO'}>Por: {chamado.nmUsuario}</DetalheText>  
        </View>
      )
    },
    {
      title: 'Prazo',
      dataIndex: 'prazo',
      defaultSortOrder: orderBy && orderBy.field === 'prazo' ? orderBy.order + 'end' : false,
      width: 40,
      sorter: 'datetime',
      render: (text, chamado) => (
        <FillView>
          {!chamado.aguardandoResposta && <PrazoStatusItem chamado={chamado} cancelado={chamado.status === 'CANCELADO'} />}
          {(chamado.nmUsuarioAtendimento) && <DetalheText cancelado={chamado.status === 'CANCELADO'}>Resp: {chamado.nmUsuarioAtendimento ? chamado.nmUsuarioAtendimento : <IconWarning/> }</DetalheText>}
        </FillView>
      )
    },    
    {
      title: 'Classificações',
      dataIndex: 'classificacoes',
      defaultSortOrder: orderBy && orderBy.field === 'classificacoes' ? orderBy.order + 'end' : false,
      width: 180,
      sorter: true,
      render: (text, chamado) => (
        <RowClassificacao gap='8px'>
          <ClassificacoesTag chamado={chamado}/>
        </RowClassificacao>
      )
    },  
    {
      title: 'Status',
      dataIndex: 'statusDs',
      defaultSortOrder: orderBy && orderBy.field === 'statusDs' ? orderBy.order + 'end' : false,
      width: 222,
      sorter: () => '',
      render: (text, chamado) => {
        return (
          <RowSpaceBetween>
            {(
              <StatusItem texto={text} chamado={chamado} />
            )}
            {!handleShowOptions(chamado) ? null : (
              <View
                onClick={(e) => (e.stopPropagation())}
              >
                <Dropdown
                  key="more"
                  overlay={(
                    <Menu>
                      {handleShowOptionDataChange(chamado) && 
                          <Menu.Item
                            onClick={(e) => { e.domEvent.stopPropagation(); handleOpenChangeResponsavel(chamado); }} 
                          >
                            <MenuButton type="icon" icon={<Icon.MDI type="account-edit" />}>
                              Alterar responsável
                            </MenuButton>                    
                          </Menu.Item>}
                        
                      {handleShowOptionDataChange(chamado) && 
                          <Menu.Item
                            onClick={(e) => { e.domEvent.stopPropagation(); handleOpenChangeCategoria(chamado); }} 
                          >
                            <MenuButton type="icon" icon={<Icon.MDI type="circle-edit-outline" />}>
                              Alterar categoria
                            </MenuButton>
                          </Menu.Item>}
        
                      {handleShowOptionMarkResolved(chamado) && !chamado.aguardandoResposta &&
                          <Menu.Item
                            onClick={(e) => { e.domEvent.stopPropagation(); handleResolverAtendimento(chamado); }} 
                          >
                            <MenuButton type="icon" icon={<Icon.CheckOutlined />}>
                              Marcar como Resolvido
                            </MenuButton>               
                          </Menu.Item>}
        
                      {handleShowOptionReopen(chamado) &&
                          <Menu.Item
                            onClick={(e) => { e.domEvent.stopPropagation(); handleReabrirAtendimento(chamado); }} 
                          >
                            <MenuButton type="icon" icon={<Icon.Font type="play" />}>
                              Reabrir atendimento
                            </MenuButton>                    
                          </Menu.Item>}
        
                      {handleShowOptionCancel(chamado) &&
                          <ContainerButton>
                            <HoverableContainer>
                              <MenuButton onClick={(e) => {e.stopPropagation(); handleCancelarAtendimento(chamado); }} type="icon" icon={<Icon.Feather type='trash-2' size='18px' />}>
                                Cancelar ticket
                              </MenuButton>      
                            </HoverableContainer>  
                          </ContainerButton>           
                      }
                    </Menu> 
                  )}
                >
                  <Button type="icon">
                    <Icon.MDI type="dots-vertical" />
                  </Button>
                </Dropdown>
              </View>
            )}
          </RowSpaceBetween>
        );
      }
    }
  ];
  
  return(
    <Container>
      <StyledTable
        rowKey="id"
        columns={columns}
        dataSource={chamadosList}
        hidePagination
        loading={(loading && !loadingMore) || loadingStatus}
        queries={{ 0: '(max-width: 599px)', 1: '(min-width: 600px) and (max-width: 740px)', 2: '(min-width: 741px) and (max-width: 1070px)', 3: '(min-width: 1071px)' }}
        onChange={(pagination, filters, { field, order }) => {
          dispatch(setChamadoId(null));
          setOrderBy(order ? { field, order: order.replace('end', '') } : false);
        }}
        onRow={(chamado, rowIndex) => {
          return {
            id: chamado.id,
            onClick: () => { handleClickOnRow(chamado); }
          };
        }}
        ref={tableRef}
      />
      {chamados.length < total && (
        <LoadMoreButton
          type='secondary' icon={<Icon.ReloadOutlined/>} loading={loading || loadingStatus} onClick={() => { dispatch(getChamados(InfoUsuario.get('cdUsuario'), { ...filtros, ...typeTicketForFiltrosParams }, chamados.length + 50, busca, orderBy)); setLoadingMore(true); }}
        >Carregar mais</LoadMoreButton>
      )}
      {visibleEtapas && (
        <ModalViewSteps
          mainTicket={chamadoSelecionado}
          steps={chamadoSelecionado.chamadosFilhos}
          loadingSteps={loadingSteps}
          visible={visibleEtapas}
          setVisibleViewStep={setVisibleEtapas}
          onCancel={()=> {
            setVisibleEtapas(false);
            setChamadoSelecionado(null);
          }}
          setCurrentStep={setCurrentStep}
        />
      )}

      {visibleBriefingReplyEtapas && (
        <ModalQuestionarioReply
          visible={visibleBriefingReplyEtapas}
          onCancel={() => {
            dispatch(changeVisibleBriefingReplyEtapas(false));
            history.push('#');
          }}
          tipo={'BRIEFING'} 
          chamado={currentStep}
        />
      )}
    </Container>
  );
}