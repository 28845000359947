import React from 'react';
import { Row, StarIcon, StyledChart, StyledProgress, StyledLine, FilledLine } from './styles';
import { useSelector } from 'react-redux';
import { getColorByPercentage } from './rules';

const Progress = ({ ...props }) => {
  const theme = useSelector(({ app }) => app.get('theme'));

  return(
    <StyledProgress 
      trailColor={props.trailColor || theme['@global-dark-lowlight-color']}
      strokeColor={props.strokeColor || getColorByPercentage(props.percent, theme)}
      {...props}
    />
  );
};

Progress.Star = ({ totalStars = 5, percent, ...props }) => (
  <Row gap='4px'>
    {new Array(totalStars).fill().map((elem, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <StarIcon key={new Date().getTime() + index} type={((index + 1) <= Math.round((percent || 0) / (100 / totalStars))) ? 'star-fill' : 'star'} {...props}/>
    ))}
  </Row>
);

const HalfCircle = ({ percent, ...props }) => {
  const theme = useSelector(({ app }) => app.get('theme'));

  const options = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: theme['@global-dark-lowlight-color'],
          strokeWidth: '97%',
          margin: 5, 
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px'
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    labels: ['Average Results'],
    colors: [props.strokeColor]
  };
  const series = [percent || 0];

  return (
    <StyledChart
      options={options}
      series={series}
      type='radialBar'
      width={props.width || '100%'}
      height={props.height || 'auto'}
      {...props}
    />
  );
};

const Line = ({ percent, status, ...props }) => {
  return (
    <StyledLine>
      <FilledLine status={status} percent={percent} {...props}/>
    </StyledLine>
  );
};

Progress.HalfCircle = ({ ...props }) => <HalfCircle {...props}/>;
Progress.Line = ({ ...props }) => <Line {...props}/>;

export default Progress;