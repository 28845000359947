import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { View } from '@components';
import { Modal, Form, Select, Input, Upload, Switch, Row } from '@components-teammove';
import { getValueFromFile, InfoUsuario, download, formatDate, moment, TelasNomenclaturas, manipulationHeight } from '@utils';
import { FormItemSelect, MarginBottom, TextVencimento, UploadIcon, UploadText } from './styles';

import { getUnidades } from '@sdk/Unidades';
import { authorization } from '@sdk/api';
import { getUsuarios, getUsuariosPorUnidade } from '@sdk/Chamados';
import { changeVisibleBriefingReply, changeVisibleBriefingReplyEtapas } from '@ducks/chamadosHistorico';
import { saveChamado } from '@ducks/chamadosCadastro';
import SelectCategoryModal from './SelectCategoryModal';

const { REACT_APP_API } = window.env;

export default function ModalNovo({ isStep, mainTicket, visible, onCancel, onOk, type, ...props }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const chamado = useSelector(({ chamadosCadastro }) => chamadosCadastro.get('chamado'));
  const loading = useSelector(({ chamadosCadastro }) => chamadosCadastro.get('loading'));
  const success = useSelector(({ chamadosCadastro }) => chamadosCadastro.get('success'));
 
  const permiteInformarUsuarioCriacao = InfoUsuario.perm('chamadosInformarUsuarioCriacao', InfoUsuario.get());

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  
  const [categoria, setCategoria] = useState({});
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosCriacao, setUsuariosCriacao] = useState([]);
  const [unidade, setUnidade] = useState(-1);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [ mandatoryForMainTicket, setMandatoryForMainTicket ] = useState(false);

  const [ categoryHasBriefing, setCategoryHasBriefing ] = useState(false);
  const [ creatorUserId, setCreatorUserId ] = useState(null);

  const [ isVisibleSelectCategoryModal, setIsVisibleSelectCategoryModal ] = useState(false); 
  const [ isVisibleRegisterModal, setIsVisibleRegisterModal ] = useState(false);
  const [ isChangeCategoryAgain, setIsChangeCategoryAgain ] = useState(false);

  const [openSelect, setOpenSelect] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    setIsVisibleSelectCategoryModal(true);
    setIsVisibleRegisterModal(false);
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setCategoria({});
    }
  }, [visible]);

  useEffect(() => {
    if (success) {
      if (categoryHasBriefing && creatorUserId === InfoUsuario.get('cdUsuario')) {
        if(!isStep) {
          history.push(`?ticket=${chamado.id}`);
        } 
        if(type === 'ETAPA') {
          dispatch(changeVisibleBriefingReplyEtapas(true));
        } else {
          dispatch(changeVisibleBriefingReply(true));
        }
      } 
      onOk();
    }
  }, [history, success]);
 
  const handleChangeUnidade = (unidade) => {
    const idUnidade = unidade || -1;
    setUnidade(idUnidade);
    handleChangeUsuariosCriacao(idUnidade);
    handleChangeUsuarios(categoria, idUnidade);
    handleSetValueForm('idUsuarioExecutar', null);
  };

  const handleChangeUsuarios = (categoria, unidade) => {

    if (!categoria.id || unidade < 0) {
      setUsuarios([]);
      return;
    }
    getUsuarios(true, categoria.id, unidade)
      .then((res) => {
        setUsuarios(res);

        if (res.length === 1) {
          handleSetValueForm('idUsuarioExecutar', res[0].value);
        }
      });

  };

  const handleChangeUsuariosCriacao = (unidade) => {
    
    if (!permiteInformarUsuarioCriacao || unidade < 0) {
      setUsuariosCriacao([]);
      return;
    }

    getUsuariosPorUnidade(true, unidade)
      .then((res) => {
        setUsuariosCriacao(res);
        handleSetValueForm('idUsuarioCriacao', InfoUsuario.get('cdUsuario'));
      });
  };

  const handleSetValueForm = (field, value) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [field]: value,
    });
  };

  const renderUserSelectOption = (itens) => (
    itens.map((item) => (
      <Select.Option key={item.key} {...item}>
        <Row>
          {item.title || item.value}
          {item.tag && <View dangerouslySetInnerHTML={{ __html: item.tag }}/>}
        </Row>
      </Select.Option>
    ))
  );

  const onBeforeCancel = () => {
    return form.isFieldsTouched() || Object.keys(form.getFieldsValue()).some((key) => form.getFieldValue(key));
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      if(mainTicket) {
        values.idChamadoPai = mainTicket.id;
        values.bloqueiaChamadoPai = mandatoryForMainTicket;
        values.unidade = mainTicket.companyId;
      }
      setCreatorUserId(values.idUsuarioCriacao || InfoUsuario.get('cdUsuario'));

      dispatch(saveChamado(values));
    }).catch(Form.scrollToFirstError);
  };

  const toggleMandatoryForMainTicket = () => {
    setMandatoryForMainTicket(!mandatoryForMainTicket);
  };
  
  return(
    <>
      {isVisibleSelectCategoryModal && (
        <SelectCategoryModal
          form={form}
          visible={visible}
          onCancel={onCancel}
          loading={loading}
          unidade={unidade}
          setCategoria={setCategoria}
          setIsVisibleRegisterModal={setIsVisibleRegisterModal}
          isVisibleSelectCategoryModal={isVisibleSelectCategoryModal}
          setIsVisibleSelectCategoryModal={setIsVisibleSelectCategoryModal}
          isChangeCategoryAgain={isChangeCategoryAgain}
          loadingUpload={loadingUpload}
          handleSetValueForm={handleSetValueForm}
          setCategoryHasBriefing={setCategoryHasBriefing}
          handleChangeUsuarios={handleChangeUsuarios}
        />
      )}
      {isVisibleRegisterModal && (
        <Modal
          visible={visible}
          title='Novo Chamado / Ticket'
          onCancel={onCancel}
          onOk={handleOk}
          okButtonProps={{ loading, disabled: loadingUpload }}
          onBeforeCancel={onBeforeCancel}
        >
          <Form form={form} layout='vertical'>
            <Form.Item label="Departamento/Categoria" name="categoria" rules={[{ required: true, message: 'Informe um Departamento/Categoria!' }]} hidden/>
            
            <Form.Item label="Departamento/Categoria" name="categoriaPath" rules={[{ required: true, message: 'Informe um Departamento/Categoria!' }]} marginless>
              <Select 
                form={form}
                name="categoriaPath"
                placeholder="Selecione um departamento/categoria"
                onClick={() => {
                  setIsChangeCategoryAgain(true);
                  setIsVisibleSelectCategoryModal(true);
                  setIsVisibleRegisterModal(false);
                }}
              />
            </Form.Item>
            {categoria.prazo && <TextVencimento>Prazo: {moment(categoria.prazo).isAfter(new Date(), 'day') ? formatDate(categoria.prazo) : 'Hoje'}</TextVencimento>}
            <MarginBottom/>

            {mainTicket && (
              <>
                <Row gap='16px'>
                  <Switch checked={mandatoryForMainTicket} onClick={()=>  toggleMandatoryForMainTicket()}/>
                  <div>Etapa obrigatória para o fechamento do ticket pai</div>
                </Row>
                <MarginBottom/>
              </>
            )}
            {!mainTicket && (
              <FormItemSelect selectOpen={openSelect} dropdownHeight={dropdownHeight} label={companyNomenclature.nomenclatura} name="unidade" rules={[{ required: true, message: `Selecione um${(companyNomenclature.artigo) === 'a' ? (companyNomenclature.artigo) : ''} ${(companyNomenclature.nomenclatura).toLowerCase()}!` }]}>
                <Select
                  form={form}
                  name="unidade"
                  action={getUnidades}
                  optionLabelProp='title'
                  placeholder={'Selecione ' + (companyNomenclature.artigo)  + ' '  + (companyNomenclature.nomenclatura)}
                  onChange={handleChangeUnidade}
                  onDropdownVisibleChange={(open) => manipulationHeight(open, setOpenSelect, setDropdownHeight)}
                  listHeight={200}
                />
              </FormItemSelect>
            )}
            {permiteInformarUsuarioCriacao && usuariosCriacao.length > 0 && <Form.Item label="Usuário Criação" name="idUsuarioCriacao" rules={[{ required: true, message: 'Informe um usuário!' }]}>
              <Select
                form={form}
                name="idUsuarioCriacao"
                placeholder="Selecione um usuário"
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {renderUserSelectOption(usuariosCriacao)}
              </Select>
            </Form.Item>}

            {categoria?.permiteUsuarioResponsavel && usuarios.length > 0 && <Form.Item label="Responsável" name="idUsuarioExecutar" rules={[{ required: true, message: 'Informe um usuário!' }]}>
              <Select
                form={form}
                name="idUsuarioExecutar"
                placeholder="Selecione um usuário"
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={usuarios.length === 1}
              >
                {renderUserSelectOption(usuarios)}
              </Select>
            </Form.Item>}

            <Form.Item label="Assunto" name="assunto" rules={[{ required: true, message: 'Informe o assunto!' }, { max: 80, message: 'Insira um assunto menor', type: 'string' }]}>
              <Input placeholder='Digite aqui o assunto'/>
            </Form.Item>

            {!categoryHasBriefing && (
              <>
                <Form.Item label="Mensagem" name="mensagem" rules={[{ required: true, message: 'Informe a mensagem!' }]}>
                  <Input.TextArea placeholder='Digite aqui sua mensagem'/>
                </Form.Item>

                <Form.Item label="Anexos" name="anexos" valuePropName="fileList" getValueFromEvent={getValueFromFile}>
                  <Upload.Dragger
                    listType="picture"
                    folder={`Chamados/${InfoUsuario.get('cdCliente')}`}
                    URI={REACT_APP_API}
                    headers={authorization()}
                    onDownload={(file) =>  download(file.response)}
                    onChange={(response) => {
                      if (response.fileList.some(({ status }) => status !== 'done')) {
                        setLoadingUpload(true);
                      } else {
                        setLoadingUpload(false);
                      }
                    }}
                    multiple
                  >
                    <UploadIcon type='upload'/>
                    <UploadText>Clique ou arraste um arquivo para anexar ao ticket</UploadText>
                  </Upload.Dragger>
                </Form.Item>
              </>
            )}
          </Form>
        </Modal>  
      )}
    </>
  );
}