import { Map } from 'immutable';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Notification } from '@utils';
import { 
  getCompanyDelinquencyConfiguration as getCompanyDelinquencyConfigurationSdk,
  putCompanyDelinquencyConfiguration as putCompanyDelinquencyConfigurationSdk,
} from '../../sdk/CompanyDelinquencyConfiguration';

//Action Types
export const Types = {
  GET_CONFIGURATION: 'CONFIGURACOES_UNIDADES_INADIMPLENCIA/GET_CONFIGURATION',
  GET_CONFIGURATION_SUCCESS: 'CONFIGURACOES_UNIDADES_INADIMPLENCIA/GET_CONFIGURATION_SUCCESS',
  GET_CONFIGURATION_ERROR: 'CONFIGURACOES_UNIDADES_INADIMPLENCIA/GET_CONFIGURATION_ERROR',

  PUT_CONFIGURATION: 'CONFIGURACOES_UNIDADES_INADIMPLENCIA/PUT_CONFIGURATION',
  PUT_CONFIGURATION_SUCCESS: 'CONFIGURACOES_UNIDADES_INADIMPLENCIA/PUT_CONFIGURATION_SUCCESS',
  PUT_CONFIGURATION_ERROR: 'CONFIGURACOES_UNIDADES_INADIMPLENCIA/PUT_CONFIGURATION_ERROR',

  RESET: 'CONFIGURACOES_UNIDADES_INADIMPLENCIA/RESET',
  RESET_SUCCESS: 'CONFIGURACOES_UNIDADES_INADIMPLENCIA/RESET_SUCCESS',
};

//Action Creators
export const getDelinquencyConfiguration = () => ({ type: Types.GET_CONFIGURATION });
export const getDelinquencyConfigurationSuccess = (response) => ({ type: Types.GET_CONFIGURATION_SUCCESS, response });
export const getDelinquencyConfigurationError = (error) => ({ type: Types.GET_CONFIGURATION_ERROR, error });

export const putDelinquencyConfiguration = (data) => ({ type: Types.PUT_CONFIGURATION, data });
export const putDelinquencyConfigurationSuccess = (response) => ({ type: Types.PUT_CONFIGURATION_SUCCESS, response });
export const putDelinquencyConfigurationError = (error) => ({ type: Types.PUT_CONFIGURATION_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });

function* fetchGetDelinquencyConfiguration() {
  try {
    const response = yield call(getCompanyDelinquencyConfigurationSdk);
    yield put(getDelinquencyConfigurationSuccess(response));
  } catch (err) {
    Notification.error(err);
    yield put(getDelinquencyConfigurationError(err));
  }
}

function* fetchPutDelinquencyConfiguration(action) {
  try {
    const { data } = action;
    const response = yield call(putCompanyDelinquencyConfigurationSdk, data);
    yield put(putDelinquencyConfigurationSuccess(response));
    Notification.success('Configuração salva com sucesso!');
  } catch (err) {
    Notification.error(err);
    yield put(putDelinquencyConfigurationError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CONFIGURATION, fetchGetDelinquencyConfiguration),
  takeLatest(Types.PUT_CONFIGURATION, fetchPutDelinquencyConfiguration),
];

// Reducer
const initialState = Map({
  delinquencyConfiguration: Map(),
  loadingDelinquencyConfiguration: false,
  successDelinquencyConfiguration: false,
  errorDelinquencyConfiguration: false,

  loadingDelinquencyConfigurationSave: false,
  successDelinquencyConfigurationSave: false,
  errorDelinquencyConfigurationSave: false,
});

const handleDelinquencyConfiguration = (state, action) => {
  return state.set('loadingDelinquencyConfiguration', true).set('successDelinquencyConfiguration', false).set('errorDelinquencyConfiguration', false);
};

const handleDelinquencyConfigurationSuccess = (state, action) => {
  const { response } = action;
  return state.set('delinquencyConfiguration', response).set('loadingDelinquencyConfiguration', false).set('successDelinquencyConfiguration', true).set('errorDelinquencyConfiguration', false);
};

const handleDelinquencyConfigurationError = (state, action) => {
  return state.set('loadingDelinquencyConfiguration', false).set('successDelinquencyConfiguration', false).set('errorDelinquencyConfiguration', action.error);
};

const handleDelinquencyConfigurationSave = (state, action) => {
  return state.set('loadingDelinquencyConfigurationSave', true).set('successDelinquencyConfigurationSave', false).set('errorDelinquencyConfigurationSave', false);
};

const handleDelinquencyConfigurationSaveSuccess = (state, action) => {
  const { response } = action;
  return state.set('delinquencyConfiguration', response).set('loadingDelinquencyConfigurationSave', false).set('successDelinquencyConfigurationSave', true).set('errorDelinquencyConfigurationSave', false);
};

const handleDelinquencyConfigurationSaveError = (state, action) => {
  return state.set('loadingDelinquencyConfigurationSave', false).set('successDelinquencyConfigurationSave', false).set('errorDelinquencyConfigurationSave', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CONFIGURATION: return handleDelinquencyConfiguration(state);
    case Types.GET_CONFIGURATION_SUCCESS: return handleDelinquencyConfigurationSuccess(state, action);
    case Types.GET_CONFIGURATION_ERROR: return handleDelinquencyConfigurationError(state, action);

    case Types.PUT_CONFIGURATION: return handleDelinquencyConfigurationSave(state);
    case Types.PUT_CONFIGURATION_SUCCESS: return handleDelinquencyConfigurationSaveSuccess(state, action);
    case Types.PUT_CONFIGURATION_ERROR: return handleDelinquencyConfigurationSaveError(state, action);

    case Types.RESET_ALL_VALUES: return initialState;
    case Types.RESET_SUCCESS: return state.set('successDelinquencyConfigurationSave', false).set('successDelinquencyConfiguration', false);
    
    default: return state;
  }
}