import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app/';

import { 
  Icon, 
  Body, 
  PageHeader, 
  Breadcrumb, 
  ActiveFilters, 
  Button, 
  Column, 
  Filter, 
  DateControllerHeader, 
  SearchBar 
} from '@components-teammove';

import { TelasNomenclaturas, InfoUsuario, Filtros, mountDataTreeSelect } from '@utils';

import { getAllClassificationsWithOptions } from '@sdk/Classificacoes';
import { getCategorias } from '@sdk/Chamados';
import { getUsuarios } from '@sdk/Usuarios';
import { 
  getSatisfactionIndexDash, 
  getSatisfactionIndexSummary, 
  getSatisfactionIndexTickets, 
  FILTERS_KEY , 
  PARAMS_KEY 
} from '@ducks/chamadosDashboard';

import AssessmentSummary from './AssessmentSummary';
import AssessmentCards from './AssessmentsCards';
import AssessmentTable from './AssessmentTable';

import { createInitialModalFilters } from '../../rules';

export default function SatisfactionIndexDetails() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const dispatch = useDispatch();
  const usuario = InfoUsuario.get();
  
  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const [ visibleFilterModal, setVisibleFilterModal ] = useState(false);

  const [ currentFilters, setCurrentFilters ] = useState(Filtros.get(FILTERS_KEY));
  const [ currentParams, setCurrentParams ] = useState(Filtros.get(PARAMS_KEY));

  const [ filtersModal, setFiltersModal] = useState(createInitialModalFilters(companyNomenclature, ticketNomenclatures));
  const [ categoriasTree, setCategoriasTree] = useState();
  const [ usuarios, setUsuarios] = useState();
  const [ classificationsWithOptions, setClassificationsWithOptions] = useState();

  const [activeSearch, setActiveSearch] = useState();
  const inputSearch = useRef(null);

  const [ limit, setLimit ] = useState(10);

  const [ isShowingInactiveCategories, setIsShowingInactiveCategories ] = useState(false);
  const [ categoriesValuesWithActiveAndInactive, setCategoriesValuesWithActiveAndInactive ] = useState();
  const [ categoriesValuesOnlyActive, setCategoriesValuesOnlyActive ] = useState();

  const getOptions = () => {
    const inputOptions = classificationsWithOptions?.map((classification) => ({
      type: 'SELECT',
      name: `idsClassificationsItens_${classification.id}`,
      label: classification.titulo + ' - Opções',
      placeholder: `Selecione ${classification.titulo}`,
      options:classification.opcoes.map((item)=> ({ value: item.id, label: item.titulo })),
      dependsOn:'idsClassifications',
      idDepends: classification.id,
    }));
  
    return inputOptions;
  };

  useEffect(()=> {
    (async () => {
      getUsuarios()
        .then((resp) => {
          setUsuarios(resp.filter((item) => usuario.hierarquia?.includes(item.key)).map(({ key, title }) => ({ value: key, label: title })));
        });
      getCategorias(false, false)
        .then((resp) => {        
          const response = resp.filter((item)=> item.ativo);
          const mountDataActive = mountDataTreeSelect(response, 'id', 'idCategoria', 'titulo');
          const mountData = mountDataTreeSelect(resp, 'id', 'idCategoria', 'titulo');
          setCategoriesValuesOnlyActive(mountDataActive);
          setCategoriesValuesWithActiveAndInactive(mountData);

          setCategoriasTree(!isShowingInactiveCategories ? mountDataActive : mountData);
        });
      getAllClassificationsWithOptions()
        .then((resp)=>{
          setClassificationsWithOptions(resp);
        });  
    })();
  },[]);

  useEffect(() => {
    if (usuarios && categoriasTree && classificationsWithOptions) {
      setFiltersModal([...filtersModal.map((filter) => {
        if (filter.name === 'idUserResponsible') {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'idUserCreation') {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'idCategory') {
          return { ...filter, options: categoriasTree };
        }
        if (filter.name === 'idsClassifications') {
          return { ...filter, 
            options: classificationsWithOptions?.map((classification) => ({
              value: classification.id,
              label: classification.titulo,
            })) 
          };
        }
        return { ...filter };
      }), ...getOptions()]);
    }
  }, [usuarios, categoriasTree, classificationsWithOptions]);

  const handleSearch = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
    
  };

  const handleSearchWord = (value) => {
    setActiveSearch(value);
    dispatch(getSatisfactionIndexTickets({ ...currentFilters, search:value, ...currentParams }, { limit: 10 }));
  };
  
  const handleSearchChangingNewFilters = (filtros) => {
    if (filtros?.descFiltros) {
      Object.keys(filtros.descFiltros).forEach((key) => {
        if (!filtros.descFiltros[key] || filtros.descFiltros[key].length === 0) {
          delete filtros.descFiltros[key];
        }
      });
    }
    setCurrentFilters(filtros);
    handleSearch(filtros);
  };

  useEffect(() => {
    Filtros.set(PARAMS_KEY, currentParams);
    dispatch(getSatisfactionIndexDash({ ...currentFilters, ...currentParams }));
    dispatch(getSatisfactionIndexSummary({ ...currentFilters, ...currentParams }));
    dispatch(getSatisfactionIndexTickets({ ...currentFilters, ...currentParams }, { limit: limit }));
  }, [currentParams]);

  const handleChangePeriod = ({ typePeriod, initialDate, formattedTypePeriod }) => {
    setCurrentParams({ ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
  };

  const handleActiveCategories = () => {
    setIsShowingInactiveCategories(!isShowingInactiveCategories);
  };

  useEffect(() => {
    if(categoriesValuesWithActiveAndInactive) {
      setCategoriasTree(!isShowingInactiveCategories ? categoriesValuesOnlyActive : categoriesValuesWithActiveAndInactive);
    }
  },[isShowingInactiveCategories]);

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Índice de satisfação'}
          onBack={() => window.history.back()}
          extra={[
            // <Button size='small' type='secondary' key='1' icon={<Icon.MDI type='upload' />} onClick={() => setVisibleFilterModal(true)}>
            // Exportar
            // </Button>,
            <Button size='small' type='secondary' key='2' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilterModal(true)}>
            Filtros
            </Button>
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/chamados', name: `${ticketNomenclatures.plural}`, current: true  },
                { path: '/chamados/dashboard-chamados', name: `Dashboard de ${ticketNomenclatures.plural}` },
                { path: '/dashboard-chamados/indice-satisfacao', name: 'Índice de satisfação', current: true },
              ]}
            />
          )}
        >
          <ActiveFilters filters={filtersModal} filtros={currentFilters} onSearch={handleSearchChangingNewFilters}/>
          
          <Column gap='20px'>
            <Column gap='28px'>
              <Column gap='30px'>
                <DateControllerHeader 
                  onChange={handleChangePeriod}
                  typePeriod={currentParams.typePeriod}
                  initialDate={currentParams.initialDate}
                  formattedTypePeriod={currentParams.formattedRangeDate}
                />
                <AssessmentSummary currentFilters={currentFilters} currentParams={currentParams}/>
              </Column>
              <AssessmentCards currentFilters={currentFilters} currentParams={currentParams} limit={limit}/>
            </Column>
            <Column gap='16px'>
              <SearchBar
                ref={inputSearch}
                onSearch={(value) => handleSearchWord(value)}
                marginLess
                placeholder="Pesquisar"
                initialValue={activeSearch}
              />
              <AssessmentTable currentFilters={currentFilters} currentParams={currentParams} limit={limit} setLimit={setLimit}/>
            </Column>
            
          </Column>
        </PageHeader>
        {visibleFilterModal && (
          <Filter 
            visible={visibleFilterModal} 
            onClose={() => {setVisibleFilterModal(false);}}
            filters={filtersModal}
            filtros={currentFilters}
            onSearch={handleSearchChangingNewFilters}
            onChangeValues={{ idCategory: { change: handleActiveCategories, value: isShowingInactiveCategories } }}
          />
        )}
      </Body>
    </App>
  );
}
