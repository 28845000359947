import { dateToAnsi, DATE_FORMATS } from '@utils';

export const queryParamsChamados = (filtros, limit, search, orderBy, count) => {
  if (!filtros) return '';
  
  const query = [];
  const { unidade, categoria, responsavel, perfil, status, usuarioCriacao, dataCriacao, avaliacao, meusTickets, fechadoNoPrazo, fechadoForaPrazo } = filtros;

  if ((dataCriacao) && (dataCriacao.length > 0)) {
    query.push(`dt_criacao_inicio=${dateToAnsi(dataCriacao[0], null, DATE_FORMATS.ANSI_DATE)}`);
    query.push(`dt_criacao_fim=${dateToAnsi(dataCriacao[1], null, DATE_FORMATS.ANSI_DATE)}`);
  }
  if ((unidade) && (unidade.length > 0)) query.push(`unidade=${unidade.join('+')}`);
  if ((categoria) && (categoria.length > 0)) query.push(`categoria=${categoria.join('+')}`);
  if ((responsavel) && (responsavel.length > 0)) query.push(`responsavel=${responsavel.join('+')}`);
  if ((perfil) && (perfil.length > 0)) query.push(`perfil=${perfil.join('+')}`);
  if ((status) && (status.length > 0)) query.push(`status=${status.join('+')}`);
  if ((usuarioCriacao) && (usuarioCriacao.length > 0)) query.push(`usuario_criacao=${usuarioCriacao.join('+')}`);
  if ((avaliacao) && (avaliacao.length > 0)) query.push(`avaliacao=${avaliacao.join('+')}`);
  if (meusTickets) query.push(`meus_tickets=${meusTickets}+`);
  if (fechadoNoPrazo) query.push(`fechado_no_prazo=${fechadoNoPrazo}+`);
  if (fechadoForaPrazo) query.push(`fechado_fora_prazo=${fechadoForaPrazo}+`);

  if (limit) query.push(`limit=${limit}`);
  if (search) query.push(`search=${search}`);
  if (orderBy) {
    query.push(`orderBy=${orderBy.field}`);
    query.push(`orderDirection=${orderBy.order}`);
  }

  query.push('version=2');

  if (query.length === 0) return '';
  return '?' + query.join('&');
};

export const renderHTMLTagUser = ({ cor, descricao }) => (
  `<div style='padding: 0px 5px; margin-left: 2px;'>
    <span style='padding: 3px; color: white; border-radius: 5px; background-color: ${cor};'>
      ${descricao}
    </span>
  </div>`
);