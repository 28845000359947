import styled from 'styled-components';
import { Text, Select, Form, Button, Row } from '@components-teammove';

export const DesvioCondicionalText = styled(Text)`
    font-size: 16px;
    color: ${({ theme }) => theme.textColor};
    font-weight: 400;
    margin-bottom: 12px;
    text-decoration: underline;
    display: block;
    cursor: pointer;
`;

export const SelectStyled = styled(Select)`
    width: 100% !important;
    max-width: 240px !important;
    min-width: 240px !important;
`;

export const FormItemStyledWidth = styled(Form.Item)`
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth || '240px'} !important;
`;

export const ButtonStyled = styled(Button)`
    margin-bottom: 12px;
`;

export const RowStyledConditional = styled(Row)`
    display: ${({ hasToRender }) => (hasToRender ? 'flex' : 'none')};
`;