export const optionsDigitalSignature = [
  { 
    value: 'N', 
    label: 'Não' 
  }, { 
    value: 'O', 
    label: 'Opcional' 
  }, 
  { 
    value: 'S', 
    label: 'Obrigatório' 
  },
];

export const optionsSendEmail = [
  {
    value: 'N',
    label: 'Não',
  },
  {
    value: 'S',
    label: 'Sim',
  }
];

export const optionsAllowComparison = [
  {
    value: 'N',
    label: 'Não',
  },
  {
    value: 'S',
    label: 'Sim',
  }
];

export const optionsRestrictedObservation = [
  {
    value: 'S',
    label: 'Obrigatório',
  },
  {
    value: 'N',
    label: 'Não utiliza',
  },
  {
    value: 'O',
    label: 'Opcional',
  }
];

export const optionsStatus = [
  {
    value: 'EM_CADASTRO',
    label: 'Em cadastro',
  },
  {
    value: 'LIBERADO',
    label: 'Liberado',
  },
  {
    value: 'DESATIVADO',
    label: 'Desativado',
  }
];