import { darken } from 'polished';
import styled, { keyframes } from 'styled-components';
import { Icon, Badge } from '@components-teammove';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -20px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translate(0, 0);
  }
  to {
    opacity: 0;
    transform: translate(0, 20px);
  }
`;

export const BellIcon = styled(Icon.MDI)`
  font-size: 1.3em !important;
  color: ${({ theme }) => theme.textColor} !important;
`;

export const StyledNotificationContainer = styled.div`
    background-color: ${({ theme }) => darken(0.0425, theme.darkPrimaryColor)};
    border-radius: 18px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme }) => theme.textColor};
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    max-width: 388px;
    max-height: 85vh;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    z-index: 10;
    position: fixed;
    top: 8%;
    left: auto;
    right: 24px;
    transform: none; 
    transition: all 0.5s ease;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.5s ease-in-out;
    overflow: hidden;
    cursor: auto;
`;

export const ScrollContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    width: 100%;
    margin: 0 auto;
`;

export const CloseIcon = styled(Icon.Feather)`
  cursor: pointer;
`;

export const BadgeNotification = styled(Badge)`
  .ant-badge-count {
    background-color: ${({ theme }) => theme.actionColor};
    color: ${({ theme }) => theme.textColor};
  }
`;