import { TelasNomenclaturas, stateOptions } from '@utils';

const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

export const initialFilters = [
  {
    type: 'SELECT',
    label: 'Agrupador',
    name: 'companyGroups',
    placeholder: 'Selecione os agrupadores',
    options: []
  }, 
  {
    type: 'SELECT',
    label: 'Estado',
    name: 'states',
    placeholder: 'Selecione os Estados',
    options: stateOptions
  },
  {
    type: 'SELECT',
    label: `Tipo de ${companyNomenclature.nomenclatura}`,
    name: 'companyKinds',
    placeholder: `Selecione ${companyNomenclature.artigo}s ${companyNomenclature.nomenclatura}`,
    options: []
  },
];

export const getOrderByFieldFromColumnIndex = (index) => {
  switch (index) {
    case 'shortName':
      return 'identifier';
    case 'name':
      return 'name';
    case 'cnpj':
      return 'cnpj';
    case 'companyKind':
      return 'companyKind';
    case 'companyGroup':
      return 'companyGroup';
    case 'expired':
      return 'expired';
    case 'unexpired':
      return 'unexpired';
    default:
      return 'identifier';
  }
};