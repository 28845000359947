import styled from 'styled-components';
import { Button, Text } from '@components-teammove';

export const LoadMoreButton = styled(Button)`
  align-self: center;
`;

export const StatusDotIndicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const IndicatorCard = styled.div`
  display: flex;
  flex: 1;
  padding: 24px;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
`;

export const SwitchLabel = styled(Text)`
  color: ${({ theme }) => theme.textColor};
`;