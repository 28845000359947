export const IconQuestionType = (type) => {
  switch(type) {
    case 'ESCOLHA_UNICA':
      return 'check-circle';
    case 'MULTIPLA_ESCOLHA':
      return 'check-square';
    case 'TEXTO':
      return 'type';
    case 'TEXTO_PARAGRAFO':
      return 'align-left';
    case 'DATA':
      return 'calendar';
    case 'HORARIO':
      return 'clock';
    case 'ANEXO':
      return 'paperclip';
    default:
      return 'file';
  }
};