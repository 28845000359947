import { Checkbox, Form, InputNumber, Row } from '@components-teammove';
import React from 'react';

const Numerico = ({ form }) => {
  return (
    <>
      <Form.Item name='monetario' valuePropName='checked'>
        <Checkbox>Monetário</Checkbox>
      </Form.Item>
      <Row width='100%' align='center' gap='12px'>
        <Form.Item name="casas_decimal" label="Nº Casas decimais" rules={[{ required: true, message: 'Por favor, insira o numero de casas decimais' }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="intervalo_minimo" label="Valor mínimo" rules={[{ required: true, message: 'Por favor, insira o valor mínimo' }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="intervalo_maximo" label="Valor máximo" rules={[{ required: true, message: 'Por favor, insira o valor máximo' }]}>
          <InputNumber />
        </Form.Item>
      </Row>
    </>
  );
};

export default Numerico;