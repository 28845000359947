import React, { useState } from 'react';
import App from '@app';
import { Body, PageHeader, Column, Tabs, Breadcrumb } from '@components-teammove';
import { useSelector } from 'react-redux';
import { Session } from '@utils';
import ImportacaoProdutos from './ImportacaoProdutos';

const SESSION_KEY = 'CONFIG_CONTENT_TAB';

export default function ConfiguracoesUnidades() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const [currentTab, setCurrentTab] = useState(Session.get(SESSION_KEY) || 'Importação de produtos');

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set(SESSION_KEY, newTab);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Conteúdos'}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/conteudos', name: 'Configurações de conteúdos', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs 
              options={[
                { name: 'Importação de produtos' }
              ]} 
              current={currentTab} 
              onChange={handleChangeTab}
            />
            {currentTab === 'Importação de produtos' ? <ImportacaoProdutos /> : null}
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}
