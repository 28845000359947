import { get } from '../api';

export const getIndicadores = async (infoUsuario, tela = 'UNIDADE', idTela, idPai = 0) => {
  let path = `/api/v1/indicador/visualizacao/tela/${tela}?idTela=${idTela}`;
  if (tela === 'UNIDADE' ? !!idPai : idPai > 0) {
    path += `&idPai=${idPai}`;
  }

  const headers = {
    'GERENTEKEY': infoUsuario.key,
    'GERENTETOKEN': infoUsuario.token,
  };

  return get(path, headers);
};