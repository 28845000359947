import React from 'react';
import { Icon, Form, Column, DividedLine, Row, ChartDonut, View } from '@components-teammove';
import { Container, Header, ContentImageHeader, ContentInfoHeader, UserNameHeader, UserPhotoHeader, IconDropDown, ContentBody, ContentMenu, MenuItem, ContainerPreview, ContainerPreviewLogin, CardContainer, ContentImage, FormItemInput, PrimaryButton, SecondaryButton, RecoverPassword, ItemInput, ItemPasswordInput, ItemTextAreaInput, SelectItem, PreviewModalContainer, ModalContainer, ModalHeader, ModalTitle, ModalBody, ModalFooter, UploadContent, UploadIcon, UploadLabel, DropdownItem, Logo, LoadingButtonApp, GestaoContainerCard, OkStatusText, IconFeather, OkStatus, OthersStatusText, MiniCard, MiniCardPrimaryInfo, MiniCardSubtitleInfo, ColoredBall, PercentageText } from './styles';
import { TelasNomenclaturas } from '@utils';

const DEFAULT_LOGO = 'https://res.cloudinary.com/teammove/image/upload/v1667907784/logo_teammove_small_white.png';
const DEFAULT_USER_PHOTO = 'https://res.cloudinary.com/teammove/image/upload/v1566574600/clientes/sem_foto.png';

export default function WhitelabelPreview({ whitelabel }) {
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  return (
    <Container>
      <Header themePreview={whitelabel}>
        <ContentImageHeader>
          <Logo src={whitelabel?.logoHeader || DEFAULT_LOGO} alt='Logo header' />
        </ContentImageHeader>
        <ContentInfoHeader>
          <UserNameHeader themePreview={whitelabel}>John Doe</UserNameHeader>
          <UserPhotoHeader src={DEFAULT_USER_PHOTO} />
          <IconDropDown themePreview={whitelabel} />
        </ContentInfoHeader>
      </Header>
      <ContentBody>
        <ContentMenu themePreview={whitelabel}>
          <MenuItem><Icon.HomeOutlined />Início</MenuItem>
          <MenuItem><Icon.TrophyOutlined />Campanhas</MenuItem>
          <MenuItem><Icon.ShopOutlined />{companyNomenclature.plural}</MenuItem>
          <MenuItem><Icon.SettingOutlined />Configurações</MenuItem>
        </ContentMenu>
        <Column width='100%' gap='8px' >
          <ContainerPreview themePreview={whitelabel}>
            <ContainerPreviewLogin>
              <CardContainer themePreview={whitelabel} $isLogin>
                <ContentImage themePreview={whitelabel}>
                  <Logo src={whitelabel?.logo || DEFAULT_LOGO} alt='Logo' />
                </ContentImage>
                <Form hideRequiredMark layout='vertical'>
                  <FormItemInput themePreview={whitelabel} label='Login' rules={[{ required: true, message: 'Informe o login!' }]}>
                    <ItemInput themePreview={whitelabel} placeholder='teammove@teammove.com.br' />
                  </FormItemInput>
                  <FormItemInput themePreview={whitelabel} label='Senha' rules={[{ required: true, message: 'Informe a senha!' }]}>
                    <ItemPasswordInput themePreview={whitelabel} placeholder='********' />
                  </FormItemInput>
                  <FormItemInput>
                    <PrimaryButton themePreview={whitelabel} type='primary' htmlType='submit' block>Entrar</PrimaryButton>
                    <RecoverPassword themePreview={whitelabel}>Esqueci minha senha</RecoverPassword>
                  </FormItemInput>
                </Form>
              </CardContainer>
              <CardContainer themePreview={whitelabel} app>
                <Column gap='50px' align='center' justify='center' width='100%' height='100%'>
                  <Logo src={whitelabel?.logo || DEFAULT_LOGO} alt='Logo' />
                  <ModalFooter>
                    <LoadingButtonApp themePreview={{ ...whitelabel, variant: 'secondary'  }}  />
                    <LoadingButtonApp themePreview={{ ...whitelabel, variant: 'tertiary'  }}   />
                    <LoadingButtonApp themePreview={{ ...whitelabel, variant: 'quarternary'  }}   />
                  </ModalFooter>
                </Column>
              </CardContainer>
            </ContainerPreviewLogin>
            <PreviewModalContainer>
              <ModalContainer themePreview={whitelabel}>
                <ModalHeader>
                  <ModalTitle themePreview={whitelabel}>Exemplo de modal</ModalTitle>
                </ModalHeader>
                <Form hideRequiredMark layout='vertical'>
                  <ModalBody>
                    <FormItemInput themePreview={whitelabel} label={companyNomenclature.nomenclatura} rules={[ { required: true, message: `Informe ${(companyNomenclature.artigo) === 'a' ? 'uma' : 'um'} ${(companyNomenclature.nomenclatura).toLowerCase()}` } ]}>
                      <SelectItem themePreview={whitelabel} placeholder={'Selecione ' + ((companyNomenclature.artigo) === 'a' ? 'uma' : 'um') + ' ' + (companyNomenclature.nomenclatura).toLowerCase()} dropdownRender={(menu) => <DropdownItem themePreview={whitelabel}>{menu}</DropdownItem>}>
                        <SelectItem.Option value={1}>{companyNomenclature.nomenclatura} 1</SelectItem.Option>
                        <SelectItem.Option value={2}>{companyNomenclature.nomenclatura} 2</SelectItem.Option>
                        <SelectItem.Option value={3}>{companyNomenclature.nomenclatura} 3</SelectItem.Option>
                      </SelectItem>
                    </FormItemInput>
                    <FormItemInput themePreview={whitelabel} label='Assunto' rules={[{ required: true, message: 'Informe o assunto!' }]}>
                      <ItemInput themePreview={whitelabel} placeholder='Escreva o assunto do ticket' />
                    </FormItemInput>
                    <FormItemInput themePreview={whitelabel} label='Mensagem' rules={[{ required: true, message: 'Informe a mensagem!' }]}>
                      <ItemTextAreaInput themePreview={whitelabel} placeholder='Escreva uma mensagem para quem vai resolver seu ticket' />
                    </FormItemInput>
                    <FormItemInput themePreview={whitelabel} label='Anexo' rules={[{ required: true, message: 'Informe um anexo!' }]}>
                      <UploadContent themePreview={whitelabel}>
                        <UploadIcon themePreview={whitelabel} type='upload' />
                        <UploadLabel themePreview={whitelabel}>Clique ou arraste um arquivo para anexar ao ticket</UploadLabel>
                      </UploadContent>
                    </FormItemInput>
                  </ModalBody>
                  <FormItemInput>
                    <ModalFooter>
                      <SecondaryButton themePreview={whitelabel} type='secondary' htmlType='submit' block>Cancelar</SecondaryButton>
                      <PrimaryButton themePreview={whitelabel} type='primary' htmlType='submit' block>Salvar</PrimaryButton>
                    </ModalFooter>
                  </FormItemInput>
                </Form>
              </ModalContainer>
            </PreviewModalContainer>
          </ContainerPreview>
          <PreviewModalContainer flexCenter>
            <GestaoContainerCard themePreview={whitelabel}>
              <ModalHeader>
                <ModalTitle themePreview={whitelabel}>Exemplo cluster</ModalTitle>
              </ModalHeader>
              <OkStatus>
                <IconFeather color='ok' size='26' type='thumbs-up'/> 

                <OkStatusText>Ok: </OkStatusText>
                <OkStatusText bold>25</OkStatusText>
                <OkStatusText>{'(25%)'}</OkStatusText>

              </OkStatus>

              <DividedLine
                subtitleDisabled
                divisions={[
                  { label: 'Concluído', percentage: 25, status: 'success' }, 
                  { label: 'Alerta', percentage: 25, status: 'warning' },
                  { label: 'Crítico', percentage: 25, status: 'danger' },
                  { label: 'Sem Histórico', percentage: 25, useBgColor: true },
                ]}
              />  
              <Row justify='space-between' margin='10px 0 0 0'>
                <Row align='flex-start' gap= '24px'>
          
                  <Row align='center' gap='8px'>
                    <IconFeather color='alert' size='24' type='thumbs-up'/> 
                    <Row gap='4px'>
                      <OthersStatusText color='alert'>Alerta:</OthersStatusText>
                      <OthersStatusText bold>25</OthersStatusText>
                      <OthersStatusText opacity>{'(25%)'}</OthersStatusText>
                    </Row>
                  </Row>
            
                  <Row align='center' gap='8px'>
                    <IconFeather color='critical' size='24' type='thumbs-down'/> 
                    <Row gap='4px'>
                      <OthersStatusText color='critical'>Crítico:</OthersStatusText>
                      <OthersStatusText bold>25</OthersStatusText>
                      <OthersStatusText opacity>{'(25%)'}</OthersStatusText>
                    </Row>
                  </Row>

                  <Row align='center' gap='8px'>
                    <IconFeather color='n/a' size='24' type='x-circle'/> 
                    <Row gap='4px'>
                      <OthersStatusText opacity>Sem Histórico:</OthersStatusText>
                      <OthersStatusText bold>25</OthersStatusText>
                      <OthersStatusText opacity>{'(25%)'}</OthersStatusText>
                    </Row>
                  </Row>
                </Row>
              </Row>
              <Row gap='20px' margin='20px 0 0 0'>
                <MiniCard>
                  <Column gap='20px'>
                    <Column>
                      <Row justify='space-between' align='center'>
                        <MiniCardPrimaryInfo>Nome do cluster</MiniCardPrimaryInfo>
                        <MiniCardPrimaryInfo>50</MiniCardPrimaryInfo>
                      </Row>
                      <Row justify='space-between' align='center'>
                        <MiniCardSubtitleInfo>Frequência: 25 dias</MiniCardSubtitleInfo>
                        <MiniCardSubtitleInfo>{((companyNomenclature.plural)?.toLowerCase() || 'unidades')}</MiniCardSubtitleInfo>
                      </Row>
                    </Column>
              
                    <Row align= 'flex-end' justify='space-between'>
                
                      <Row gap='8px' align='center'>
                        <ChartDonut 
                          values ={[
                            25, 25, 25, 25
                          ]}
                          successNumber={25}
                          successPercent={25}
                        />
                        <View>
                          <Row gap='4px' align='center'>
                            <ColoredBall color='ok'/>
                            <Row gap='4px'>
                              <PercentageText>25</PercentageText>
                              <PercentageText opacity>{'(25%)'}</PercentageText>
                            </Row>
                          </Row>
                          <Row gap='4px' align='center'>
                            <ColoredBall color='critical'/>
                            <Row gap='4px'>
                              <PercentageText>25</PercentageText>
                              <PercentageText opacity>{'(25%)'}</PercentageText>
                            </Row>
                          </Row>
                          <Row gap='4px' align='center'>
                            <ColoredBall color='alert'/>
                            <Row gap='4px'>
                              <PercentageText>25</PercentageText>
                              <PercentageText opacity>{'(25%)'}</PercentageText>
                            </Row>
                          </Row>
                          <Row gap='4px' align='center'>
                            <ColoredBall color='n/a'/>
                            <Row gap='4px'>
                              <PercentageText>25</PercentageText>
                              <PercentageText opacity>{'(25%)'}</PercentageText>
                            </Row>
                          </Row>
                        </View>
                      </Row>
                    </Row>

                  </Column>
                </MiniCard>
                <MiniCard isNotCluter>
                  <Column gap='12px'>
                    <Column>
                      <Row justify='space-between' align='center'>
                        <MiniCardPrimaryInfo>Sem Cluster</MiniCardPrimaryInfo>
                        <MiniCardPrimaryInfo>100</MiniCardPrimaryInfo>
                      </Row>
                      <Row justify='end' align='center'>
                        <MiniCardSubtitleInfo>{((companyNomenclature.plural)?.toLowerCase() || 'unidades')}</MiniCardSubtitleInfo>
                      </Row>
                    </Column>
                    <Row>
                      <MiniCardSubtitleInfo>
                        Encontramos 100 {((companyNomenclature.plural)?.toLowerCase() || 'unidades')} sem o cluster para {' '}   
                        <MiniCardSubtitleInfo isBold>
                          exemplo de cluster
                        </MiniCardSubtitleInfo>
                      </MiniCardSubtitleInfo>
                    </Row>     
                  </Column>
                </MiniCard>
              </Row>
            </GestaoContainerCard>
          </PreviewModalContainer>
        </Column>
      </ContentBody>
    </Container>
  );
}