import React from 'react';
import { Column, Text, Row, Tooltip } from '@components-teammove';
import { FlexibleInputView, FlexibleSelectStyle, Typography } from './styles';
import { useSelector } from 'react-redux';
import { TelasNomenclaturas } from '@utils';

export const RelateColumns = ({ onChange, relationshipData }) => {
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  const uploadExcelResponse = useSelector(({ companyImport }) => companyImport.get('uploadExcelResponse'));

  const handleChangeRelationship = (columnId, optionId) => {
    const changeOptionOfColumn = relationshipData.map((item) => {
      if (item.id === columnId) {
        return { ...item, importCompanyColumnId: optionId };
      }
      return item;
    });

    onChange(changeOptionOfColumn);
  };

  return (
    <Column gap='20px' padding='20px 0 20px 8px'>
      <Column gap='8px'>
        <Text size='24px' weight='700'>Relacionar colunas</Text>
        <Text size='16px' weight='500' lowOpacity>Relacione as colunas da sua planilha com os campos d{companyNomenclature.artigo}s {companyNomenclature.plural}</Text>
      </Column>

      <Row width='100%'>

        <Column gap='12px' width='50%'>
          <Text size='20px' weight='600'>Colunas que esperamos</Text>
          {relationshipData.map((column) => (
            <FlexibleInputView key={column.id} relationed={column.importCompanyColumnId !== 0 && column.importCompanyColumnId !== null}>
              <Tooltip title={column.description}>
                <Typography size='16px' weight='400'>{column.description}</Typography>
              </Tooltip>
            </FlexibleInputView>
          ))}
        </Column>

        <Column width='50%' gap='12px' padding='0 0 0 0'>
          <Text size='20px' weight='600'>Colunas da sua planilha</Text>
          {relationshipData.map((column) => (
            <FlexibleSelectStyle
              key={column.id}
              placeholder='Selecione a coluna'
              value={column.importCompanyColumnId !== 0 && column.importCompanyColumnId !== null ? column.importCompanyColumnId : undefined}
              options={[
                ...(column.importCompanyColumnId !== 0 && column.importCompanyColumnId !== null
                  ? [{ key: 0, value: 0, label: 'Nenhum' }]
                  : []),
                ...uploadExcelResponse.spreadsheetColumns.map((item) => ({
                  key: item.importCompanyColumnId,
                  value: item.importCompanyColumnId,
                  label: item.columnName
                }))
              ]}
              onChange={(key) => handleChangeRelationship(column.id, key)}
            />
          ))}
        </Column>
      </Row>
    </Column>
  );
};