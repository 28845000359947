import React, { useEffect, useState } from 'react';
import { Card, InputStyledNoBox } from '../styles';
import { Grid, Input, Form, Select, Skeleton } from '@components-teammove';
import { optionsAllowComparison, optionsDigitalSignature, optionsRestrictedObservation, optionsSendEmail, optionsStatus } from './rules';
import { useSelector } from 'react-redux';

const TituloChecklist = ({ form }) => {
  const loadingChecklistTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('loadingChecklistTemplate'));
  const [visaoConsultor, setVisaoConsultor] = useState('S');
  
  useEffect(() => {
    setVisaoConsultor(form.getFieldValue('visao_consultor'));
  }, [form.getFieldValue('visao_consultor')]);

  return loadingChecklistTemplate ? (
    <Skeleton.Card/>
  ) : (
    <Card>
      <Form form={form} layout='vertical'>
        <Form.Item hidden name='id'/>
        <Form.Item name="nome" rules={[{ required: true, message: 'Por favor, insira o título' }]}>
          <InputStyledNoBox placeholder='Digite um título do checklist' title/>
        </Form.Item>
        <Form.Item name="descricao">
          <InputStyledNoBox placeholder='Digite uma descrição para o checklist'/>
        </Form.Item>
        <Grid numberOfColumns={2} gap='0 20px' width='100%'>
          <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Por favor, insira o status' }]}>
            <Select placeholder='Escolha uma opção' options={optionsStatus}/>
          </Form.Item>
          <Form.Item label="Assinatura digital" name="assinatura_digital" rules={[{ required: true, message: 'Por favor, insira a assinatura digital' }]}>
            <Select placeholder='Escolha uma opção' options={optionsDigitalSignature}/>
          </Form.Item>
          <Form.Item label="Enviar email?" name="enviar_email" rules={[{ required: true, message: 'Por favor, insira este campo' }]}>
            <Select placeholder='Escolha uma opção'  options={optionsSendEmail}/>
          </Form.Item>
          <Form.Item label="Permitir comparação" name="permite_comparacao" rules={[{ required: true, message: 'Por favor, insira este campo' }]}>
            <Select placeholder='Escolha uma opção'  options={optionsAllowComparison}/>
          </Form.Item>
          <Form.Item label="Observação restrita" name="visao_consultor" rules={[{ required: true, message: 'Por favor, insira a observação restrita' }]}>
            <Select placeholder='Escolha uma opção'  options={optionsRestrictedObservation} onChange={(value) => setVisaoConsultor(value)}/>
          </Form.Item>
          <Form.Item hidden={visaoConsultor === 'N'} label="Nome da observação restrita" name="nome_laudo">
            <Input placeholder='visão do consultor'/>
          </Form.Item>
        </Grid>
      </Form>
    </Card>
  );
};

export default TituloChecklist;