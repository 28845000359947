import React from 'react';
import { Table, Row, Tooltip, Avatar, Text, Column, AudioPlayer } from '@components-teammove';
import { TelasNomenclaturas, getDownloadUrlAvatar, formatDate, formatTime, formatCurrency, formatTelephone, subtractHours } from '@utils';
import { FeatherIcon, ImportanceIcon, VerticalDivider } from './styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const TableComponent = ({ onSorter, orderByField, orderByDirection }) => {
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  const history = useHistory();
  
  const callList = useSelector(({ callActivity }) => callActivity.get('callList'));
  const loadingCallList = useSelector(({ callActivity }) => callActivity.get('loadingCallList'));

  const formatContactImporation = (text) => {
    switch (text) {
      case 'Nao_Relevante':
        return 'Nao Relevante';
      case 'Relevante':
        return 'Relevante';
      case 'Sem_Contato':
        return 'Sem Contato';
      default:
        return 'Erro';
    }
  };

  const columns = [
    {
      title: companyNomenclature.nomenclatura,
      defaultSortOrder: orderByField === 'UNIDADE' ? orderByDirection.toLowerCase()  + 'end' : null,
      dataIndex: 'companyName',
      sorter: true,
      width: '200px',
      render: (text, itens) => (
        <Column>
          <Tooltip title={text?.nm_reduzido}>
            <Text 
              size='18px' 
              weight='400' 
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='200px'
            >{itens.companyShortName}</Text>
          </Tooltip>
          <Tooltip title={text?.nm_empresa}>
            <Text 
              size='14px' 
              weight='400' 
              lowOpacity
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='200px'
            >{text}</Text>
          </Tooltip>
        </Column>
      )
    },
    {
      title: 'Contato',
      dataIndex: 'contactName',
      defaultSortOrder: orderByField === 'CONTATO' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Column>
          <Tooltip title={text}>
            <Text 
              size='18px' 
              weight='400' 
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='150px'
            >{text}</Text>
          </Tooltip>
          
          <Tooltip title={text}>
            <Text 
              size='14px' 
              weight='400' 
              lowOpacity
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='150px'
            >{formatTelephone(itens.numberCalled.substring(3))}</Text>
          </Tooltip>
        </Column>
      )
    },
    {
      title: 'Responsável',
      dataIndex: 'userName',
      defaultSortOrder: orderByField === 'RESPONSAVEL' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Row align='center' gap='12px'>
          <Avatar size='44px' src={getDownloadUrlAvatar(itens.avatarUrl)}/>
          <VerticalDivider />
          <Column>
            <Tooltip title={text}>
              <Text 
                textOverflow='ellipsis'
                whiteSpace='nowrap' 
                overflow='hidden' 
                maxWidth='100px' 
                size='18px' 
                weight='inherit'
              >
                {text}
              </Text>
            </Tooltip>

            <Tooltip title={itens.userRole}>
              <Text 
                size='14px' 
                weight='400' 
                lowOpacity
                textOverflow='ellipsis'
                whiteSpace='nowrap' 
                overflow='hidden' 
                maxWidth='100px'
              >
                {itens.userRole}
              </Text>
            </Tooltip>
          </Column>
        </Row>
      )
    },
    {
      title: 'Data',
      dataIndex: 'recordStartTime',
      defaultSortOrder: orderByField === 'DATA' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Column >
          <Text size='18px' weight='400'>{formatDate(text)}</Text>
          <Text size='14px' weight='400' lowOpacity>{subtractHours(formatTime(text), 3)}</Text>
        </Column>
      )
    },
    {
      title: 'Custo',
      dataIndex: 'price',
      defaultSortOrder: orderByField === 'CUSTO' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Column >
          <Text size='18px' weight='400'>{formatCurrency(text)}</Text>
        </Column>
      )
    },
    {
      title: 'Gravação',
      dataIndex: 'recordingUrl',
      defaultSortOrder: orderByField === 'GRAVACAO' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Column onClick={(e) => {
          e.stopPropagation();
        }}
        >
          <AudioPlayer src={text} noOptions/>
        </Column>
      ) },
    {
      title: 'Importância',
      dataIndex: 'classification',
      defaultSortOrder: orderByField === 'IMPORTANCIA' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Column align='center' justify='center'>
          <Row align='center' gap='12px'>
            <Tooltip title={formatContactImporation(text)}>
              <ImportanceIcon importance={text}>
                <FeatherIcon
                  size='24px' type={text === 'Relevante' ? 'thumbs-up' : text === 'Nao_Relevante' ? 'thumbs-down' : text === 'Sem_Contato' ? 'user-x' : 'alert-triangle'} 
                  importance={text}
                />
              </ImportanceIcon>
            </Tooltip>          
          </Row>        
        </Column>
      ) }, 
  ];

  return(
    <Table
      columns={columns} 
      dataSource={callList.listVoiceCallRecords}
      loading={loadingCallList}
      hidePagination
      onChange={((pagination, filters, sorter) => onSorter(sorter.order, sorter.field))}
      onRow={(actionPlan) => {
        return {
          onClick: () => actionPlan?.voiceCallRecordId && history.push(`/atividades/ligacoes/detalhes/${actionPlan?.voiceCallRecordId}`)
        };
      }}
    />
  );

};