import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import App from '@app';
import { Checkbox, Body, PageHeader, Breadcrumb, Button, Form, Input, InputNumber, Select, Switch, Radio, Text, View, Tooltip, Skeleton, Column, Icon } from '@components-teammove';

import { getUsuarios } from '@sdk/Usuarios';
import { getPerfis } from '@sdk/Perfil';
import { saveCategoria, reset } from '@ducks/configuracoesChamadosCategoria';
import { getAtendimentosSelect } from '@sdk/Chamados';
import { getClassifications } from '@ducks/classificacoes';
import { getCategoria, getInitialValues } from './rules';
import BriefingModal from './QuestionarioModal';
import { SmallFormItem } from '../../../styles';
import { CheckboxFull, FormItemPerfil, RadioResponsavel, TextObrigatorio, ButtonCadastro, InfoIcon, ButtonContainer } from './styles';
import { TelasNomenclaturas } from '@utils';
import { getCategoryById, resetCategory } from '@ducks/configuracoesChamados';

const TIPO_RESPONSAVEL = { 
  USUARIO: 1,
  PERFIS: 2,
};

const TIPO_CHAMADO = {
  GRUPO: 'GRUPO',
  ITEM: 'ITEM',
};

export default function CategoriaCadastro() {
  const [responsavel, setResponsavel] = useState(TIPO_RESPONSAVEL.USUARIO);
  const [showConfiguracaoPerfis, setShowConfiguracaoPerfis] = useState(false);
  const [tipo, setTipo] = useState('');
  const [briefing, setBriefing] = useState({});
  const [report, setReport] = useState({});
  const [questionarioModalVisible, setQuestionarioModalVisible] = useState(false);
  const [tipoQuestionario, setTipoQuestionario] = useState('');
  const [classificacoesAtivas, setClassificacoesAtivas] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const theme = useSelector(({ app }) => app.get('theme'));

  const classificacoes = useSelector(({ classificacoes }) => classificacoes.get('classifications'));
  const successClassifications = useSelector(({ classificacoes }) => classificacoes.get('successClassifications'));
  
  const category = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('category'));
  const successCategory = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('successCategory'));
  const loadingCategory = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('loadingCategory'));
  
  const loading = useSelector(({ configuracoesChamadosCategoria }) => configuracoesChamadosCategoria.get('loading'));
  const success = useSelector(({ configuracoesChamadosCategoria }) => configuracoesChamadosCategoria.get('success'));

  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');

  const [initialValues, setInitialValues] = useState();
  
  useEffect(() => {
    if(location.state.id) {
      dispatch(getCategoryById(location.state.id));
    }else {
      setInitialValues(getInitialValues(location.state));
    }
  }, []);

  useEffect(() => {
    if(successCategory) {
      setInitialValues(getInitialValues(category));
    } 
  }, [successCategory]);

  useEffect(() => {
    if(initialValues) {
      if (initialValues?.perfis && initialValues.perfis.length > 0) {
        setResponsavel(TIPO_RESPONSAVEL.PERFIS);
      }

      if (initialValues?.tipo) {
        setTipo(initialValues.tipo);
      }

      if (initialValues?.utilizaQuestionario) {
        initialValues.categoriaQuestionario.forEach((quest) => {
          const setQuestionario = quest.tipoQuestionario === 'BRIEFING' ? setBriefing : setReport;
          if(quest.perguntas.length > 0) {
            setQuestionario({
              title: quest.questionarioTitulo, 
              campoTexto1: quest.campoTexto1, 
              campoTexto2: quest.campoTexto2, 
              campoTexto3: quest.campoTexto3, 
              perguntas: quest.perguntas, 
              id: quest.id, 
              dtCriacao: quest.dtCriacao, 
              tipoQuestionario: quest.tipoQuestionario,
              ativo: quest.ativo
            });
          }

        });
      }
      setShowConfiguracaoPerfis(initialValues?.configuracaoPerfis && initialValues?.configuracaoPerfis.length > 0);
    }  
    dispatch(getClassifications());
    return () => {
      dispatch(reset());
      dispatch(resetCategory());
    };
    
  }, [initialValues]);

  useEffect(() => {
    if(successClassifications) {
      setClassificacoesAtivas(classificacoes.filter((item) => item.ativo));
    }
  }, [successClassifications]);

  useEffect(() => {
    if (success) {
      history.goBack();
    }
  }, [history, success]);
  
  const handleSalvar = () => {
    form.validateFields().then((values) => {

      switch(responsavel) {
        case TIPO_RESPONSAVEL.PERFIS:
          values.idUsuarioExecutar = null;
          break;
        default:
          values.perfis = null;
          values.permiteUsuarioResponsavel = false;
          break;  
      }
      let perguntasQuestionario = [];

      const categoriasQuestionario = [briefing, report].filter((quest) => Object.keys(quest).length).map((questionario) => {
        perguntasQuestionario = questionario.perguntas.map((perg) => {
          const isNewQuestion = !perg.id;
          if (isNewQuestion) {
            return{
              titulo: perg.titulo, 
              tipo: perg.tipo, 
              ordem: perg.ordem, 
              obrigatorio: perg.obrigatorio, 
              opcoes: perg.opcoes?.map((opt) => ({ titulo: opt.titulo }))
            };
          }
          else{
            const initialQuestions = initialValues.categoriaQuestionario
              .filter((data) => data.perguntas.length > 0)
              .map((data) => data.perguntas)
              .flat();

            const initialValuesPerg = initialQuestions.find((data) => data.id === perg.id);               
            const { dtCriacao, idCategoriaQuestionario } = initialValuesPerg;

            return {
              ativo: perg.ativo,
              dtCriacao: new Date(dtCriacao.valueOf()),
              idCategoriaQuestionario,
              id: perg.id,
              titulo: perg.titulo, 
              tipo: perg.tipo, 
              ordem: perg.ordem, 
              obrigatorio: perg.obrigatorio, 
              opcoes: perg.opcoes?.map((opt) => {
                if (opt.id) {
                  const initialValuesPergOpt = initialValuesPerg.opcoes.find((option) => option.id === opt.id);
                  const { dtCriacao, idCategoriaBriefingPergunta } = initialValuesPergOpt;
                  return {
                    ativo: opt.ativo,
                    dtCriacao: new Date(dtCriacao.valueOf()),
                    idCategoriaBriefingPergunta,
                    titulo: opt.titulo, 
                    id: opt.id
                  }; 
                }
                else{
                  return { titulo: opt.titulo };
                }
              })
            };
          }
        });

        const categoriaQuestionario = {
          questionarioTitulo: questionario.title,
          tipoQuestionario: questionario.tipoQuestionario,
          ativo: questionario.id ? questionario.ativo : true,
          perguntas: perguntasQuestionario,
          campoTexto1: questionario.campoTexto1,
          campoTexto2: questionario.campoTexto2,
          campoTexto3: questionario.campoTexto3,
        };
        if(questionario.id) {
          categoriaQuestionario.id = questionario.id;
          categoriaQuestionario.dtCriacao = new Date(questionario.dtCriacao.valueOf());
          categoriaQuestionario.dtManutencao = new Date().toISOString();
        }    
        return categoriaQuestionario;
      });

      values = {
        ...values,
        utilizaQuestionario: categoriasQuestionario.length > 0,
        categoriaQuestionario: categoriasQuestionario
      };

      dispatch(saveCategoria(getCategoria(values)));
    });
  };

  const handleOkModalBriefing = (questionario) => {
    if(questionario) {
      const setQuestionario = questionario.tipoQuestionario === 'BRIEFING' ? setBriefing : setReport;
      setQuestionario(questionario);
    } else {
      setBriefing({});
      setReport({});
    }
    
    setQuestionarioModalVisible(false);
  };

  const displayQuestionarioModal = (tipo) => {
    setTipoQuestionario(tipo);
    setQuestionarioModalVisible(true);
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title="Configurações / Atendimentos / Categoria"          
          extra={[
            <Button key="1" size='small' context='header' type='secondary' icon={<Icon.CloseOutlined />} onClick={() => history.goBack()}>Cancelar</Button>,
            <Button key="2" size='small' context='header' type="primary" icon={<Icon.SaveOutlined />} onClick={handleSalvar} loading={loading}>Salvar</Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: 'configuracoes/chamados', name: 'Configurações' },
                { path: 'configuracoes/chamados', name: `Configurações de ${ticketNomenclatures.plural}` },
                { path: 'configuracoes/chamados/categoria', name: 'Cadastro de Categorias' }
              ]}
            />
          )}
        >
          {loadingCategory ? <>
            <Column gap='12px' width='300px'>
              <Skeleton.Form/>
           
              <Skeleton.Form/>
              <Skeleton.Form/>
            </Column>
            
          </> : 
            initialValues &&
          <>
            <Form layout="vertical" form={form} initialValues={initialValues}>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>

              <Form.Item name="idCategoria" hidden>
                <Input />
              </Form.Item>

              <SmallFormItem label="Tipo" name="tipo" rules={[{ required: true, message: 'Informe o tipo!' }]}>
                <Select placeholder="Informe o tipo" disabled={initialValues.disabledTipo || initialValues.id} onChange={(value) => setTipo(value)}>
                  <Select.Option key="GRUPO">Grupo</Select.Option>
                  <Select.Option key="ITEM">Item</Select.Option>
                </Select>
              </SmallFormItem>

              <SmallFormItem label="Título" name="titulo" rules={[{ required: true, message: 'Informe o título!' }]}>
                <Input placeholder="Informe o título" />
              </SmallFormItem>

              <SmallFormItem label="Resumo" name="resumo">
                <Input placeholder="Informe o resumo" />
              </SmallFormItem>

              {tipo === TIPO_CHAMADO.ITEM && 
            <> 
              <SmallFormItem label="Horas (SLA)" name="tempoHoras" rules={[{ required: true, message: 'Informe o tempo!' }]}>
                <InputNumber placeholder="0" />
              </SmallFormItem>

              <SmallFormItem label="Classificações" name="classificacoes">
                <Select
                  tokenSeparators={[',']}
                  mode="tags"
                  options={classificacoesAtivas?.map((item) => ({ value: item.id, label: item.titulo }))}
                  placeholder="Selecione as classificações disponíveis"
                />
              </SmallFormItem>
            
              <View>              
                <Text><TextObrigatorio>*</TextObrigatorio>Responsável</Text>            
              </View>
              <RadioResponsavel value={responsavel} onChange={(val) => setResponsavel(val)}>
                <Radio value={TIPO_RESPONSAVEL.USUARIO}>Usuário</Radio>
                <Radio value={TIPO_RESPONSAVEL.PERFIS}>Perfis</Radio>
              </RadioResponsavel>

              {responsavel === TIPO_RESPONSAVEL.USUARIO && 
                <SmallFormItem name="idUsuarioExecutar" rules={[{ required: true, message: 'Informe o usuário!' }]}>
                  <Select
                    form={form}
                    name="idUsuarioExecutar"
                    action={() => getUsuarios()}
                    placeholder="Informe o usuário"
                  />
                </SmallFormItem>
              }

              {responsavel === TIPO_RESPONSAVEL.PERFIS &&
                <>
                  <FormItemPerfil name="perfis" rules={[{ required: true, message: 'Informe o perfil!' }]}>
                    <Select 
                      mode="multiple"
                      action={getPerfis}
                    />
                  </FormItemPerfil>
                  <Form.Item name="permiteUsuarioResponsavel" valuePropName="checked">
                    <Checkbox>Selecionar usuário na abertura do chamado</Checkbox>
                  </Form.Item>
                </>  
              }
               
              <SmallFormItem label="Horário de atendimento" name="idAtendimento">
                <Select
                  form={form}
                  name="idAtendimento"
                  action={() => getAtendimentosSelect()}
                  placeholder="Informe a configuração de atendimento"
                />
              </SmallFormItem>
            </>
              }

              <CheckboxFull checked={showConfiguracaoPerfis} onChange={(checked) => setShowConfiguracaoPerfis(checked)}>Restringir por perfis</CheckboxFull>

              {showConfiguracaoPerfis &&
              <SmallFormItem name="configuracaoPerfis">
                <Select 
                  mode="multiple"
                  action={getPerfis}
                  placeholder='Selecione os perfis'
                />
              </SmallFormItem>  
              }

              <Form.Item label="Ativo" name="ativo" valuePropName="checked">
                <Switch />
              </Form.Item>

              {tipo === TIPO_CHAMADO.ITEM && (
                <>
                  <ButtonContainer>
                    <ButtonCadastro
                      type='secondary'
                      size='small'
                      onClick={() => displayQuestionarioModal('BRIEFING')}
                    >
                      {briefing.perguntas ? (
                        <Icon.EditOutlined />
                      ) : (
                        <Icon.PlusOutlined />
                      )}
                Briefing
                    </ButtonCadastro>
                    <ButtonCadastro
                      type='secondary'
                      size='small'
                      onClick={() => displayQuestionarioModal('REPORT')}
                    >
                      {report.perguntas ? (
                        <Icon.EditOutlined />
                      ) : (
                        <Icon.PlusOutlined />
                      )}
                  Report
                    </ButtonCadastro>
                    <Tooltip title='Briefing e Report são templates de perguntas para abertura e fechamento dos tickets' placement="right">
                      <InfoIcon />
                    </Tooltip>
                  </ButtonContainer>
                </>
              )}

              {questionarioModalVisible && (
                <BriefingModal
                  data={tipoQuestionario === 'BRIEFING' ? briefing : report}
                  visible={questionarioModalVisible}
                  onCancel={() => setQuestionarioModalVisible(false)}
                  onOk={handleOkModalBriefing}
                  tipoQuestionario={tipoQuestionario}
                />
              )}      
            </Form>
          </>
          }
          
        </PageHeader>
      </Body>
    </App>
  );
}
