import React, { useEffect, useState } from 'react';
import { Column, Text, Classifications, Form } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getClassificationContacts, putClassificationContact, delClassificationContact } from '@ducks/settingsClassificationContacts';
import { colorList } from './rules';

export const Contacts = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [classificacao, setClassificacao] = useState({});
  const [idList, setIdList] = useState([]);
  const [opcoesAtivas, setOpcoesAtivas] = useState();
  const [ indexItemToDelete, setIndexItemToDelete] = useState();

  const classificationContacts = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('classificationContacts'));
  const loadingClassificationContacts = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('loadingClassificationContacts'));
  const successClassificationContacts = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('successClassificationContacts'));

  const loadingPutClassificationContact = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('loadingPutClassificationContact'));
  const successPutClassificationContact = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('successPutClassificationContact'));
  
  const loadingDelClassificationContact = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('loadingDelClassificationContact'));
  const successDelClassificationContact = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('successDelClassificationContact'));
  
  const getNewId = (items) => ((items && items.length > 0 ? Math.max.apply(Math, items) : 0) + 1);

  const handleSaveNewClassification = () => {
    form.validateFields().then((values) => {
      const opcoesMapeadas = classificacao.opcoes.map((opcao, index) => {
        return {
          id: opcao.id,
          description: opcao.description,
          color: opcao.color,
          order: index + 1,
        };
      });

      dispatch(putClassificationContact(opcoesMapeadas));
    }).catch(Form.scrollToFirstError);
    
  };

  useEffect(() => {
    if (classificacao?.opcoes?.length !== opcoesAtivas?.length) {
      setOpcoesAtivas(classificacao?.opcoes?.map((data, index) => ({ ...data, order: index })));
    }
  }, [classificacao]);

  useEffect(() => {
    dispatch(getClassificationContacts());
  },[]);

  useEffect(() => { 
    if(successClassificationContacts && classificationContacts.length > 0) {
      handlePutClassificationContact();
    }
  }, [successClassificationContacts]);

  useEffect(() => {
    if(!successPutClassificationContact) return;
    handlePutClassificationContact();
  }, [successPutClassificationContact]);

  const handleNew = () => {
    const id = getNewId(idList);
    setIdList([...idList, id]);
    const opcoesArray = classificacao?.opcoes || [];
    const coresSelecionadas = opcoesArray.map((opcArray) => opcArray.color);
    const primeiraCorFaltante = colorList.find((color) => !coresSelecionadas.includes(color));

    const novaOpcao = {
      color: primeiraCorFaltante || '#fff',
      description: '',
      order: opcoesArray.length,
      tempId: id,
    };
    
    setClassificacao({ ...classificacao, opcoes: [...opcoesArray, novaOpcao] });

    form.setFieldsValue({ [`cor${id}`]: novaOpcao.color });
  };

  const handleDelete = (item, itemToDelete) => { 
    if(item.id) {
      setIndexItemToDelete(itemToDelete);
      dispatch(delClassificationContact(item.id));
    }else if (!item.id) {
      setClassificacao((prevClassificacoes) => {
        const updatedOpcoes = prevClassificacoes.opcoes.filter((item, index) =>
          index !== itemToDelete
        );
        return { ...prevClassificacoes, opcoes: updatedOpcoes };
      });
    }
   
  };

  useEffect(() => {
    if(successDelClassificationContact) {
      setClassificacao((prevClassificacoes) => {
        const updatedOpcoes = prevClassificacoes.opcoes.filter((item, index) =>
          index !== indexItemToDelete
        );
        return { ...prevClassificacoes, opcoes: updatedOpcoes };
      });
    }
    
  },[successDelClassificationContact]);
  
  const handlePutClassificationContact = () => {
    const options = classificationContacts.reduce((obj, opcao) => ({ ...obj, [`name${opcao.id}`]: opcao.description, [`cor${opcao.id}`]: opcao.color }), {});
    const optionsWithTempId = classificationContacts.map((opcao, index) => ({ ...opcao, tempId: opcao.id, order: index }));
    const ajustOrder = classificationContacts.map((opcao, index) => ({ ...opcao, order: index }));

    form.setFieldsValue({ ...ajustOrder, ...options });
    setClassificacao({ ...ajustOrder, opcoes: optionsWithTempId });
    setIdList(classificationContacts.map((opcao) => opcao.id));
  };

  return(
    <Column width='50%' gap='20px'>
      <Column gap='8px' width='100%'>
        <Text size='12px' weight='600'>Tipo de contato</Text>
        <Classifications 
          data={classificationContacts} 
          loading={loadingClassificationContacts} 
          success={successClassificationContacts}
          handleSalvar={handleSaveNewClassification}
          loadingSave={loadingPutClassificationContact}
          handleDelete={handleDelete}
          form={form}
          classificacao={classificacao}
          handleNew={handleNew}
          setClassificacao={setClassificacao}
          idList={idList}
          setIdList={setIdList}
          opcoesAtivas={opcoesAtivas}
          setOpcoesAtivas={setOpcoesAtivas}
          loadingDel={loadingDelClassificationContact}
        />
      </Column>
     
    </Column>
  
  );
};