import { Form, Icon } from '@components-teammove';
import styled from 'styled-components';

export const FormItemStyled = styled(Form.Item)`
    flex-direction: row !important;
   .ant-form-item-label {
    margin-bottom: 0 !important;
   }
`;

export const FormItemDynamic = styled(Form.Item)`
   display: ${({ hasToRender }) => hasToRender ? 'block' : 'none'};
`;

export const CopyIcon = styled(Icon.MDI)`
   font-size: 20px;
   color: ${({ theme }) => theme.textColor};
   cursor: pointer;
   opacity: 0.5;
   :hover {
      opacity: 1;
   }
`;