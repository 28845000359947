import { Form, Input, Modal } from '@components-teammove';
import React from 'react';

const NovoGrupoChecklist = ({ visible, onCancel, handleAddNewGroup, form }) => (
  <Modal
    visible={visible}
    onCancel={onCancel}
    title={form.getFieldValue('nome_grupo') ? `Editar grupo ${form.getFieldValue('nome_grupo')}` : 'Novo grupo'}
    cancelText='Cancelar'
    okText='Salvar'
    onOk={handleAddNewGroup}
  //   okButtonProps={{ loading: actionType === 'cancel' ? loadingCancel : loadingReopen }}
  >
    <Form form={form} layout='vertical'>
      <Form.Item name='id' hidden />
      <Form.Item
        label="Nome"
        name="nome_grupo"
        rules={
          [
            { required: true, message: 'Por favor, insira o nome do grupo' },
            {
              validator: (data, value) => {
                if (value && value.trim() === '') {
                  return Promise.reject(new Error('O nome do grupo não pode ser apenas espaços em branco!'));
                }
                return Promise.resolve();
              },
            },
          ]
        }
      >
        <Input />
      </Form.Item>
    </Form>
  </Modal>
);

export default NovoGrupoChecklist;