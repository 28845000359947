export const formatParams = (params) => {
  const {
    initialDate,
    typePeriod,
    idCategory,
    avaliations,
    idCompanies,
    idUserCreation,
    idUserResponsible,
    idProfile,
    ticketsStatus,
    idsClassifications,
    search,
    limit,
  } = params;

  const filteredKeys = Object.keys(params).filter(
    (key) => key.includes('_') && params[key] !== undefined
  );

  const idsClassificationsItens = filteredKeys.reduce(
    (acc, key) => acc.concat(params[key]),
    []
  );

  const initialDateParam = initialDate ? `initialDate=${initialDate}` : '';
  const typePeriodParam = typePeriod ? `typePeriod=${typePeriod}` : '';
  const categoriesParam = idCategory ? `categories=${idCategory.join(' ')}` : '';
  const companiesParam = idCompanies ? `companies=${idCompanies.join(' ')}` : '';
  const creationUsersParam = idUserCreation
    ? `creationUsers=${idUserCreation.join(' ')}`
    : '';
  const assignedUsersParam = idUserResponsible
    ? `assignedUsers=${idUserResponsible.join(' ')}`
    : '';
  const profilesParam = idProfile
    ? `profiles=${idProfile.join(' ')}`
    : '';
  const statusParam = ticketsStatus
    ? `ticketsStatus=${ticketsStatus.join(' ')}`
    : '';
  const classificationsParam = idsClassifications
    ? `classifications=${idsClassifications.join(' ')}`
    : '';
  const classificationsOptionsParam = idsClassificationsItens.length
    ? `classificationsOptions=${idsClassificationsItens.join(' ')}`
    : '';
  const searchParam = search ? `search=${search}` : '';
  const limitParam = limit ? `limit=${limit}` : '';
  const avaliationParam = avaliations ? `avaliations=${avaliations.join(' ')}` : '';

  return [
    initialDateParam,
    typePeriodParam,
    categoriesParam,
    companiesParam,
    avaliationParam,
    creationUsersParam,
    assignedUsersParam,
    profilesParam,
    statusParam,
    classificationsParam,
    classificationsOptionsParam,
    searchParam,
    limitParam,
  ]
    .filter((param) => param)
    .join('&');
};
