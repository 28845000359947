import React from 'react';
import { View } from '@components';
import { Form, Input, Radio, Checkbox, Upload, DatePicker } from '@components-teammove';
import { authorization } from '@sdk/api';
import { InfoUsuario, download, getValueFromFile } from '@utils';
import { UploadIcon, UploadText, CloseIcon } from './style';
import moment from 'moment';

const { REACT_APP_API } = window.env;

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const DateInput = ({ answer, form }) => {
  const onChange = (date, dateString) => {
    form.setFieldsValue({ [answer.id]: date });
  };

  return (
    <DatePicker
      format={'DD/MM/YYYY'}
      defaultValue={answer.resposta ? moment(answer.resposta) : null}
      onChange={onChange}
      inputReadOnly
      allowClear
      clearIcon={<CloseIcon type='x'/>}
    />
  );
};

export const convertArrayToFormObject = (arr) => {
  let object = {};
  for (let interator = 0; interator < arr.length; interator++) {
    switch(arr[interator].pergunta.tipo) {
      case TYPE_ASK.ESCOLHA_UNICA:
        object[arr[interator].id] = arr[interator].resposta ? parseInt(arr[interator].resposta) : null;
        break;
      case TYPE_ASK.CAIXA_SELECAO:
        object[arr[interator].id] = arr[interator].resposta ? arr[interator].resposta.split(',').map((item) => parseInt(item)) : null;
        object[arr[interator].id * -1]  = arr[interator].resposta ? true : null;
        break;
      case TYPE_ASK.ANEXO:
        object[arr[interator].id] = arr[interator].resposta ? arr[interator].resposta?.split(',').map((file) => ({ url: file, name: file.substring(file.lastIndexOf('/') + 1), status: 'done', uid: file })) : null;
        break;
      default:
        object[arr[interator].id] = arr[interator].resposta;
        break;
    }
  }
  return object;
};

export const TYPE_ASK = {
  TEXTO: 'TEXTO',
  ESCOLHA_UNICA: 'ESCOLHA_UNICA',
  CAIXA_SELECAO: 'CAIXA_SELECAO',
  ANEXO: 'ANEXO',
  DATA: 'DATA',
  NUMERICO: 'NUMERICO',
  PARAGRAFO: 'PARAGRAFO'
};

export const handleRenderAsk = (answer, index, form, isBriefingCompleted, isUsuarioCriador, setBlockingSave, tipo) => {

  const handleChangeAttachment = (info, answer) => {
    setBlockingSave(true);
    if (info.file.status === 'done') {
      setBlockingSave(false);
      const files = [];
      for (const file of info.fileList) {
        files.push(file);
      }
      form.setFieldsValue({ [answer]: files });
    } else if(info.file.status === 'removed') {
      setBlockingSave(false);
    }
  };

  const numberAsk = index + 1;

  switch(answer.pergunta.tipo) {
    case TYPE_ASK.TEXTO:
      return (
        <Form.Item key={answer.id} label={numberAsk + ' - ' + answer.pergunta.titulo} name={answer.id} rules={[{ required: answer.pergunta.obrigatorio, message: 'Responda a pergunta' }]}>
          <Input />
        </Form.Item>);
    case TYPE_ASK.DATA:
      return (
        <Form.Item key={answer.id} label={numberAsk + ' - ' + answer.pergunta.titulo} name={answer.id} rules={[{ required: answer.pergunta.obrigatorio, message: 'Responda a pergunta' }]}>
          <DateInput answer={answer} form={form} />
        </Form.Item>
      );
    case TYPE_ASK.NUMERICO:
      return (
        <Form.Item
          key={answer.id}
          label={numberAsk + ' - ' + answer.pergunta.titulo}
          name={answer.id}
          rules={[
            {
              required: answer.pergunta.obrigatorio,
              message: 'Responda a pergunta',
            },
            {
              validator: (rule, value) => {
                const isValidNumber = /^[0-9]+(,[0-9]+)?$/.test(value);
                if (!isValidNumber && answer.pergunta.obrigatorio) {
                  return Promise.reject('Insira um número válido');
                }       
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder='00' />
        </Form.Item>
      );
    case TYPE_ASK.PARAGRAFO:
      return (
        <Form.Item key={answer.id} label={numberAsk + ' - ' + answer.pergunta.titulo} name={answer.id} rules={[{ required: answer.pergunta.obrigatorio, message: 'Responda a pergunta' }]}>
          <Input.TextArea />
        </Form.Item>
      );
    case TYPE_ASK.ANEXO:
      return (
        <>
          <Form.Item label={numberAsk + ' - ' + answer.pergunta.titulo} name={answer.id} rules={[{ required: answer.pergunta.obrigatorio, message: 'Responda a pergunta' }]} valuePropName='fileList' getValueFromEvent={getValueFromFile}>
            <Upload.Dragger
              headers={authorization()}
              folder={`Chamados/${InfoUsuario.get('cdCliente')}`}
              URI={REACT_APP_API}
              onDownload={(file) => download(file.response)}
              onChange={(info) => handleChangeAttachment(info, answer.id)}
              multiple
            >
              <UploadIcon type='upload'/>
              <UploadText>Clique ou arraste o arquivo para essa área para fazer upload</UploadText>
            </Upload.Dragger>
          </Form.Item>
        </>
      );
    case TYPE_ASK.ESCOLHA_UNICA:
      return (
        <Form.Item key={answer.id} label={numberAsk + ' - ' + answer.pergunta.titulo} name={answer.id} rules={[{ required: answer.pergunta.obrigatorio, message: 'Responda a pergunta' }]} >
          <Radio.Group>
            {answer.pergunta.opcoes.map((option) => (
              <Radio key={option.id} value={option.id}>
                {option.titulo}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      );
    case TYPE_ASK.CAIXA_SELECAO:
      return (
        <View key={answer.id}>
          <Form.Item label={numberAsk + ' - ' + answer.pergunta.titulo} name={answer.id} rules={[{ required: answer.pergunta.obrigatorio, message: 'Responda a pergunta' }]}>
            <Checkbox.Group>
              {answer.pergunta.opcoes.map((option) => (
                <Checkbox key={option.id} value={option.id}>{option.titulo}</Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </View>
      );
    default: return null;
  }
};