import { getUnidades } from '@sdk/Unidades';
import { TelasNomenclaturas, formatDate, formatDateTime, formatTime } from '@utils';
import pptxgen from 'pptxgenjs';

const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

export const initialFilters = [
  {
    type: 'RANGE',
    label: 'Período',
    name: 'period'
  },
  {
    type: 'SELECT',
    label: 'Tipo de Atividade',
    name: 'typesActivities',
    placeholder: 'Selecione os Tipos de Atividade',
    options: [],
  },
  {
    type: 'SELECT',
    label: 'Usuários',
    name: 'users',
    placeholder: 'Selecione os Usuários',
    options: [],
  },
  {
    type: 'SELECT',
    label: `Tipo de ${companyNomenclature.nomenclatura}`,
    name: 'typesCompanies',
    placeholder: `Selecione os Tipos de ${companyNomenclature.plural}`,
    options: [],
  },
  {
    type: 'SELECT',
    label: 'Agrupador',
    name: 'agrupador',
    placeholder: 'Selecione os agrupadores',
    options: []
  }, 
  {
    type: 'SELECT',
    label: `${companyNomenclature.nomenclatura}`,
    name: 'companies',
    placeholder: `Selecione ${companyNomenclature.artigo}s ${companyNomenclature.plural}`,
    action: getUnidades
  },
];

async function urlToBase64(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        // Remove EXIF data
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const base64WithoutExif = canvas.toDataURL('image/jpeg');
          resolve(base64WithoutExif);
        };
        img.src = URL.createObjectURL(new Blob([xhr.response]));
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = function (error) {
      reject(error);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

const findImageScaleFromBase64 = (base64Data) => {
  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      const resolution = {
        width: image.width,
        height: image.height,
      };
      resolve(resolution);
    };

    image.src = `${base64Data}`;
  });
};

export async function convertUrlsToBase64(data, setLoading) {
  for (const powerPoint of data) {
    powerPoint.logoEmpresa = await urlToBase64(powerPoint?.logoEmpresa);
    for (const foto of powerPoint.fotos) {
      foto.url = await urlToBase64(foto?.url);
    }
  }
  setLoading(false);
}

export const exportToPPTX = async (powerPointToBase64,setLoading) => {
  setLoading(true);
  const pptx = new pptxgen();

  for (const data of powerPointToBase64) {
    const resolution = await findImageScaleFromBase64(data?.logoEmpresa);
    let slide = pptx.addSlide();
    const heightLogo = resolution.height / resolution.width;
    
    // eslint-disable-next-line id-length
    slide.addImage({ path: `${data?.logoEmpresa}`, w:1, h:heightLogo, y: 0.35, x: 0.2 });
    
    slide.addText(`${data.nomeReduzido}`, { fontFace: 'Arial', x: 1.3, y: 0.4, width: 3, color: '000000', fontSize: 12, bold: true });
    slide.addText(`${data.nomeEmpresa}`, { fontFace: 'Arial', x: 1.3, y: 0.60, width: 3, color: '000000', fontSize: 12, bold: true });
    slide.addText(`CNPJ: ${data.cnpj}`, { fontFace: 'Arial', x: 1.3, y: 0.85, width: 3, color: '000000', fontSize: 12, bold: true });
    slide.addText(`${data.endereco}`, { fontFace: 'Arial', x: 1.3, y: 1.05, width: 3, color: '000000', fontSize: 8 });
    slide.addText(`${data.bairro} - ${data.cidade} - ${data.estado}`, { fontFace: 'Arial', x: 1.3, y: 1.3, width: 3, color: '000000', fontSize: 8 });

    slide.addText(`${data.nomeTarefa}`, { fontFace: 'Arial', x: 7.0, y: 0.60, width: 3, color: '000000', fontSize: 10, bold: true });
    slide.addText(`Por ${data.nomeUsuario}`, { fontFace: 'Arial', x: 7.0, y: 0.85, width: 3, color: '000000', fontSize: 8 });
    slide.addText(`em ${formatDate(data.dataFinalizacaoPrevista)} as ${formatTime(data.dataFinalizacaoPrevista)}.`, { fontFace: 'Arial', x: 7.0, y: 1.00, width: 3, color: '000000', fontSize: 8 });

    const beforeImgs = data.fotos.filter((foto) => foto.tag === 'foto_antes').slice(0, 4);
    const laterImgs = data.fotos.filter((foto) => foto.tag === 'foto_depois').slice(0, 4);
      
    let axisX = 0, axisY = 0;

    for (let index = 0; index < beforeImgs.length; index++) {
      let beforeWidth = 0;
      let beforeHeight = 0;
      const resolution = await findImageScaleFromBase64(beforeImgs[index]?.url);

      if(resolution.height > resolution.width) {
        beforeWidth = beforeImgs.length === 1 ? ((resolution.width * 3.7) / resolution.height) : ((resolution.width * 1.8) / resolution.height);
        beforeHeight = beforeImgs.length === 1 ? 3.7 : 1.8;
      } else {
        beforeWidth = beforeImgs.length === 1 ? 4.2 : 2.1;
        beforeHeight = beforeImgs.length === 1 ? ((resolution.height * 4.1) / resolution.width ) : ((resolution.height * 2.1) / resolution.width);
      }

      if(beforeImgs.length === 1) {
        axisX = 0.4;
        axisY = 1.7;
      } else {
        if (index === 0) {
          axisX = 0.4;
          axisY = 1.75;
        }else if(index === 1) {
          axisX = 0.4;
          axisY = 3.6;
        }else if (index === 2) {
          axisX = 2.6; 
          axisY = 1.75;
        }else if (index === 3) {
          axisX = 2.6; 
          axisY = 3.6;
        }
      }
      
      if(index === 0) {
        slide.addText(`${beforeImgs[index].pergunta}`, { fontFace: 'Arial', x: 0.5, y: 1.7, width: 3, color: '000000', fontSize: 8, bold: true });
      }
      slide.addText(`${formatDateTime(beforeImgs[index].dataHora)}`, { fontFace: 'Arial', x: 2.0, y: 1.7, width: 3, color: '000000', fontSize: 8, bold: true });
      // eslint-disable-next-line id-length
      slide.addImage({ path: `${beforeImgs[index]?.url}`, h: beforeHeight, w: beforeWidth , y: axisY + 0.1, x: axisX + 0.1 });
    }
    
    for (let index = 0; index < laterImgs.length; index++) {  
      let laterWidth = 0;
      let laterHeight = 0;
      const resolution = await findImageScaleFromBase64(laterImgs[index]?.url);

      if(resolution.height > resolution.width) {
        laterWidth = laterImgs.length === 1 ? ((resolution.width * 3.7) / resolution.height) : ((resolution.width * 1.8) / resolution.height);
        laterHeight = laterImgs.length === 1 ? 3.7 : 1.8;
      } else {
        laterWidth = laterImgs.length === 1 ? 4.2 : 2.1;
        laterHeight = laterImgs.length === 1 ? ((resolution.height * 4.1) / resolution.width ) : ((resolution.height * 2.1) / resolution.width);
      }

      if(laterImgs.length === 1) {
        axisX = 5.3;
        axisY = 1.7;
      }else{
        if(index === 0 ) {
          axisX = 5.3;
          axisY = 1.75;
        }else if(index === 1) {
          axisX = 5.3;
          axisY = 3.6;
        }else if(index === 2) {
          axisX = 7.5;
          axisY = 1.75;
        }else if(index === 3) {
          axisX = 7.5;
          axisY = 3.6;
        }
      }

      if(index === 0) {
        slide.addText(`${laterImgs[index].pergunta}`, { fontFace: 'Arial', x: 5.4, y: 1.7, width: 3, color: '000000', fontSize: 8, bold: true });
      }
    
      slide.addText(`${formatDateTime(laterImgs[index].dataHora)}`, { fontFace: 'Arial', x: 6.9, y: 1.7, width: 3, color: '000000', fontSize: 8, bold: true });
      // eslint-disable-next-line id-length
      slide.addImage({ path: `${laterImgs[index]?.url}`, h: laterHeight, w: laterWidth, y: axisY + 0.1, x: axisX + 0.1 });
        
    }
  }
 
  pptx.writeFile({
    fileName: 'modelo_de_apresentacao',
    returnBlob: true,
  }).then(()=> setLoading(false)); 
  
};