import React from 'react';
import { Modal, Text } from '@components-teammove';
import { formatDecimal } from '@utils';
import { TableRanking } from './styles';

function AllFamiliesModal({ visible, onClose, allFamilies }) {
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      render: (text, row) => {
        return <Text strong>{text}</Text>;
      },
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      render: (text, row) => {
        return <Text>{text.toFixed(0)}</Text>;
      }
    },
    {
      title: 'Porcentagem',
      dataIndex: 'quantityPercent',
      render: (text, row) => {
        return <Text>{`${formatDecimal(text, 2)}%`}</Text>;
      },
    },
  ].filter((column) => !column.hidden);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      okText='Fechar'
      onOk={onClose}
      title={allFamilies.name}
      numberOfButtons={1}
    >
      <TableRanking
        columns={columns}
        dataSource={allFamilies.families}
        hidePagination
        rowKey='id'
        status='active'
      />
    </Modal>
  );
}

export default AllFamiliesModal;