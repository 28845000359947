import React from 'react';
import { IconStar } from './styles';
import { getUnidades } from '@sdk/Unidades';
import { TelasNomenclaturas } from '@utils';

const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

export const getDefaultFilter = () => {
  return { 
    descFiltros: { meusTickets: true }, 
    meusTickets: true,
  };
};

export const STATUS_AGUARDANDO_RESPOSTA = {
  ATRASADO: 'ATRASADO',
  ABERTO: 'ABERTO',
};

export const STATUS_FECHADO = {
  NO_PRAZO: 'NO_PRAZO',
  ATRASADO: 'ATRASADO',
};

export const FILTER_AWAITING_EVALUATION = (usuario) => ({
  descFiltros: { meusTickets: true, status:['Aguardando Avaliação'], usuarioCriacao: [usuario.nmUsuario] },
  dataCriacao: [],
  usuarioCriacao: [usuario.cdUsuario],
  unidade: [],
  categoria: [],
  responsavel: [],
  status: ['AA'],
  avaliacao: [],
  meusTickets: true,
});

export const initialFilters = [
  {
    type: 'RANGE',
    label: 'Data Criação',
    name: 'dataCriacao'
  },
  {
    type: 'SELECT',
    label: 'Usuário Criação',
    name: 'usuarioCriacao',
    placeholder: 'Selecione os Usuários',
    options: [],
  },
  {
    type: 'SELECT',
    label: `${companyNomenclature.nomenclatura}`,
    name: 'unidade',
    placeholder: `Selecione ${companyNomenclature.artigo}s ${companyNomenclature.plural}`,
    optionLabelProp: 'title',
    action: getUnidades,
  },
  {
    type: 'TREE_SELECT',
    label: 'Departamento/Categoria',
    name: 'categoria',
    placeholder: 'Selecione os Departamentos / Categorias',
    options: [],
    switch: true,
  },
  {
    type: 'SELECT',
    label: 'Responsável',
    name: 'responsavel',
    placeholder: 'Selecione os Responsáveis',
    options: [],
  },
  {
    type: 'SELECT',
    label: 'Perfil de Usuário',
    name: 'perfil',
    placeholder: 'Selecione os Perfis dos Responsáveis',
    options: [],
  },
  {
    type: 'SELECT',
    label: 'Status',
    name: 'status',
    placeholder: 'Selecione os Status',
    options: [
      { value:'AB', label: 'Aberto' },
      { value:'EA', label: 'Em Atendimento' },
      { value:'CO', label: 'Concluído' },
      { value:'FE', label: 'Fechado' },
      { value:'AA', label: 'Aguardando Avaliação' },
      { value:'RE', label: 'Reaberto' },
      { value:'CA', label: 'Cancelado' },
      { value:'AR', label: 'Aguardando Pendência' },
      { value:'BR', label: 'Aguardando Briefing' },
    ],
    dependentFields: [
      'fechadoNoPrazo', 'fechadoForaPrazo'
    ]
  },
  {
    type: 'SELECT',
    label: 'Avaliação',
    name: 'avaliacao',
    placeholder: 'Selecione as Avaliações',
    options: [
      { value: '1', title: '1', label: (
        <>
          {new Array(parseInt(1)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'1' + index}/>
          ))}
        </>
      ) },
      { value: '2', title: '2', label: (
        <>
          {new Array(parseInt(2)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'2' + index}/>
          ))}
        </>
      ) },
      { value: '3', title: '3', label: (
        <>
          {new Array(parseInt(3)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'3' + index}/>
          ))}
        </>
      ) },
      { value: '4', title: '4', label: (
        <>
          {new Array(parseInt(4)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'4' + index}/>
          ))}
        </>
      ) },
      { value: '5', title: '5', label: (
        <>
          {new Array(parseInt(5)).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconStar key={'5' + index}/>
          ))}
        </>
      ) },
    ],
  },
  {
    type: 'CHECKBOX',
    label: 'Meus Tickets',
    name: 'meusTickets'
  },
  {
    type: 'CHECKBOX',
    label: 'Fechados No Prazo',
    name: 'fechadoNoPrazo'
  },
  {
    type: 'CHECKBOX',
    label: 'Fechados Fora do Prazo',
    name: 'fechadoForaPrazo'
  }
];

export const STATUS = {
  ABERTO: 'ABERTO',
  EM_ATENDIMENTO: 'EM_ATENDIMENTO',
  CONCLUIDO: 'CONCLUIDO',
  CANCELADO: 'CANCELADO',
  FECHADO: 'FECHADO',
  REABERTO: 'REABERTO',
  AGUARDANDO_AVALIACAO: 'AGUARDANDO_AVALIACAO',
  AGUARDANDO_BRIEFING: 'AGUARDANDO_BRIEFING'
};