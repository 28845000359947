import styled from 'styled-components';
import { Icon, Column } from '@components-teammove';

export const Row = styled.div`
  display: flex;
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
  ${({ justify }) => justify && `
    justify-content: ${justify};
  `}
  ${({ align }) => align && `
    align-items: ${align};
  `}
`;

export const Container = styled(Column)`
  gap: 40px;
  
  @media screen and (min-width: 1000px) {
    max-width: 80%;
    margin: auto;
  }
`;

export const ChecklistProgressSummaryTitle = styled.span`
  font-size: 32px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  padding: 0 1.5rem;
`;

export const ContentTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const ContentContainer = styled(Column)`
  border-radius: 28px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 28px;
  align-items: center;
`;

export const ProgressPercentage = styled.span`
  font-size: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const GroupTitleColumn = styled(Column)`
  padding: 0 3rem;
  cursor: pointer;
`;

export const GroupTitleRow = styled(Row)`
  gap: 14px;
  align-items: center;
`;

export const AnsweredOfTotal = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

export const GroupContentContainer = styled(ContentContainer)`
  padding: 48px;
`;

export const QuestionContainer = styled(Row)`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ answered }) => answered ? 'var(--success-color)' : 'var(--warning-color)'};
  justify-content: space-between;
  width: ${({ isConditional }) => isConditional ? 'calc(100% - 22px)' : '100%'};
  margin-left: ${({ isConditional }) => isConditional ? '22px' : '0'};
  margin-top: ${({ isFirstIndex, renderConditionalTag }) => !isFirstIndex && !renderConditionalTag ? '20px' : '0'};
  margin-bottom: ${({ nextQuestionShouldRenderConditionalTag }) => nextQuestionShouldRenderConditionalTag ? '20px' : '0'};
  cursor: pointer;
`;

export const QuestionText = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const AnsweredIcon = styled(Icon.MDI)`
  color: var(--success-color);
`;

export const WarningIcon = styled(Icon.Feather)`
  color: var(--warning-color);
`;

export const StarIcon = styled(Icon.Bootstrap)`
  color: var(--success-color);
  width: 44px;
  height: 44px;
`;

export const ConditionalContainerQuestion = styled.div`
  border-radius: 4px;
  padding: 3px 8px;
  background-color: ${({ theme }) => theme.darkHighlightColor};
  width: max-content;
  color: ${({ theme }) => theme.textColor};
  margin-bottom: 8px;
  ${({ fromFinished }) => fromFinished && `
    margin-top: 12px;
  `}
`;

export const AbsoluteDiv = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.darkHighlightColor};
  width: 5px;
  height: 100%;
  ${({ fromFinished }) => fromFinished && `
    margin-top: 12px;
  `}
`;

export const ColumnContainer = styled(Column)`
  position: relative;
`;