export const optionTypeActivity = [
  { value: 'ESCOLHA_UNICA', label: 'Escolha única' },
  { value: 'MULTIPLA_ESCOLHA', label: 'Caixa de seleção' },
  { value: 'TEXTO', label: 'Texto curto' },
  { value: 'TEXTO_PARAGRAFO', label: 'Parágrafo' },
  { value: 'DATA', label: 'Data' },
  { value: 'HORARIO', label: 'Horário' },
  { value: 'NUMERICO', label: 'Numérico' },
  { value: 'ANEXO', label: 'Anexo' },
];

export const optionLastAnswer = [
  { value: 'S', label: 'Sim' },
  { value: 'N', label: 'Não' },
];

export const optionsSelectInOptions = [
  { value: 'PERMITE', label: 'Sim' },
  { value: 'NAO_PERMITE', label: 'Não' },
  { value: 'OPCIONAL', label: 'Opcional' },
];