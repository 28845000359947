import React from 'react';

export const getActionTypeFromMessage = (message) => {
  if (message.toLowerCase().startsWith('status alterado para')) {
    if (message.toLowerCase().includes('aguardando avaliação')) {
      return ACTION.CHANGE_STATUS_TO_AWAITING_EVALUATION;
    } else if (message.toLowerCase().includes('reaberto')) {
      return ACTION.CHANGE_STATUS_TO_REOPENED;
    } else if (message.toLowerCase().includes('fechado')) {
      return ACTION.CHANGE_STATUS_TO_CLOSED;
    } else if (message.toLowerCase().includes('em atendimento')) {
      return ACTION.CHANGE_STATUS_TO_IN_ATTENDANCE;
    } else if (message.toLowerCase().includes('aberto')) {
      return ACTION.CHANGE_STATUS_TO_OPEN;
    } else if (message.toLowerCase().includes('cancelado')) {
      return ACTION.CHANGE_STATUS_TO_CANCEL;
    }
  } else if (message.toLowerCase().startsWith('atendimento alterado de')) {
    return ACTION.CHANGE_RESPONSIBLE;
  } else if (message.toLowerCase().startsWith('categoria alterada de')) {
    return ACTION.CHANGE_CATEGORY;
  } else if (message.toLowerCase().startsWith('prazo alterado de')) {
    return ACTION.CHANGE_TIME;
  } else if (message.toLowerCase().startsWith('atendimento aguardando resposta')) {
    return ACTION.WAITING_FOR_ANSWER;
  } else if (message.toLowerCase().startsWith('resposta:')) {
    return ACTION.RESPONSE_WAITING_FOR_ANSWER;
  } else if (message.toLowerCase().startsWith('briefing concluído em')) {
    return ACTION.RESPONSE_BRIEFING; 
  } else if (message.toLowerCase().startsWith('assunto alterado de')) {
    return ACTION.CHANGE_TITLE; 
  } else if (message.toLowerCase().startsWith('criada a etapa')) {
    return ACTION.CHANGE_STEP;
  } else if (message.toLowerCase().includes('finalizada')) {
    return ACTION.CHANGE_FINISHING_STEP;
  }
  return null;
};

export const getLabelStatusToOpen = (historic) => {
  const { mensagem, nmUsuario } = historic;
  const indexOfWord = mensagem.indexOf('por');
  if (indexOfWord > -1) {
    const creator = mensagem.substring(indexOfWord + 4, mensagem.lenght);
    return `${creator} <b>abriu</b> o ticket por ${nmUsuario}`;
  } else {
    return `${nmUsuario} <b>abriu</b> o ticket`;
  }
};

export const getIconChangeResponsible = (historic) => {
  return historic.mensagem.includes('null') ? 'account-plus' : 'account-switch' ;
};

export const getLabelChangeResponsible = (historic) => {
  const { mensagem, nmUsuario } = historic;
  const oldResponsible = mensagem.substring(mensagem.lastIndexOf('de') + 3, mensagem.lastIndexOf('para') - 1);
  const newResponsible = mensagem.substring(mensagem.lastIndexOf('para') + 5, mensagem.lenght);
  
  return oldResponsible === 'null' ? `${nmUsuario} <b>adicionou</b> ${newResponsible} como responsável` : `${nmUsuario} <b>alterou</b> o responsável de ${oldResponsible} para ${newResponsible}`;
};

export const getLabelChangeCategory = (historic) => {
  const { mensagem, nmUsuario } = historic;
  const oldCategory = mensagem.substring(mensagem.indexOf(' de ') + 3, mensagem.lastIndexOf('para') - 1);
  const newCategory = mensagem.substring(mensagem.lastIndexOf('para') + 5, mensagem.lenght);

  return `${nmUsuario} <b>alterou</b> a categoria de ${oldCategory} para ${newCategory}`;
};

export const getLabelChangeTime = (historic) => {
  const { mensagem } = historic;
  const newTime = mensagem.substring(mensagem.lastIndexOf('para') + 5, mensagem.lenght);
  return `Novo prazo: <b>${newTime}</b>`;
};

export const getLabelDetailsWaitingAnswer = (historic) => {
  const { mensagem } = historic;
  const reason = mensagem.substring(mensagem.indexOf('Motivo') + 7, mensagem.length);
  return(
    <div style={{ minWidth: '320px', minHeight: '58px', padding: '15px', borderRadius: '12px', backgroundColor: '#FFCC00', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#2A2837' }}>
      <strong style={{ fontSize: '18px', fontWeight: '700' }}>Motivo:&nbsp;</strong>
      <p style={{ fontSize: '18px', fontWeight: '400', marginBottom: 0 }}>{reason}</p>
    </div>
  );
};

export const getLabelDetailsResponseWaitingAnswer = (historic) => {
  const { mensagem } = historic;
  const answer = mensagem.substring(mensagem.indexOf('Resposta') + 9, mensagem.length);
  return(
    <div style={{ minWidth: '320px', minHeight: '58px', padding: '15px', borderRadius: '12px', backgroundColor: '#34C759', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#2A2837' }}>
      <strong style={{ fontSize: '18px', fontWeight: '700' }}>Resposta:&nbsp;</strong>
      <p style={{ fontSize: '18px', fontWeight: '400', marginBottom: 0 }}>{answer}</p>
    </div>
  );
};

export const getLabelDetailsResponseCancelAnswer = (historic) => {
  const { mensagem } = historic;
  const answer = mensagem.substring(mensagem.indexOf('Resposta') + 9, mensagem.length);
  return(
    <div style={{ minWidth: '320px', minHeight: '58px', padding: '15px', borderRadius: '12px', backgroundColor: '#FF3B30', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#2A2837' }}>
      <strong style={{ fontSize: '18px', fontWeight: '700' }}>Motivo:&nbsp;</strong>
      <p style={{ fontSize: '18px', fontWeight: '400', marginBottom: 0 }}>{answer}</p>
    </div>
  );
};

export const ACTION = {
  CHANGE_STATUS_TO_OPEN: 'CHANGE_STATUS_TO_OPEN',
  CHANGE_STATUS_TO_IN_ATTENDANCE: 'CHANGE_STATUS_TO_IN_ATTENDANCE',
  CHANGE_STATUS_TO_CLOSED: 'CHANGE_STATUS_TO_CLOSED',
  CHANGE_STATUS_TO_REOPENED: 'CHANGE_STATUS_TO_REOPENED',
  CHANGE_STATUS_TO_AWAITING_EVALUATION: 'CHANGE_STATUS_TO_AWAITING_EVALUATION',
  CHANGE_STATUS_TO_CANCEL: 'CHANGE_STATUS_TO_CANCEL',
  CHANGE_RESPONSIBLE: 'CHANGE_RESPONSIBLE',
  CHANGE_CATEGORY: 'CHANGE_CATEGORY',
  CHANGE_TIME: 'CHANGE_TIME',
  WAITING_FOR_ANSWER: 'WAITING_FOR_ANSWER',
  RESPONSE_WAITING_FOR_ANSWER: 'RESPONSE_WAITING_FOR_ANSWER',
  RESPONSE_BRIEFING: 'RESPONSE_BRIEFING',
  CHANGE_TITLE: 'CHANGE_TITLE',
  CHANGE_STEP: 'CHANGE_STEP',
  CHANGE_FINISHING_STEP: 'CHANGE_FINISHING_STEP'
};