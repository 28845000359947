export const getQueryParamsChecklistTemplate = (params) => {
  if (!params) return '';
  
  const query = [];
  const { search, limit, offset, orderBy, allTemplates } = params;

  if ((search) && (search.length > 0)) query.push(`search=${search}`);
  if (!allTemplates) query.push('status=LIBERADO EM_CADASTRO');
  if (limit) query.push(`limit=${limit}`);
  if (offset) query.push(`offset=${offset}`);
  if ((orderBy && orderBy.order) && (orderBy.order.length > 0)) query.push(`orderByDirection=${orderBy.order}`);
  if ((orderBy && orderBy.field) && (orderBy.field.length > 0)) query.push(`orderByField=${orderBy.field}`);

  if (query.length === 0) return '';
  return '&' + query.join('&');
};