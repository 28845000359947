import styled, { css } from 'styled-components';
import { Text, List, Column } from '@components-teammove';

export const TextStyled = styled(Text)`
    width: ${({ date }) => date ? 'auto' : '100%'};
    font-size: ${({ type, body }) => type || body ? '12px' : '16px'};
    opacity: ${({ type, date }) => type || date ? '0.5' : '1'};
    font-weight: ${({ title }) => title ? '700' : '400'};
    text-transform: ${({ type }) => type ? 'capitalize' : 'none'};
`;

export const ListItem = styled(List.Item)`
  padding: 6px;
  .ant-list-item-action {
    margin-left: 0px;
  }
  background-color: ${({ theme, lida }) => lida ? theme.darkPrimaryColor : theme.darkHighlightColor};
  max-width: 300px;
`;

export const DadosView = styled(Column)`
  ${(props) =>
  props.lida &&
    css`
      background-color: ${({ theme }) => theme.transparent};
    `}
`;