export const getQueryParamsUnidadesAdimplencia = (params) => {
  if (!params) return '';
  
  const query = [];
  const { delinquencyStatus, search, companyGroups, companyKinds, states, limit, offset, orderBy } = params;

  if ((delinquencyStatus) && (delinquencyStatus.length > 0)) query.push(`delinquencyStatus=${delinquencyStatus}`);
  if ((search) && (search.length > 0)) query.push(`search=${search}`);
  if ((companyGroups) && (companyGroups.length > 0)) query.push(`companyGroups=${companyGroups.join(' ')}`);
  if((companyKinds) && (companyKinds.length > 0)) query.push(`companyKinds=${companyKinds.join(' ')}`);
  if ((states) && (states.length > 0)) query.push(`states=${states.join(' ')}`);
  if (limit) query.push(`limit=${limit}`);
  if (offset) query.push(`offset=${offset}`);
  if ((orderBy && orderBy.order) && (orderBy.order.length > 0)) query.push(`orderByDirection=${orderBy.order}`);
  if ((orderBy && orderBy.field) && (orderBy.field.length > 0)) query.push(`orderByField=${orderBy.field}`);

  if (query.length === 0) return '';
  return '&' + query.join('&');
};