import { saveAs } from 'file-saver';

const optionsFetchDownload = {
  method: 'GET',
  mode: 'cors',
  cache: 'no-cache',
  headers: {
    Origin: window.location.origin,
  }
};

export const getValueFromFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const download = (uri, incomingFileName = '') => {
  const lastSlashPosition = uri.lastIndexOf('/');
  const fileName = incomingFileName || uri.substring(lastSlashPosition + 1);

  return fetch(uri, optionsFetchDownload)
    .then((response) => {
      return response.blob();
    }).then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName || 'file');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const downloadBlobAsFile = (blob, fileName = '') => {
  const objectUrl = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');

  downloadLink.href = objectUrl;
  downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  
  downloadLink.click();
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(objectUrl); // Cleanup the object URL
};

export const downloadBlob = (uri, name) => {
  saveAs(uri, name);
};

export const isImage = (url) => {
  return [
    'png',
    'jpg',
    'jpeg',
    'bmp',
  ].includes(url.substring(url.lastIndexOf('.') + 1, url.length).toLowerCase());
};

export const isAudio = (url) => {
  return [
    'm4a',
    'flac',
    'wav',
    'wma',
    'mp3',
    'aac',
    'ogx',
    'opus'
  ].includes(url.substring(url.lastIndexOf('.') + 1, url.length).toLowerCase());
};

export async function getFileFromUrl(url, name, defaultType = 'application/pdf') {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export const getDataFromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

export const getTypeByFileExtension = (extension) => {
  switch (extension) {
    case 'mp4':
      return 'VIDEO';
    case 'pdf':
      return 'PDF';
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'bmp':
      return 'IMAGEM';
    case 'html':
      return 'HTML';
    case 'zip':
      return 'ZIP';
    default:
      return 'ARQUIVO';
  }
};