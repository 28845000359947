import React, { useState } from 'react';
import App from '@app';
import { Body, PageHeader, Column, Tabs, Breadcrumb, Button } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Session, Filtros } from '@utils';
import Templates from './Template';
import { FILTERS_KEY, getChecklistTemplates } from '@ducks/checklistTemplates';

export default function ConfiguracoesChecklists() {
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useSelector(({ app }) => app.get('theme'));
  
  const [currentTab, setCurrentTab] = useState(Session.get('CONFIG_CHECKLISTS_TAB') || 'Templates');

  const [filtros, setFiltros] = useState(Filtros.get(FILTERS_KEY));
  const initialLimit = 50;
  const [limit, setLimit] = useState(initialLimit);
  const [offset, setOffset] = useState(0); // TODO: use this for pagination
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState();

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set('CONFIG_CHECKLISTS_TAB', newTab);
  };

  const ExtraInadimplencia = () => [
    <Button 
      key="2" 
      type="primary" 
      size='small' 
      context='header' 
      onClick={() => history.push('/configuracoes/checklists/cadastro')}
    >
      Novo
    </Button>,
  ];

  const handleSearch = (filters, loaders) => {
    Filtros.set(FILTERS_KEY, filters);
    setFiltros(filters);
    setLimit(loaders.limit);
    setSearch(filters.search || '');
    setOrderBy(loaders.orderBy);

    dispatch(getChecklistTemplates({ 
      ...filters, 
      ...loaders 
    }));
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Checklists'
          extra={currentTab === 'Templates' && <ExtraInadimplencia/> }
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/checklists', name: 'Configurações de checklists', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs 
              options={[
                { name: 'Templates' }
              ]} 
              current={currentTab} 
              onChange={handleChangeTab}
            />
            {currentTab === 'Templates' && (
              <Templates
                filtros={filtros}
                search={search}
                limit={limit}
                offset={offset}
                orderBy={orderBy}
                handleSearch={handleSearch}
              />
            )}
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}
