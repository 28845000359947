import { get, post, put, del } from '../api';
import { InfoUsuario } from '@utils';
import { getParams } from './rules';

const useNodeApi = true;

export const getChecklistPreview = (idChecklist) => {
  return get(`/api/checklist/preview/${idChecklist}`, {}, useNodeApi);
};

export const getChecklistQuestionGroups = (idChecklist) => {
  return get(`/api/checklist/filling/${idChecklist}`, {}, useNodeApi);
};

export const getAnswersHistoric = (idChecklist, idQuestion) => {
  return get(`/api/activities/checklist/fill/ask/answer/historic/${idChecklist}/${idQuestion}`);
};

export const answerQuestion = (idChecklist, idQuestion, answers) => {
  return post(`/api/activities/checklist/fill/ask/${idChecklist}/${idQuestion}`, answers);
};

export const getChecklistFinishPreview = (idChecklist) => {
  return get(`/api/activities/checklist/preview/fill/${idChecklist}/${InfoUsuario.get('cdUsuario')}`);
};

export const updateChecklistFinishPreview = (idChecklist, newPreview) => {
  return put(`/api/activities/checklist/preview/fill/${idChecklist}`, newPreview);
};

export const deleteChecklistActionPlan = (idActionPlanActivity) => {
  return del(`/api/activities/checklist/preview/fill/actionPlanActivity/${idActionPlanActivity}`);
};

export const finishChecklist = (idChecklist, checklist) => {
  return post(`/api/activities/checklist/preview/fill/finish/${idChecklist}`, checklist);
};

export const getFinishedChecklist = (idChecklist, headers) => {
  return get(`/api/checklist/result/${idChecklist}`, headers, useNodeApi);
};

export const getChecklistUploads = (idChecklist) => {
  return get(`/api/activities/checklist/${idChecklist}/uploads`);
};

export const sendEmail = (idChecklist, params) => {
  return post(`/api/activities/checklist/send/email/${idChecklist}${getParams(params)}`, params.emails);
};