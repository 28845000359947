import styled from 'styled-components';
import { Icon, Button, Column, Text, Tag, Row } from '@components-teammove';

export const TextStyle = styled(Text)`
  &.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
  font-size: ${({ font })=> font && font};
  font-weight: ${({ weight })=> weight && weight};
  opacity: ${({ lowOpacity })=> lowOpacity ? '.5' : '1'};
  line-height: ${({ lineHeight })=> lineHeight ? lineHeight : '20px'};
`;

export const RowContainer = styled(Row)`
  max-width: 70%;
`;

export const MenuClosed = styled(Column)`
  position: fixed;
  overflow: auto;
  z-index: 1;
  background-color: ${({ theme })=> theme.darkBackgroundColor};
  top: 198px;
  right: 11px;
  height: 72%;
  border-left: 2px solid ${({ theme })=> theme.darkSecondaryColor};
  border-bottom: 2px solid ${({ theme })=> theme.darkSecondaryColor};
  padding: 20px 24px 20px 20px;
  gap: 20px;
`;

export const MenuOpen = styled(MenuClosed)`
  padding-left: 0px;
  width: 313px;
`;

export const Divider = styled.div`
  border-top: 2px solid ${({ theme })=> theme.darkSecondaryColor};
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme, help })=> help ? 'var(--super-success-color)' : theme.textColor};
  opacity: ${({ lowOpacity })=> lowOpacity ? '.5' : '1'};
  margin-left: ${({ marginLeft })=> marginLeft ? marginLeft : ''} !important;
  cursor: ${({ cursor })=> cursor ? cursor : ''} !important;
`;

export const ToolTipIcon = styled(FeatherIcon)`
  position: relative;
  top: 4px;
  left: 6px;
`;

export const DataIcons = styled(ToolTipIcon)`
  :hover{
    cursor: pointer;
  }
  min-width: 20px;
  align-self: flex-start;
`;

export const StyleButton = styled(Button)`
  &.ant-btn {
   :focus {
     background-color: ${({ theme })=> theme.darkSecondaryColor} !important;
    }
  } 
  height:${({ height })=> height && '48px' } !important;
  width: ${({ width })=> width ? width : '' } !important;
  background-color: ${({ theme, transparent })=> transparent && 'unset'} !important;
  border: ${({ transparent, theme })=> transparent && `1px solid ${theme.darkPrimaryColor}`} !important;
  padding: ${({ padding })=> padding ? padding : '' } !important;
`;

export const ContentDataGroup = styled(Column)`
  gap: 12px;
  padding: ${({ padding }) => padding ? padding : ''};
`;

export const EditButton = styled(Button)`
  padding: 0px !important;
`;

export const StyleRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const TextQuest = styled(Text)`
  font-size: 16px;
  font-weight: 700px;
  color: #fff;
`;

export const TagContainer = styled(Tag)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme })=> theme.textContrastColor};
`;