import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Column } from '@components-teammove';
import App from '@app';
import { InitConnection, tryCalling, InfoUsuario } from '@utils';
import { getConfigurationVoip } from '@ducks/configuracoesVoip';
import { getVoipConnection, resetCallError, postSaveCall } from '@ducks/voip';  
import { USER_STATE } from './Types';
import { HeaderStyled, LogoImage, Main } from './styles';
import PreparationCall from './PreparationCall';
import Call from './Call';

const Voip = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const number = urlParams.get('number');
  const name = urlParams.get('name');
  const CompanyId = urlParams.get('CompanyId');
  const ContactId = urlParams.get('ContactId');
  const activityId = urlParams.get('activityId');
  const unfinished = urlParams.get('unfinished');

  const [changeStatus, setChangeStatus] = useState(unfinished ? 'afterCall' : 'preparation');
  const [mediaStream, setMediaStream] = useState(null);

  const [info, setInfo] = useState({
    number,
    name,
    CompanyId,
    ContactId,
    activityId,
    unfinished,
  });
  const [callDevice, setCallDevice] = useState(null);
  const [connection, setConnection] = useState(null);
  const [userState, setUserState] = useState(USER_STATE.OFFLINE);
  const [warningText, setWarningText] = useState(null);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isConnectionReady, setIsConnectionReady] = useState(false);
  const [valuesForCall, setValuesForCall] = useState(null);

  const successConfigurationVoip = useSelector(({ configuracoesVoip }) => configuracoesVoip.get('successConfigurationVoip'));
  const configurationVoip = useSelector(({ configuracoesVoip }) => configuracoesVoip.get('configurationVoip'));
  const successPostSaveCall = useSelector(({ voip }) => voip.get('successPostSaveCall'));
  const callRecordingId = useSelector(({ voip }) => voip.get('callRecordingId'));

  const token = useSelector(({ voip }) => voip.get('token'));
  const successVoipConnection = useSelector(({ voip }) => voip.get('successVoipConnection'));
  const errorCall = useSelector(({ voip }) => voip.get('errorCall'));

  const theme = useSelector(({ app }) => app.get('theme'));

  useEffect(() => {
    dispatch(getConfigurationVoip());
    dispatch(getVoipConnection());
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        setMediaStream(stream);
        setDisabledButton(false);
      })
      .catch((err) => {
        console.error('sem microfone!', err);
        setDisabledButton(true);
      });
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
      if(errorCall) {
        dispatch(resetCallError());
      }
    };
  }, []);

  useEffect(() => {
    if(!successVoipConnection) return;
    InitConnection(token, setCallDevice, setUserState, setConnection, setIsConnectionReady);
  }, [successVoipConnection]);

  useEffect(() => {
    if(!successConfigurationVoip) return;
    setInfo({ ...info, ...configurationVoip });
  }, [successConfigurationVoip]);

  useEffect(() => {
    if(!successPostSaveCall && !valuesForCall) return;
    const phoneWithoutCountryNumber = valuesForCall.CallToNumber.replace('+55', '');
    let formattedNumber = '';
    formattedNumber = phoneWithoutCountryNumber.length > 10 ? 
    `(${phoneWithoutCountryNumber.slice(0, 2)}) ${phoneWithoutCountryNumber.slice(2, 7)}-${phoneWithoutCountryNumber.slice(7)}` : 
    `(${phoneWithoutCountryNumber.slice(0, 2)}) ${phoneWithoutCountryNumber.slice(2, 6)}-${phoneWithoutCountryNumber.slice(6)}`;
  
    setInfo({ ...info, number: formattedNumber });

    tryCalling(callDevice, setConnection, setUserState, userState, { ...valuesForCall, callRecordingId }, setWarningText, dispatch);

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const windowWidth = 581;
    const windowHeight = 830;
  
    const horizontal = (screenWidth - windowWidth) / 2;
    const vertical = (screenHeight - windowHeight) / 2;

    window.resizeTo(windowWidth, windowHeight);
    window.moveTo(horizontal, vertical);
    setChangeStatus('calling');
  }, [successPostSaveCall]);

  const handleClosePopUp = () => {
    window.close();
  };

  const handleOnCalling = (values) => {
    const params = { 
      CallToNumber: values, 
      UserId: InfoUsuario.get('cdUsuario'),
      VoiceCallConfigId: info.VoiceCallConfigId, 
      ActiveCallRecording: form.getFieldValue('ActiveCallRecord'), 
      ActiveCallTextTranscription: false,
      ActiveCallTextTranscriptionHighlights: false,
      ContactId: parseInt(info.ContactId, 10) || null, 
      CompanyId: parseInt(info.CompanyId, 10) || null,
      Classification: 'Erro',
      key: InfoUsuario.get('key'),
      token: InfoUsuario.get('token'),
      ActivityId: parseInt(info.activityId, 10) || null
    };

    dispatch(postSaveCall(params));
    setValuesForCall(params);
  };

  const finishCallConnection = () => {
    if(!connection) return;
    const callStatus = connection.status();
    if (callStatus === 'pending' || callStatus === 'ringing') {
      setUserState(USER_STATE.READY); 
      // connection.cancel();
    }
    connection.disconnect();
  };

  return (
    <App hideHeader hideSider hideFooter>
      <Column width='100%'>
        <HeaderStyled>
          <LogoImage alt="TeamMove" src={theme['@global-url-logo-header'] || process.env.PUBLIC_URL + '/images/logo.png'}/>
        </HeaderStyled>
        <Main>
          {changeStatus === 'preparation' ? (
            <PreparationCall info={info} form={form} onClose={handleClosePopUp} onCalling={(value) => handleOnCalling(value)} disabledButton={disabledButton} isConnectionReady={isConnectionReady}/>
          ) : (
            <Call info={info} mediaStream={mediaStream} changeStatus={changeStatus} setChangeStatus={setChangeStatus} userState={userState} finishCallConnection={finishCallConnection} connection={connection} warningText={warningText}/>
          )}
        </Main>
      </Column>
    </App>
  );
};

export default Voip;