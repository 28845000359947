import { Map } from 'immutable';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Notification } from '@utils';
import { importProducts as importProductsSdk } from '../../sdk/Produtos';

export const FILTERS_KEY = 'FILTROS/UNIDADES_ADIMPLENCIA';

//Action Types
export const Types = {
  UPLOAD_PRODUCTS: 'CONTEUDOS_CONFIGURACOES/UPLOAD_PRODUCTS',
  UPLOAD_PRODUCTS_SUCCESS: 'CONTEUDOS_CONFIGURACOES/UPLOAD_PRODUCTS_SUCCESS',
  UPLOAD_PRODUCTS_ERROR: 'CONTEUDOS_CONFIGURACOES/UPLOAD_PRODUCTS_ERROR',

  RESET: 'CONTEUDOS_CONFIGURACOES/RESET',
};

//Action Creators
export const uploadProducts = (params) => ({ type: Types.UPLOAD_PRODUCTS, params });
export const uploadProductsSuccess = (response) => ({ type: Types.UPLOAD_PRODUCTS_SUCCESS, response });
export const uploadProductsError = (error) => ({ type: Types.UPLOAD_PRODUCTS_ERROR, error });

export const reset = () => ({ type: Types.RESET });

function* fetchUploadProducts(action) {
  try {
    const { params } = action;
    const response = yield call(importProductsSdk, params);
    yield put(uploadProductsSuccess(response));
  } catch (err) {
    Notification.error('Erro na tabela!');
    yield put(uploadProductsError(err));
  }
}

export const saga = [
  takeLatest(Types.UPLOAD_PRODUCTS, fetchUploadProducts),
];

// Reducer
const initialState = Map({
  uploadProductsResponse: {},
  loadingUploadProducts: false,
  successUploadProducts: false,
  errorUploadProducts: false,
});

const handleUploadProducts = (state, action) => {
  return state.set('loadingUploadProducts', true).set('successUploadProducts', false).set('errorUploadProducts', false);
};

const handleUploadProductsSuccess = (state, action) => {
  const { response } = action;
  return state.set('uploadProductsResponse', response).set('loadingUploadProducts', false).set('successUploadProducts', true).set('errorUploadProducts', false);
};

const handleUploadProductsError = (state, action) => {
  return state.set('loadingUploadProducts', false).set('successUploadProducts', false).set('errorUploadProducts', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.UPLOAD_PRODUCTS: return handleUploadProducts(state);
    case Types.UPLOAD_PRODUCTS_SUCCESS: return handleUploadProductsSuccess(state, action);
    case Types.UPLOAD_PRODUCTS_ERROR: return handleUploadProductsError(state, action);

    case Types.RESET: return initialState;
    
    default: return state;
  }
}