import React, { useEffect, useMemo, useState } from 'react';
import { Form, View, Spin } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { getPerfis } from '@sdk/Perfil';
import { getSearchParams, TelasNomenclaturas } from '@utils';
import { Column, ColumnLowerGap, ColumnRow, MarginBottom, PermissionIcon, ProfileName, Row, RowLowerGap, RowWithFillChildren, CommonText, TinyMarginBottom, TinierMarginBottom, LowerMarginBottom, AdaptedFormItem } from './styles';
import { getTiposAtividades, getActivitiesTypesGroup, resetSingle, saveTipo } from '@ducks/configuracoesAtividadesTipos';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import { getChecklistTemplates } from '@ducks/checklistTemplates';
import { Form as StyledForm, Input, InputNumber, Select, Subtitle, Picker, Switch, Checkbox, FormItem, Modal, Divider } from '@components-teammove';
import { useHistory } from 'react-router-dom';

export default function ModalCadastroAtividade({ visible, setVisible }) {
  const history = useHistory();

  const activity = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('tipoAtividade'));
  const loading = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('loading'));
  const success = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('success'));
  const loadingSave = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('loadingSave'));
  const successSave = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('successSave'));

  const checklistTemplates = useSelector(({ checklistTemplates }) => checklistTemplates.get('checklistTemplates'));

  const companyKinds = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));

  const activitiesTypesGroup = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('activitiesTypesGroup'));
  
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [perfis, setPerfis] = useState();
  const [selectingAll, setSelectingAll] = useState(false);
  const [permissionsByProfiles, setPermissionsByProfiles] = useState([{}]);
  const [metric, setMetric] = useState();
  const [blockActivities, setBlockActivities] = useState();
  const [checklistByCompanyKind, setChecklistByCompanyKind] = useState();
  const [checklistByCancelation, setChecklistByCancelation] = useState();
  const [originalFieldValue, setOriginalFieldValue] = useState();

  const id = useMemo(() => getSearchParams('id'), []);

  useEffect(() => {
    dispatch(getActivitiesTypesGroup());
    (async () => {
      const perfisAsync = await getPerfis();
      const perfisFormatted = perfisAsync.map(({ title, value }) => ({ label: title, value }));
      setPerfis([perfisFormatted.slice(0, Math.round(perfisFormatted.length / 2)), perfisFormatted.slice(Math.round(perfisFormatted.length / 2), perfisFormatted.length)]);
      if (id === '-1') {
        setPermissionsByProfiles(perfisFormatted.map(({ value }) => ({ idProfile: value, visualize: false, edit: false })));
      }
    })();
    return () => {
      dispatch(resetSingle());
    };
  }, []);

  useEffect(() => {
    if (success && id !== '-1') {
      setMetric(activity.metrica);
      setBlockActivities(activity.utilizaBloqueio);
      
      form.setFieldsValue({ ...activity, ...(activity.companyTypesTemplates ? activity.companyTypesTemplates.reduce((state, elem) => ({ ...state, [`${elem.idUnidadeTipo}_checklistTemplates`]: elem.idsChecklistTemplate }), {}) : {}) });

      setChecklistByCompanyKind(activity.companyTypesTemplates.filter(({ idUnidadeTipo }) => idUnidadeTipo).length > 0);
      setChecklistByCancelation(activity.idChecklistTemplateCancelamento);
      setPermissionsByProfiles(activity.perfis.map(({ idPerfil, inserir, visualizar }) => ({ idProfile: idPerfil, edit: inserir, visualize: visualizar })));
      setOriginalFieldValue(form.getFieldsValue());
    }
    if(id === '-1') {
      form.setFieldsValue({ allowGoals: false });
    }
  }, [success]);

  useEffect(() => {
    if (successSave) {
      dispatch(getTiposAtividades());
    }
  }, [successSave]);

  useEffect(() => {
    if (metric === 'C') {
      dispatch(getTiposUnidades());
      dispatch(getChecklistTemplates({ status: 'LIBERADO' }));
    }
  }, [metric]);

  useEffect(() => {
    if (successSave) {
      form.resetFields();
      handleClose();
    }
  }, [successSave]);

  const handleClose = () => {
    setVisible(false);
    history.push('#');
  };

  const handleProfilePermissionChange = (profile, permission, checked) => {
    setPermissionsByProfiles(
      permissionsByProfiles.map(
        (prof) => prof.idProfile === profile.value ? ({ ...prof, [permission]: checked }) : ({ ...prof })
      )
    );
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setPermissionsByProfiles(permissionsByProfiles.map(({ idProfile }) => ({ idProfile, edit: true, visualize: true })));
      setSelectingAll(true);
    } else {
      setPermissionsByProfiles(permissionsByProfiles.map(({ idProfile }) => ({ idProfile, edit: false, visualize: false })));
      setSelectingAll(false);
    }
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      values.perfis = permissionsByProfiles.map((profile) => ({ idTarefaTipo: id, idPerfil: profile.idProfile, inserir: profile.edit, visualizar: profile.visualize }));

      values.companyTypesTemplates = Object.keys(values).filter((key) => key.includes('_') && values[key]).map((key) => {
        const idUnidadeTipo = parseInt(key.split('_')[0]);
        const idsChecklistTemplate = Array.isArray(values[key]) ? values[key] : [values[key]];
        const objToSend = { idUnidadeTipo, idsChecklistTemplate };
        delete values[key];
        if (!objToSend.idUnidadeTipo) delete objToSend.idUnidadeTipo;
        return objToSend;
      });

      if (values.companyTypesTemplates.length === 0) delete values.companyTypesTemplates;

      dispatch(saveTipo({ ...activity, ...values, idChecklistTemplateCancelamento: checklistByCancelation ? values.idChecklistTemplateCancelamento : null }));
    }).catch((err) => console.error(err));
  };

  const onBeforeCancel = () => {
    if(originalFieldValue) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalFieldValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  }; 
  
  return(
    <Modal
      title='Tipo de Atividade' 
      visible={visible} 
      onCancel={() => handleClose()} 
      onBeforeCancel={onBeforeCancel}
      onOk={handleSave}
      okText='Salvar'
      okButtonProps={{ loading: loadingSave }}
      numberOfButtons={1}
    >
      {loading ? (
        <View>
          <Spin/>
        </View>
      ) : (
        <>
          {id !== '-1' && (
            <StyledForm form={form}>
              <FormItem name='ativo' label='Ativa' labelSide='right' colon={false} valuePropName='checked'>
                <Switch/>
              </FormItem>
            </StyledForm>
          )}
          <StyledForm form={form} layout='vertical'>
            <FormItem name='nome' label='Nome do tipo de atividade' rules={[{ required: true, message: 'Insira um nome para o tipo de atividade' }, { max: 30, message: 'Insira um nome menor' }]}>
              <Input placeholder='Nome do tipo de atividade' form={form} formFieldName='nome'/>
            </FormItem>
            
            <Row>
              <FormItem name='metrica' label='Métrica' rules={[{ required: true, message: 'Escolha uma métrica para o tipo de atividade' }]}>
                <Select 
                  onChange={(value) => setMetric(value)}
                  placeholder='Métrica'
                  options={[
                    { label: 'Simples', value: 'S' },
                    { label: 'Plano de ação', value: 'P' },
                    { label: 'Checklist', value: 'C' }
                  ]}
                />
              </FormItem>
              <FormItem name='utilizaUnidade' label={'Utiliza ' + (companyNomenclature.nomenclatura).toLowerCase()} rules={[{ required: true, message: `Escolha uma opção para o uso de ${(companyNomenclature.nomenclatura).toLowerCase()}` }]}>
                <Select 
                  placeholder={'Utiliza ' + (companyNomenclature.nomenclatura).toLowerCase()}
                  options={[
                    { label: 'Sim', value: 'S' },
                    { label: 'Não', value: 'N' },
                    { label: 'Opcional', value: 'O' }
                  ]}
                />
              </FormItem>
            </Row>

            <Row>
              <FormItem name='utilizaCheckin' label='Check-in' rules={[{ required: true, message: 'Escolha uma opção para o uso de check-in' }]}>
                <Select 
                  placeholder='Utiliza check-in'
                  options={[
                    { label: 'Simples', value: 'S' },
                    { label: 'Geolocalização', value: 'G' },
                    { label: 'Não', value: 'N' }
                  ]}
                />
              </FormItem>
            </Row>
            <FormItem name='utilizaCheckout' label='Check-out' rules={[{ required: true, message: 'Escolha uma opção para o uso de check-out' }]}>
              <Select 
                placeholder='Utiliza check-out'
                options={[
                  { label: 'Sim', value: 'S' },
                  { label: 'Não', value: 'N' },
                ]}
              />
            </FormItem>
            <FormItem name='activityTypeGroup' label='Grupo do tipo de atividade' rules={[{ required: true, message: 'Selecione o grupo do tipo de atividade' }]}>
              <Select 
                placeholder='Grupo do tipo de atividade'
                options= {activitiesTypesGroup.map((group) => ({ label: group.label, title:group.label, value: group.value }))}
              />
            </FormItem>
            <Row align='start'>
              <FormItem name='cor' label='Cor' rules={[{ required: true, message: 'Insira uma cor para o tipo de atividade' }]} valuePropName='color'>
                <Picker/>
              </FormItem>
              <FormItem name='icone' label='Ícone' rules={[{ required: true, message: 'Insira um ícone para o tipo de atividade' }]} valuePropName='icon'>
                <Picker kind='icon'/>
              </FormItem>
            </Row>
          </StyledForm>

          <StyledForm form={form}>
            <Row>
              <FormItem name='inicioInstantaneo' label='Preenchimento instantâneo' labelSide='right' colon={false} valuePropName='checked'>
                <Switch/>
              </FormItem>
              <FormItem name='exibeDeslocamento' label='Exibe deslocamento' labelSide='right' colon={false} valuePropName='checked'>
                <Switch/>
              </FormItem>
            </Row>
            <FormItem name='geraPlanoDeAcao' label='Utilizado como plano de ação no checklist' labelSide='right' colon={false} valuePropName='checked'>
              <Switch/>
            </FormItem>
          </StyledForm>

          <RowWithFillChildren>
            <Subtitle>Permissão dos usuários:</Subtitle>
            <RowLowerGap>
              <Checkbox 
                checked={selectingAll} 
                onChange={handleSelectAll}
              />
              <CommonText>Selecionar todos</CommonText>
            </RowLowerGap>
          </RowWithFillChildren>
          <LowerMarginBottom/>
          {perfis ? (
            <Row align='start'>
              <ColumnLowerGap>
                <ColumnRow>
                  <PermissionIcon type='eye-outline'/>
                  <PermissionIcon type='pencil-outline'/>
                </ColumnRow>
                <Column>
                  {perfis[0].map((profile) => (
                    <ColumnRow key={profile.value}>
                      <Checkbox checked={permissionsByProfiles.find((prof) => prof.idProfile === profile.value)?.visualize} onChange={(checked) => handleProfilePermissionChange(profile, 'visualize', checked)}/>
                      <Checkbox checked={permissionsByProfiles.find((prof) => prof.idProfile === profile.value)?.edit} onChange={(checked) => handleProfilePermissionChange(profile, 'edit', checked)}/>
                      <ProfileName>{profile.label}</ProfileName>
                    </ColumnRow>
                  ))}
                </Column>
              </ColumnLowerGap>
              <ColumnLowerGap>
                <ColumnRow>
                  <PermissionIcon type='eye-outline'/>
                  <PermissionIcon type='pencil-outline'/>
                </ColumnRow>
                <Column>
                  {perfis[1].map((profile) => (
                    <ColumnRow key={profile.value}>
                      <Checkbox checked={permissionsByProfiles.find((prof) => prof.idProfile === profile.value)?.visualize} onChange={(checked) => handleProfilePermissionChange(profile, 'visualize', checked)}/>
                      <Checkbox checked={permissionsByProfiles.find((prof) => prof.idProfile === profile.value)?.edit} onChange={(checked) => handleProfilePermissionChange(profile, 'edit', checked)}/>
                      <ProfileName>{profile.label}</ProfileName>
                    </ColumnRow>
                  ))
                  }
                </Column>
              </ColumnLowerGap>
            </Row>
          ) : (
            <View>
              <Spin/>
            </View>
          )}
          
          <MarginBottom/>

          <StyledForm form={form}>
            <RowLowerGap>
              <AdaptedFormItem name='utilizaBloqueio' colon={false} valuePropName='checked'>
                <Checkbox onChange={(value) => setBlockActivities(value)}/>
              </AdaptedFormItem>
              <Subtitle>Bloqueio da atividade</Subtitle>
            </RowLowerGap>
            <MarginBottom/>
          </StyledForm>

          <StyledForm form={form}>
            <RowLowerGap>
              <AdaptedFormItem name='allowGoals' colon={false} valuePropName='checked'>
                <Checkbox/>
              </AdaptedFormItem>
              <Subtitle>Permite o lançamento de metas</Subtitle>
            </RowLowerGap>
            <MarginBottom/>
          </StyledForm>

          {blockActivities && (
            <StyledForm form={form} layout='vertical'>
              <FormItem name='bloqueioDias' label='Prazo de resposta' colon={false} rules={[{ min: 0, message: 'Insira um valor positivo', type: 'number' }]}>
                <InputNumber placeholder='N° dias' min={0}/>
              </FormItem>
            </StyledForm>
          )}

          {metric === 'C' && (
            <StyledForm form={form} layout='vertical'>  
              <RowLowerGap>
                <Checkbox checked={checklistByCompanyKind} onChange={(checked) => setChecklistByCompanyKind(checked)}/>
                <Subtitle>Utilizar checklists por tipo de {(companyNomenclature.nomenclatura).toLowerCase()}</Subtitle>
              </RowLowerGap>

              <MarginBottom/>

              {checklistByCompanyKind ? (
                <View>
                  {companyKinds.map((companyKind) => (
                    <View key={companyKind.id}>
                      <Row>
                        <CommonText>{companyKind.name.toUpperCase()}</CommonText>
                        <Divider/>
                      </Row>
                      <TinierMarginBottom/>
                      <FormItem name={`${companyKind.id}_checklistTemplates`} rules={[{ required: true, message: `É necessário informar um checklist para todos os tipos de ${(companyNomenclature.nomenclatura).toLowerCase()}` }]}>
                        <Select placeholder='Checklists' options={checklistTemplates.map((template) => ({ label: template.name, value: template.id }))} mode='multiple'/>
                      </FormItem>
                    </View>
                  ))}
                </View>
              ) : (
                <>
                  <TinyMarginBottom>
                    <Row>
                      <CommonText>Checklists</CommonText>
                      <Divider/>
                    </Row>
                  </TinyMarginBottom>
                  <FormItem name='undefined_checklistTemplates'>
                    <Select placeholder='Checklists' options={checklistTemplates.map((template) => ({ label: template.name, value: template.id }))}/>
                  </FormItem>
                </>
              )}

              <RowLowerGap>
                <Checkbox checked={checklistByCancelation} onChange={(checked) => setChecklistByCancelation(checked)}/>
                <Subtitle>Utilizar checklists por cancelamento</Subtitle>
              </RowLowerGap>

              <MarginBottom/>

              {checklistByCancelation && (
                <FormItem name='idChecklistTemplateCancelamento' label='Checklists' rules={[{ required: true, message: 'Escolha um template de checklist' }]}>
                  <Select placeholder='Checklists' options={checklistTemplates.map((template) => ({ label: template.name, value: template.id }))}/>
                </FormItem>
              )}
            </StyledForm>
          )}
        </>
      )}
    </Modal>
  );
}