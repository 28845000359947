import styled from 'styled-components';
import { Column, Row, Icon } from '@components-teammove';

export const Card = styled(Column)`
  border-radius: 12px;
  padding: 20px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
`;

export const ButtonCard = styled(Row)`
  border-radius: 42.5px;
  padding: 7px 18px 7px 7px;
  background-color: ${({ theme, activeStatus }) => activeStatus ? theme.darkPrimaryColor : theme.darkSecondaryColor};
  border: ${({ activeStatus, theme })=> activeStatus ? `2px ${theme.actionColor} solid` : '' };
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  grid-column: ${({ totalStatus }) => totalStatus ? 'span 4' : 'span 1'};
`;

export const IconFilter = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
  width: 24px;
  height: 24px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: ${({ colorIcon, theme }) => colorIcon  || theme.backgroundColor};
  border-radius: 50%;
`;