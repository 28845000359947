import React from 'react';

import { Grid, Text, Skeleton, Row } from '@components-teammove';

import { ButtonCard, Card, IconContainer, IconFilter } from './styles';
import { useSelector } from 'react-redux';
import { formatCurrency } from '@utils';
import { getTextCard } from './rules';

export default function GridCards({ handleChangeClassification, activeStatus }) {

  const callList = useSelector(({ callActivity }) => callActivity.get('callList'));
  const loadingCallList = useSelector(({ callActivity }) => callActivity.get('loadingCallList'));

  const classifications = ['RELEVANTE', 'NAO_RELEVANTE', 'ERRO', 'SEM_CONTATO'];

  return(
    <>
      <Grid numberOfColumns={4} gap='12px'>
        <Card>
          <Text size='16px' weight='600' lowOpacity>Tempo total em ligação</Text>
          {loadingCallList ? <Skeleton.MinorLine/> : <Text size='24px' weight='700' >{(callList.totalTimeCall / 60 || 0).toFixed(2).replace('.', ',')}  <Text lowOpacity size='16px'>min</Text></Text>}
        </Card>
        <Card>
          <Text size='16px' weight='600' lowOpacity>Tempo médio por ligação</Text>
          {loadingCallList ? <Skeleton.MinorLine/> : <Text size='24px' weight='700' >{(callList.averageTimeCall / 60 || 0).toFixed(2).replace('.', ',')} <Text lowOpacity size='16px'>min</Text></Text>}
        </Card>
        <Card>
          <Text size='16px' weight='600' lowOpacity>Custo total de ligações</Text>
          {loadingCallList ? <Skeleton.MinorLine/> : <Text size='24px' weight='700' >{formatCurrency(callList.totalCostCalls) || 0}</Text>}
        </Card>
        <Card>
          <Text size='16px' weight='600' lowOpacity>Custo médio por ligação</Text>
          {loadingCallList ? <Skeleton.MinorLine/> : <Text size='24px' weight='700' >{formatCurrency(callList.averageCostPerCall) || 0}</Text>}
        </Card>
      </Grid>
      <Grid numberOfColumns={4} gap='12px'>
        <ButtonCard activeStatus={activeStatus === 'TOTAL'} onClick={() => handleChangeClassification('TOTAL')} totalStatus>
          <Row align="center" gap="12px">
            <IconContainer colorIcon={getTextCard('TOTAL')?.iconColor}>
              <IconFilter type={getTextCard('TOTAL')?.icon}/>
            </IconContainer>
            <Text size="16px" weight="700">{getTextCard('TOTAL')?.text}</Text>
          </Row>
          {loadingCallList ? <Skeleton.MinorLine/> : <Text size="16px" weight="700">{callList.totalCall || 0}</Text>}
        </ButtonCard>
      </Grid>
      <Grid numberOfColumns={4} gap='12px'>
        {classifications.map((status) => {
          const classificationDetail = callList?.classificationDetails?.find(
            (detail) => detail?.classification?.toUpperCase() === status
          );

          const total = classificationDetail ? classificationDetail.total : 0;
          const percentage = classificationDetail ? classificationDetail.percentage : '0.00';
          return (
            <ButtonCard key={status} activeStatus={activeStatus === status} onClick={() => handleChangeClassification(status)}>
              <Row align="center" gap="12px">
                <IconContainer colorIcon={getTextCard(status)?.iconColor}>
                  <IconFilter type={getTextCard(status)?.icon}/>
                </IconContainer>
                <Text size="16px" weight="700">{getTextCard(status)?.text}</Text>
              </Row>
              <Row align="center" gap="6px">
                {loadingCallList ? <Skeleton.MinorLine/> : (
                  <>
                    <Text size="16px" weight="700">{total}</Text>
                    <Text size="16px" weight="700">{`(${percentage}%)`}</Text>
                  </>
                )}
              </Row>
            </ButtonCard>
          );})}
      </Grid>
    </>
  );
}