import React, { useState } from 'react';
import { Tag } from '@components-teammove';
import { Container, InputStyled } from './styles';

const TagInput = ({ onChange, value }) => {
  const [tags, setTags] = useState( value || []);
  const [inputValue, setInputValue] = useState('');
  
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  
  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      setInputValue('');
      onChange(newTags);
      e.preventDefault();
    }
  };
  
  const handleTagClose = (tagToRemove) => {
    const newTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(newTags);
    onChange(newTags);
  };
  
  return (
    <Container>
      {tags.map((tag) => (
        <Tag key={tag} closable onClose={() => handleTagClose(tag)}>
          {tag}
        </Tag>
      ))}
      <InputStyled
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Digite e pressione Enter"
      />
    </Container>
  );
};

export default TagInput;