import React from 'react';
import { CardActivityGroup } from '../CardActivityGroup';
import { Column, Skeleton, Row, Text } from '@components-teammove';
import { useSelector } from 'react-redux';
import { Dot, RowCards } from '../CardActivityGroup/styles';

export const ActivitiesGoals = ({ activeTypesList, typePeriod, initialDate, currentFilters, onClick }) => {

  const activitiesKinds = useSelector(({ activitiesGoals }) => activitiesGoals.get('activitiesKinds'));
  const loadingActivitiesKinds = useSelector(({ activitiesGoals }) => activitiesGoals.get('loadingActivitiesKinds'));
  const successActivitiesKinds = useSelector(({ activitiesGoals }) => activitiesGoals.get('successActivitiesKinds'));
  
  const verifyIfAllIsZero = (itens) => {
    const isAllZero = itens.every((moment) => 
      moment.finishedQuantity === 0 &&
      moment.idealFinishedQuantity === 0 &&
      moment.scheduledQuantity === 0
    );

    return isAllZero; 
  };

  const verifyAllIsZeroObject = (object) => {
    return object.every((item) => verifyIfAllIsZero(item.moments));
  };
  
  return(
    loadingActivitiesKinds ? <Skeleton.LargeBox/> : successActivitiesKinds && activitiesKinds.length > 0 && (
      !verifyAllIsZeroObject(activitiesKinds) && (
        <Column gap='12px'>
          <Row gap='12px' align='center'>
            <Row align='center' gap='6px'><Dot color='#1890FF'/><Text>Meta</Text></Row>
            <Row align='center' gap='6px'><Dot color='#34C759'/><Text>Realizado</Text></Row>
            <Row align='center' gap='6px'><Dot color='#FFCC00'/><Text>Agendado</Text></Row>
          </Row>
        
          <RowCards gap='12px'>
            {activitiesKinds.map(({ id, icon, color, name, finishedActivitiesGoalPercent, 
              numberOfActivitiesGoal, numberOfFinishedActivities, numberOfScheduledActivities, groupActivityKinds,
              moments 
            })=> (
              <>
                {(!verifyIfAllIsZero(moments) || numberOfActivitiesGoal !== 0) && (
                  <CardActivityGroup
                    onClick= {() => onClick({ id, groupActivityKinds })}
                    activeType={activeTypesList.find((item)=> item.id === id && item.groupActivityKinds?.length === groupActivityKinds?.length)}
                    key={id} icon={icon} color={color} 
                    info={{ 
                      name,
                      finishedActivitiesGoalPercent,
                      numberOfActivitiesGoal,
                      numberOfFinishedActivities,
                      numberOfScheduledActivities,
                    }}
                    groupActivityKinds={groupActivityKinds}
                    moments={moments}
                    typePeriod={typePeriod}
                    initialDate={initialDate}
                  />
                )}
              
              </>
            ))}
          </RowCards>
        </Column>
      )
    )
  );
}; 