import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import App from '@app';

import { Body } from '@components';
import { Filter, PageHeader, Breadcrumb, Button, ContentHeader, ViewControl, SearchBar, Form, Icon, Row, Switch, Text } from '@components-teammove';
import { 
  InfoUsuario, 
  TelasNomenclaturas, 
  Filtros, 
  mountDataTreeSelect, 
  Notification, 
  useSearchParams 
} from '@utils';
import { ContentTitleExtra, IconContainer, ModalConfirm, SelectPeriod } from './styles';

import { getDefaultFilter, FILTER_AWAITING_EVALUATION, initialFilters, STATUS } from './rules';

import { enableCreationTicket, getCategorias } from '@sdk/Chamados';
import { getCategorias as getCategoriesForOptions } from '@ducks/configuracoesChamados';
import { getUsuarios } from '@sdk/Usuarios';
import { getPerfis } from '@sdk/Perfil';
import { getChamados, FILTERS_KEY } from '@ducks/chamadosTickets';
import { getDashboard } from '@ducks/chamadosDashboard';
import { changeResponsavel, changeCategoria , changeVisibleBriefingReply } from '@ducks/chamadosHistorico';
import { reset as resetBriefingReply } from '@ducks/chamadosBriefingReply';

import ModalResponsavel from './Chat/ModalResponsavel';
import ModalCategoria from './Chat/ModalCategoria';
import Kanban from './Kanban';
import Dashboard from './Dashboard';
import List from './List';
import ModalNovo from './Cadastro';
import ModalQuestionarioReply from './Questionario/Reply';

export default function ChamadosTickets({ history }) {
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [chamado, setChamado] = useState(null);
  const [filtros, setFiltros] = useState(Filtros.get(FILTERS_KEY));
  const [filters, setFilters] = useState(initialFilters);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const [busca, setBusca] = useState();
  const [orderBy, setOrderBy] = useState();
  const [visibleChangeResponsavel, setVisibleChangeResponsavel] = useState(false);
  const [visibleChangeCategoria, setVisibleChangeCategoria] = useState(false);
  const [visibleCadastro, setVisibleCadastro] = useState(false);
  const [loadingEnableCreationTicket, setLoadingEnableCreationTicket] = useState(false);
  const [usuarios, setUsuarios] = useState();
  const [perfis, setPerfis] = useState();
  const [categoriasTree, setCategoriasTree] = useState();
  const [visibleWarningNewTicket, setVisibleWarningNewTicket] = useState(false);
  const [viewBy, setViewBy] = useState(localStorage.getItem('tickets_view_mode') || 'Listagem');
  const { ticket: idTicket } = useSearchParams();
 
  const dispatch = useDispatch();
  const location = useLocation();
  const inputSearch = useRef(null);
  
  const usuario = InfoUsuario.get();
  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');
  const theme = useSelector(({ app }) => app.get('theme'));

  const totalUnseenMessages = useSelector(({ chamadosTickets }) => chamadosTickets.get('totalUnseenMessages'));
  //Ações dos tickets
  const loadingResponsavel = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingResponsavel'));
  const successResponsavel = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successResponsavel'));
  const loadingCategoria = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingCategoria'));
  const successCategoria = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successCategoria'));
  const successFinishBriefingReply = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('successFinishBriefingReply'));

  const currentTicket = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('chamado'));
  const visibleBriefingReply = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('visibleBriefingReply'));

  const [ typeTicket, setTypeTicket ] = useState(sessionStorage.getItem('typeTicket') || 'Todos');
  const [ requestParams, setRequestParams ] = useState();

  const [ isShowingInactiveCategories, setIsShowingInactiveCategories ] = useState(false);
  const [ categoriesValuesWithActiveAndInactive, setCategoriesValuesWithActiveAndInactive ] = useState();
  const [ categoriesValuesOnlyActive, setCategoriesValuesOnlyActive ] = useState();
  const [seeSteps, setSeeSteps] = useState(() => {
    const storedValue = localStorage.getItem('seeSteps');
    return storedValue ? JSON.parse(storedValue) : true;
  });

  useEffect(() => {
    let filtrosParams = {};
    let localStorageFilters = { ...filtros };
    
    if(typeTicket === 'Recebidos' ) {
      filtrosParams = { 
        responsavel: [usuario.cdUsuario], 
      };
      localStorageFilters = {
        ...localStorageFilters,
        responsavel: undefined,
        descFiltros: {
          ...localStorageFilters?.descFiltros,
          responsavel: undefined,
        }
      };
    }else if(typeTicket === 'Enviados') {
      filtrosParams = { 
        usuarioCriacao: [usuario.cdUsuario], 
      };
      localStorageFilters = {
        ...localStorageFilters,
        usuarioCriacao: undefined,
        descFiltros: {
          ...localStorageFilters?.descFiltros,
          usuarioCriacao: undefined,
        }
      };
    }
    
    setFiltros(localStorageFilters);
    Filtros.set(FILTERS_KEY, localStorageFilters);
    setRequestParams(filtrosParams);
    handleDefaultDispatch({ ...filtros,...filtrosParams }, busca);
    
  },[typeTicket]); 

  useEffect(() => {
    if(categoriesValuesWithActiveAndInactive) {
      if(!isShowingInactiveCategories) {
        setCategoriasTree(categoriesValuesOnlyActive);
      }else {
        setCategoriasTree(categoriesValuesWithActiveAndInactive);
      }
      
    }
  },[isShowingInactiveCategories]);

  const handleDefaultDispatch = (filtrosParam, search) => {
    const storedOrderBy = sessionStorage.getItem('chamadosTickets/Order');
    dispatch(viewBy === 'Listagem' ? getChamados(usuario.cdUsuario, filtrosParam, 50, search, storedOrderBy ? (JSON.parse(storedOrderBy)) : orderBy) : getDashboard(usuario.cdUsuario, filtrosParam, search));
  };

  const handleChangeResponsavel = (novoResponsavel) => {
    dispatch(changeResponsavel(chamado.id, novoResponsavel));
  };

  const handleChangeCategoria = (categoria) => {
    dispatch(changeCategoria(chamado.id, categoria));
  };
  
  const handleCloseChangeResponsavel = () => {
    setChamado(null); 
    setVisibleChangeResponsavel(false); 
  };

  const handleCloseChangeCategoria = () => { 
    setChamado(null);
    setVisibleChangeCategoria(false);
  };
  
  const handleSearch = (filtros, search) => {
    setBusca(search);
    Filtros.set(FILTERS_KEY, filtros);
    history.push(search ? `?busca=${search}` : '#');
    return handleDefaultDispatch({  ...filtros,...requestParams }, search);
  };

  const handleSearchChangingNewFilters = (newFilters) => { 
    setFiltros(newFilters);
    const changedReponsible = newFilters.responsavel !== filtros.responsavel;
    const changedUserCreation = newFilters.usuarioCriacao !== filtros.usuarioCriacao;

    if((typeTicket === 'Recebidos' && changedReponsible) || (typeTicket === 'Enviados' && changedUserCreation)) {
      setTypeTicket('Todos');
    }else {
      handleSearch(newFilters, busca);
    }
  };

  useEffect(() => {
    (async () => {
      getUsuarios()
        .then((resp) => {
          setUsuarios(resp.filter((item) => InfoUsuario.perm('chamadosAdministrador', usuario) || usuario.hierarquia?.includes(item.key)).map(({ key, title }) => ({ value: key, label: title })));
        });
      getPerfis()
        .then((resp) => {
          setPerfis(resp.map(({ value, title }) => ({ value: value, label: title })));
        });
      getCategorias(false, false)
        .then((resp) => { 
          const response = resp.filter((item)=> item.ativo);
          const mountDataActive = mountDataTreeSelect(response, 'id', 'idCategoria', 'titulo');
          const mountData = mountDataTreeSelect(resp, 'id', 'idCategoria', 'titulo');       
          setCategoriesValuesOnlyActive(mountDataActive);
          setCategoriesValuesWithActiveAndInactive(mountData);
          setCategoriasTree(!isShowingInactiveCategories ? mountDataActive : mountData);
          setLoadingFilters(false);
        });
    })();
    let filtroParams = location.state;

    if (!filtroParams) {
      filtroParams = filtros;
    }

    if (!filtroParams.descFiltros) {
      filtroParams = getDefaultFilter();
    }
    setBusca(busca);
    history.push(busca ? `?busca=${busca}` : '#');
    dispatch(getCategoriesForOptions(false));
    dispatch(resetBriefingReply());
    return () => dispatch(resetBriefingReply());
  }, []);

  useEffect(() => {
    if (usuarios && categoriasTree && perfis) {
      setFilters(filters.map((filter) => {
        if (filter.name === 'responsavel') {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'perfil') {
          return { ...filter, options: perfis };
        }
        if (filter.name === 'usuarioCriacao') {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'categoria') {
          return { ...filter, options: categoriasTree };
        }
        return { ...filter };
      }));
    }
  }, [usuarios, categoriasTree, perfis]);

  useEffect(() => {
    if (successResponsavel) {
      handleCloseChangeResponsavel();
    }
  }, [successResponsavel]);

  useEffect(() => {
    if (successCategoria) {
      handleCloseChangeCategoria();
    }
  }, [successCategoria]);

  useEffect(() => {
    if (successFinishBriefingReply) {
      Notification.success('Briefing finalizado com sucesso!');
      dispatch(changeVisibleBriefingReply(false));
      history.push(`/chamados/tickets/${idTicket}`);
    }
  }, [successFinishBriefingReply]);

  const handleNovo = async() => {
    setLoadingEnableCreationTicket(true);
    try {
      const res = await enableCreationTicket(usuario.cdUsuario);
      if (res) {
        setVisibleCadastro(true);
      } else {
        setVisibleWarningNewTicket(true);
      }
    } finally {
      setLoadingEnableCreationTicket(false);
    }
  };
  
  const handleViewByChange = (viewByName) => {
    if (viewBy === 'Listagem') {
      delete filtros?.fechadoNoPrazo;
      delete filtros?.fechadoForaPrazo;
      delete filtros?.descFiltros?.fechadoNoPrazo;
      delete filtros?.descFiltros?.fechadoForaPrazo;
    }
    setViewBy(viewByName);
    localStorage.setItem('tickets_view_mode', viewByName);
  };

  const handleChangeTypeTicket = (value) => {
    setTypeTicket(value);
    sessionStorage.setItem('typeTicket', value);
  };

  const handleActiveCategories = () => {
    setIsShowingInactiveCategories(!isShowingInactiveCategories);
  };
  
  const handleSeeSteps = (value) => {
    setSeeSteps(value);
    localStorage.setItem('seeSteps', JSON.stringify(value));
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={`${ticketNomenclatures.plural} / Tickets`}
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/chamados/tickets', name: `${ticketNomenclatures.plural}`, current: true },
                { path: '/chamados/tickets', name: `${ticketNomenclatures.nomenclatura}  / Tickets`, current: true }
              ]}
            />
          )}
          extra={[
            <Button key='filter' size='small' context='header' type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>Filtros</Button>,
            <Button key='new' size='small' context='header' type='primary' onClick={handleNovo} visible={InfoUsuario.perm('chamadosCadastro', usuario)} loading={loadingEnableCreationTicket}>Abrir novo ticket</Button>
          ]}
        >
          {(
            <SearchBar
              ref={inputSearch}        
              placeholder="Buscar Tickets..."  
              initialValue={busca}
              onSearch={(value) => handleSearch(filtros, value)}        
              activeFiltersProps={{ filtros, onSearch: handleSearchChangingNewFilters, filters }}
            />)}
          
          <Row gap='12px'>
            <Form>
              <Form.Item>
                <SelectPeriod 
                  size='small'
                  defaultValue={typeTicket}
                  options={[
                    { label: 'Todos', value: 'Todos' },
                    { label: 'Recebidos', value: 'Recebidos' },
                    { label: 'Enviados', value: 'Enviados' },
                  ]}
                  onChange={(value) => handleChangeTypeTicket(value)}
                />
              </Form.Item>
            </Form>
            <IconContainer>
              <Switch checked={seeSteps} onChange={(value) => handleSeeSteps(value)} />
              <Text>Mostrar etapas</Text>
            </IconContainer>           
          </Row>
          <ContentHeader 
            title={viewBy} 
            extra={(
              <ViewControl 
                options={[
                  { icon: <Icon.Vsc type='VscGraph'/>, name: 'Resumo' },
                  { icon: <Icon.MDI type='format-list-bulleted'/>, name: 'Listagem' }, 
                ]}
                current={viewBy} 
                onChange={handleViewByChange}
              />
            )}
            subTitle={viewBy === 'Listagem' ? (totalUnseenMessages > 0 ? <ContentTitleExtra>{totalUnseenMessages} mensage{totalUnseenMessages > 1 ? 'ns' : 'm'}</ContentTitleExtra> : null) : null}
          />
          
          {viewBy === 'Listagem' ? (
            <List 
              chamado={chamado} 
              setChamado={setChamado} 
              orderBy={orderBy} 
              setOrderBy={setOrderBy} 
              filtros={filtros} 
              busca={busca} 
              setVisibleChangeCategoria={setVisibleChangeCategoria} 
              setVisibleChangeResponsavel={setVisibleChangeResponsavel}
              typeTicketForFiltrosParams={requestParams}
              seeSteps={seeSteps}
            />
          ) : viewBy === 'Kanban' ? (
            <Kanban/>
          ) : (
            <Dashboard 
              busca={busca} 
              filtros={filtros} 
              setViewBy={handleViewByChange} 
              setFiltros={setFiltros} 
              typeTicketForFiltrosParams={requestParams}
            />
          )}
            
        </PageHeader>

        <Filter
          visible={visibleFilters}
          onClose={() => setVisibleFilters(false)}
          filtros={filtros}
          onSearch={handleSearchChangingNewFilters}
          filters={filters.filter((filt) => filt.name !== 'fechadoNoPrazo' && filt.name !== 'fechadoForaPrazo')}
          loading={loadingFilters}
          onChangeValues={{ categoria: { change: handleActiveCategories, value: isShowingInactiveCategories } }}
        />
      </Body>
    
      {chamado && 
      <ModalResponsavel
        chamado={chamado}
        visible={visibleChangeResponsavel}
        loading={loadingResponsavel}
        onCancel={handleCloseChangeResponsavel}
        onChangeResponsavel={handleChangeResponsavel} 
      />}

      {chamado && 
      <ModalCategoria
        visible={visibleChangeCategoria}
        loading={loadingCategoria}
        onCancel={handleCloseChangeCategoria}
        onChangeCategoria={handleChangeCategoria}
        categoriaAtual={chamado.nmCategoriaCompleto}
        idsCategoria={chamado.idsCategoria}
      />}

      <ModalNovo
        visible={visibleCadastro}
        onCancel={() => setVisibleCadastro(false)}
        onOk={() => {
          setVisibleCadastro(false); 
          handleDefaultDispatch(filtros, busca);
        }}
      />
      
      {currentTicket.status ? (visibleBriefingReply && (currentTicket.status === STATUS.AGUARDANDO_BRIEFING && currentTicket.idUsuario === InfoUsuario.get('cdUsuario'))) : visibleBriefingReply && (
        <ModalQuestionarioReply
          visible={currentTicket.status ? (visibleBriefingReply && (currentTicket.status === STATUS.AGUARDANDO_BRIEFING && currentTicket.idUsuario === InfoUsuario.get('cdUsuario'))) : visibleBriefingReply}
          onCancel={() => {
            dispatch(changeVisibleBriefingReply(false));
            history.push('#');
          }}
          tipo={'BRIEFING'}
          chamado={''}
        />
      )}
      {visibleWarningNewTicket && (
        <ModalConfirm
          visible={visibleWarningNewTicket}
          title='Tickets pendentes'
          content='Existem tickets pendentes para avaliação'
          cancelText='Cancelar'
          okText='Avaliar'
          onOk={() => {
            handleSearchChangingNewFilters(FILTER_AWAITING_EVALUATION(usuario));
            Filtros.set(FILTERS_KEY, FILTER_AWAITING_EVALUATION(usuario));
            setVisibleWarningNewTicket(false);
          }}
          onCancel={() => setVisibleWarningNewTicket(false)}
        />
      )}
    </App>
  );
}
