import styled from 'styled-components';
import { Icon, Text, View, Upload } from '@components-teammove';

export const UploadEdge = styled(Upload)`
  border: 2px dashed ${(props) => props.theme.darkHighlightColor};
  height: 180px;
  border-radius:12px;
  background-color: ${(props) => props.theme.darkPrimaryColor};
  .ant-upload {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const UploadIcon = styled(Icon.Feather)`
  * {
    color: ${({ theme }) => theme.actionColor};
  }
`;

export const UploadText = styled(Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.textColor};
    opacity: 50%;
  }
`;

export const ComponentEmptyCover = styled(View)`
  width: 100%;
  height: 180px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5;
`;