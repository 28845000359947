import styled from 'styled-components';
import { Text, Form } from '@components-teammove';

export const AvatarItem = styled(Form.Item)`
  text-align: left;
  flex:none !important;
`;

export const HelpText = styled(Text)`
  font-size: 13px;
  color: ${ ({ theme })=> theme.textForegroundColor};
  opacity: .5;
`;

export const EditBlock = styled.div`
  position: relative;
  width: fit-content;
  display: flex;   
  gap:32px;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled(Form.Item)` 
  align-self: self-end;
  flex:none;
  .ant-form-item-control-input-content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const InputContainer = styled(Form.Item)` 
  margin: 0;
  ${({ extraMarginTop  }) => (extraMarginTop  && 'margin-top: 20px;')}
`;

export const DeleteIconConatiner = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  left: 120px;
  cursor: pointer;
`;