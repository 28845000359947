import { Map, List } from 'immutable';
import { 
  getExcelTemplate as getExcelTemplateSdk,
  uploadExcel as uploadExcelSdk,
  putRelationship as putRelationshipSdk,
  getTableValidation as getTableValidationSdk,
  postSaveAllCompanies as postSaveAllCompaniesSdk,
  putEditValueFromTableValidation as putEditValueFromTableValidationSdk,
  deleteCompanyImport as deleteCompanyImportSdk,
} from '@sdk/CompanyImport';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Notification } from '@utils';

//Action Types
export const Types = {
  GET_DOWLOAD_EXCEL_TEMPLATE: 'CONFIGURACOES_UNIDADES/GET_DOWLOAD_EXCEL_TEMPLATE',
  GET_DOWLOAD_EXCEL_TEMPLATE_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_DOWLOAD_EXCEL_TEMPLATE_SUCCESS',
  GET_DOWLOAD_EXCEL_TEMPLATE_ERROR: 'CONFIGURACOES_UNIDADES/GET_DOWLOAD_EXCEL_TEMPLATE_ERROR',

  UPLOAD_EXCEL: 'CONFIGURACOES_UNIDADES/UPLOAD_EXCEL',
  UPLOAD_EXCEL_SUCCESS: 'CONFIGURACOES_UNIDADES/UPLOAD_EXCEL_SUCCESS',
  UPLOAD_EXCEL_ERROR: 'CONFIGURACOES_UNIDADES/UPLOAD_EXCEL_ERROR',

  PUT_RELATIONSHIP: 'CONFIGURACOES_UNIDADES/PUT_RELATIONSHIP',
  PUT_RELATIONSHIP_SUCCESS: 'CONFIGURACOES_UNIDADES/PUT_RELATIONSHIP_SUCCESS',
  PUT_RELATIONSHIP_ERROR: 'CONFIGURACOES_UNIDADES/PUT_RELATIONSHIP_ERROR',
  
  GET_TABLE_VALIDATION: 'CONFIGURACOES_UNIDADES/GET_TABLE_VALIDATION',
  GET_TABLE_VALIDATION_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_TABLE_VALIDATION_SUCCESS',
  GET_TABLE_VALIDATION_ERROR: 'CONFIGURACOES_UNIDADES/GET_TABLE_VALIDATION_ERROR',

  POST_SAVE_ALL_COMPANIES: 'CONFIGURACOES_UNIDADES/POST_SAVE_ALL_COMPANIES',
  POST_SAVE_ALL_COMPANIES_SUCCESS: 'CONFIGURACOES_UNIDADES/POST_SAVE_ALL_COMPANIES_SUCCESS',
  POST_SAVE_ALL_COMPANIES_ERROR: 'CONFIGURACOES_UNIDADES/POST_SAVE_ALL_COMPANIES_ERROR',

  PUT_EDIT_VALUE_FROM_TABLE_VALIDATION: 'CONFIGURACOES_UNIDADES/PUT_EDIT_VALUE_FROM_TABLE_VALIDATION',
  PUT_EDIT_VALUE_FROM_TABLE_VALIDATION_SUCCESS: 'CONFIGURACOES_UNIDADES/PUT_EDIT_VALUE_FROM_TABLE_VALIDATION_SUCCESS',
  PUT_EDIT_VALUE_FROM_TABLE_VALIDATION_ERROR: 'CONFIGURACOES_UNIDADES/PUT_EDIT_VALUE_FROM_TABLE_VALIDATION_ERROR',

  DEL_COMPANY_IMPORT: 'CONFIGURACOES_UNIDADES/DEL_COMPANY_IMPORT',
  DEL_COMPANY_IMPORT_SUCCESS: 'CONFIGURACOES_UNIDADES/DEL_COMPANY_IMPORT_SUCCESS',
  DEL_COMPANY_IMPORT_ERROR: 'CONFIGURACOES_UNIDADES/DEL_COMPANY_IMPORT_ERROR',

  RESET_ALL_VALUES: 'CONFIGURACOES_UNIDADES/RESET_ALL_VALUES',
  RESET_SUCCESS_DOWNLOAD_TEMPLATE: 'CONFIGURACOES_UNIDADES/RESET_SUCCESS_DOWNLOAD_TEMPLATE',
};

//Action Creators
export const getDowloadExcelTemplate = (getCompanyData) => ({ type: Types.GET_DOWLOAD_EXCEL_TEMPLATE, getCompanyData });
export const getDowloadExcelTemplateSuccess = (templateUrl) => ({ type: Types.GET_DOWLOAD_EXCEL_TEMPLATE_SUCCESS, templateUrl });
export const getDowloadExcelTemplateSuccessError = (error) => ({ type: Types.GET_DOWLOAD_EXCEL_TEMPLATE_ERROR, error });

export const uploadExcel = (excel) => ({ type: Types.UPLOAD_EXCEL, excel });
export const uploadExecelSuccess = (response) => ({ type: Types.UPLOAD_EXCEL_SUCCESS, response });
export const uploadExcelError = (error) => ({ type: Types.UPLOAD_EXCEL_ERROR, error });

export const putRelationship = (modifiedCell) => ({ type: Types.PUT_RELATIONSHIP, modifiedCell });
export const putRelationshipSuccess = (response) => ({ type: Types.PUT_RELATIONSHIP_SUCCESS, response });
export const putRelationshipError = (error) => ({ type: Types.PUT_RELATIONSHIP_ERROR, error });

export const getTableValidation = (idTable, onlyErrors, rowsByPage, pageToGo) => ({ type: Types.GET_TABLE_VALIDATION, idTable, onlyErrors, rowsByPage, pageToGo });
export const getTableValidationSuccess = (table) => ({ type: Types.GET_TABLE_VALIDATION_SUCCESS, table });
export const getTableValidationError = (error) => ({ type: Types.GET_TABLE_VALIDATION_ERROR, error });

export const postSaveAllCompanies = (idTable) => ({ type: Types.POST_SAVE_ALL_COMPANIES, idTable });
export const postSaveAllCompaniesSuccess = (response) => ({ type: Types.POST_SAVE_ALL_COMPANIES_SUCCESS, response });
export const postSaveAllCompaniesError = (error) => ({ type: Types.POST_SAVE_ALL_COMPANIES_ERROR, error });

export const putEditValueFromTableValidation = (body) => ({ type: Types.PUT_EDIT_VALUE_FROM_TABLE_VALIDATION, body });
export const putEditValueFromTableValidationSuccess = (response) => ({ type: Types.PUT_EDIT_VALUE_FROM_TABLE_VALIDATION_SUCCESS, response });
export const putEditValueFromTableValidationError = (error) => ({ type: Types.PUT_EDIT_VALUE_FROM_TABLE_VALIDATION_ERROR, error });

export const delCompanyImport = (idTable) => ({ type: Types.DEL_COMPANY_IMPORT, idTable });
export const delCompanyImportSuccess = (response) => ({ type: Types.DEL_COMPANY_IMPORT_SUCCESS, response });
export const delCompanyImportError = (error) => ({ type: Types.DEL_COMPANY_IMPORT_ERROR, error });

export const resetAllValues = () => ({ type: Types.RESET_ALL_VALUES });
export const resetSuccessDownloadTemplate = () => ({ type: Types.RESET_SUCCESS_DOWNLOAD_TEMPLATE });

function* fetchGetDowloadExcelTemplate(action) {
  try {
    const { getCompanyData } = action;
    const templateUrl = yield call(getExcelTemplateSdk, getCompanyData);
    yield put(getDowloadExcelTemplateSuccess(templateUrl));
  } catch (err) {
    Notification.error(err);
    yield put(getDowloadExcelTemplateSuccessError(err));
  }
}

function* fetchUploadExcel(action) {
  try {
    const { excel } = action;
    const response = yield call(uploadExcelSdk, excel);
    yield put(uploadExecelSuccess(response));
  } catch (err) {
    Notification.error('Erro na tabela!');
    yield put(uploadExcelError(err));
  }
}

function* fetchPutRelationship(action) {
  try {
    const { modifiedCell } = action;
    const response = yield call(putRelationshipSdk, modifiedCell);
    yield put(putRelationshipSuccess(response));
  } catch (err) {
    Notification.error(err.message);
    yield put(putRelationshipError(err));
  }
}

function* fetchGetTableValidation(action) {
  try {
    const { idTable, onlyErrors, rowsByPage, pageToGo } = action;
    let table = yield call(getTableValidationSdk, idTable, onlyErrors, rowsByPage, pageToGo);
    yield put(getTableValidationSuccess(table));
  } catch (err) {
    Notification.error(err);
    yield put(getTableValidationError(err));
  }
}

function* fetchPostSaveAllCompanies(action) {
  try {
    const { idTable } = action;
    const response = yield call(postSaveAllCompaniesSdk, idTable);
    if (response?.message) {
      Notification.warning(response.message);
    }
    yield put(postSaveAllCompaniesSuccess(response));
  } catch (err) {
    Notification.error(err);
    yield put(postSaveAllCompaniesError(err));
  }
}

function* fetchPutEditValueFromTableValidation(action) {
  try {
    const { body } = action;
    const response = yield call(putEditValueFromTableValidationSdk, body);
    yield put(putEditValueFromTableValidationSuccess(response));
  } catch (err) {
    Notification.error(err.message);
    yield put(putEditValueFromTableValidationError(err));
  }
}

function* fetchDelCompanyImport(action) {
  try {
    const { idTable } = action;
    const response = yield call(deleteCompanyImportSdk, idTable);
    yield put(delCompanyImportSuccess(response));
  } catch (err) {
    Notification.error(err);
    yield put(delCompanyImportError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_DOWLOAD_EXCEL_TEMPLATE, fetchGetDowloadExcelTemplate),
  takeLatest(Types.UPLOAD_EXCEL, fetchUploadExcel),
  takeLatest(Types.PUT_RELATIONSHIP, fetchPutRelationship),
  takeLatest(Types.GET_TABLE_VALIDATION, fetchGetTableValidation),
  takeLatest(Types.POST_SAVE_ALL_COMPANIES, fetchPostSaveAllCompanies),
  takeLatest(Types.PUT_EDIT_VALUE_FROM_TABLE_VALIDATION, fetchPutEditValueFromTableValidation),
  takeLatest(Types.DEL_COMPANY_IMPORT, fetchDelCompanyImport),
];

// Reducer
const initialState = Map({
  templateUrl: Map(),
  loadingTemplateUrl: false,
  successTemplateUrl: false,
  errorTemplateUrl: false,

  uploadExcelResponse: List(),
  loadingUploadExcel: false,
  successUploadExcel: false,
  errorUploadExcel: false,

  putRelationshipResponse: List(),
  loadingPutRelationship: false,
  successPutRelationship: false,
  errorPutRelationship: false,

  tableValidation: List(),
  loadingTableValidation: false,
  successTableValidation: false,
  errorTableValidation: false,

  loadingSaveAllCompanies: false,
  successSaveAllCompanies: false,
  errorSaveAllCompanies: false,

  loadingEditValueFromTableValidation: false,
  successEditValueFromTableValidation: false,
  errorEditValueFromTableValidation: false,

  loadingDelCompanyImport: false,
  successDelCompanyImport: false,
  errorDelCompanyImport: false,

});

const handleGetDowloadExcelTemplate = (state) => {
  return state.set('loadingTemplateUrl', true).set('successTemplateUrl', false).set('errorTemplateUrl', false);
};

const handleGetDowloadExcelTemplateSuccess = (state, action) => {
  const { templateUrl } = action;
  return state.set('templateUrl', templateUrl).set('loadingTemplateUrl', false).set('successTemplateUrl', true).set('errorTemplateUrl', false);
};

const handleGetDowloadExcelTemplateError = (state, action) => {
  return state.set('loadingTemplateUrl', false).set('successTemplateUrl', false).set('errorTemplateUrl', action.error);
};

const handleUploadExcel = (state, action) => {
  return state.set('loadingUploadExcel', true).set('successUploadExcel', false).set('errorUploadExcel', false);
};

const handleUploadExcelSuccess = (state, action) => {
  const { response } = action;
  return state.set('uploadExcelResponse', response).set('loadingUploadExcel', false).set('successUploadExcel', true).set('errorUploadExcel', false);
};

const handleUploadExcelError = (state, action) => {
  return state.set('loadingUploadExcel', false).set('successUploadExcel', false).set('errorUploadExcel', action.error);
};

const handlePutRelationship = (state, action) => {
  return state.set('loadingPutRelationship', true).set('successPutRelationship', false).set('errorPutRelationship', false);
};

const handleDeleteRelationship = (state, action) => {
  const { response } = action;
  return state.set('putRelationshipResponse', response).set('loadingPutRelationship', false).set('successPutRelationship', true).set('errorPutRelationship', false);
};

const handlePutRelationshipError = (state, action) => {
  return state.set('loadingPutRelationship', false).set('successPutRelationship', false).set('errorPutRelationship', action.error);
};

const handleGetTableValidation = (state, action) => {
  return state.set('loadingTableValidation', true).set('successTableValidation', false).set('errorTableValidation', false);
};

const handleGetTableValidationSuccess = (state, action) => {
  const { table } = action;
  return state.set('tableValidation', table).set('loadingTableValidation', false).set('successTableValidation', true).set('errorTableValidation', false);
};

const handleGetTableValidationError = (state, action) => {
  return state.set('loadingTableValidation', false).set('successTableValidation', false).set('errorTableValidation', action.error);
};

const handlePostSaveAllCompanies = (state, action) => {
  return state.set('loadingSaveAllCompanies', true).set('successSaveAllCompanies', false).set('errorSaveAllCompanies', false);
};

const handlePostSaveAllCompaniesSuccess = (state, action) => {
  return state.set('loadingSaveAllCompanies', false).set('successSaveAllCompanies', true).set('errorSaveAllCompanies', false);
};

const handlePostSaveAllCompaniesError = (state, action) => {
  return state.set('loadingSaveAllCompanies', false).set('successSaveAllCompanies', false).set('errorSaveAllCompanies', action.error);
};

const handlePutEditValueFromTableValidation = (state, action) => {
  return state
    .set('loadingEditValueFromTableValidation', true).set('successEditValueFromTableValidation', false).set('errorEditValueFromTableValidation', false);
};

const handlePutEditValueFromTableValidationSuccess = (state, action) => {
  const { response } = action;
  const tableValidation = state.get('tableValidation');
  
  return state
    .set('loadingEditValueFromTableValidation', false)
    .set('tableValidation', {
      ...tableValidation,
      cellWithErrors: response.cellWithErrors, 
      rows: tableValidation.rows.map(({ importCompanyRow, values }) => {
        const responseForRow = response.importCompanyValue.find((resp) => resp.importCompanyRow === importCompanyRow);

        if (responseForRow) {
          return {
            importCompanyRow,
            values: values.map((item) => {
              const responseValue = responseForRow;
              
              if (item.importCompanyValueId === responseValue.importCompanyValueId) {
                return {
                  ...item,
                  value: responseValue.value,
                  importCompanyErrorId: responseValue.importCompanyErrorId,
                  importCompanyError: responseValue.importCompanyErrorId !== null ? { 
                    importCompanyErrorId: responseValue.importCompanyErrorId,
                    description: responseValue.importCompanyError.description
                  } : null
                };
              }
              return item;
            })
          };
        }
        return { importCompanyRow, values };
      })
    })
    .set('successEditValueFromTableValidation', true)
    .set('errorEditValueFromTableValidation', false);
};

const handlePutEditValueFromTableValidationError = (state, action) => {
  return state.set('loadingEditValueFromTableValidation', false).set('successEditValueFromTableValidation', false).set('errorEditValueFromTableValidation', action.error);
};

const handleDelCompanyImport = (state, action) => {
  return state.set('loadingDelCompanyImport', true).set('successDelCompanyImport', false).set('errorDelCompanyImport', false);
};

const handleDelCompanyImportSuccess = (state, action) => {
  return state.set('loadingDelCompanyImport', false).set('successDelCompanyImport', true).set('errorDelCompanyImport', false);
};

const handleDelCompanyImportError = (state, action) => {
  return state.set('loadingDelCompanyImport', false).set('successDelCompanyImport', false).set('errorDelCompanyImport', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    
    case Types.GET_DOWLOAD_EXCEL_TEMPLATE: return handleGetDowloadExcelTemplate(state);
    case Types.GET_DOWLOAD_EXCEL_TEMPLATE_SUCCESS: return handleGetDowloadExcelTemplateSuccess(state, action);
    case Types.GET_DOWLOAD_EXCEL_TEMPLATE_ERROR: return handleGetDowloadExcelTemplateError(state, action);

    case Types.UPLOAD_EXCEL: return handleUploadExcel(state);
    case Types.UPLOAD_EXCEL_SUCCESS: return handleUploadExcelSuccess(state, action);
    case Types.UPLOAD_EXCEL_ERROR: return handleUploadExcelError(state, action);

    case Types.PUT_RELATIONSHIP: return handlePutRelationship(state, action);
    case Types.PUT_RELATIONSHIP_SUCCESS: return handleDeleteRelationship(state, action);
    case Types.PUT_RELATIONSHIP_ERROR: return handlePutRelationshipError(state, action);

    case Types.GET_TABLE_VALIDATION: return handleGetTableValidation(state, action);
    case Types.GET_TABLE_VALIDATION_SUCCESS: return handleGetTableValidationSuccess(state, action);
    case Types.GET_TABLE_VALIDATION_ERROR: return handleGetTableValidationError(state, action);

    case Types.POST_SAVE_ALL_COMPANIES: return handlePostSaveAllCompanies(state, action);
    case Types.POST_SAVE_ALL_COMPANIES_SUCCESS: return handlePostSaveAllCompaniesSuccess(state, action);
    case Types.POST_SAVE_ALL_COMPANIES_ERROR: return handlePostSaveAllCompaniesError(state, action);

    case Types.PUT_EDIT_VALUE_FROM_TABLE_VALIDATION: return handlePutEditValueFromTableValidation(state, action);
    case Types.PUT_EDIT_VALUE_FROM_TABLE_VALIDATION_SUCCESS: return handlePutEditValueFromTableValidationSuccess(state, action);
    case Types.PUT_EDIT_VALUE_FROM_TABLE_VALIDATION_ERROR: return handlePutEditValueFromTableValidationError(state, action);
    
    case Types.DEL_COMPANY_IMPORT: return handleDelCompanyImport(state, action);
    case Types.DEL_COMPANY_IMPORT_SUCCESS: return handleDelCompanyImportSuccess(state, action);
    case Types.DEL_COMPANY_IMPORT_ERROR: return handleDelCompanyImportError(state, action);

    case Types.RESET_ALL_VALUES: return initialState;
    case Types.RESET_SUCCESS_DOWNLOAD_TEMPLATE: return state.set('loadingUploadExcel', false).set('successTemplateUrl', false).set('errorTemplateUrl', false);
    
    default: return state;
  }
}