/* eslint-disable indent */
import styled from 'styled-components';

const StatusDotIndicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ status }) => {
    if (status === 'success') {
      return 'var(--success-color)';
    }
    if (status === 'warning') {
      return 'var(--warning-color)';
    }
    if (status === 'danger') {
      return 'var(--danger-color)';
    }
  }};
`;

export default StatusDotIndicator;