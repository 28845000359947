import React from 'react';
import { CheckIcon, Children, StyledCheckbox, StyledCheckboxGroup } from './styles';
import { Row } from '@components-teammove';

const StyledCheckboxComponent = ({ checked, onChange, children, label, flexEnd, ...props }) => {
  const handleKeyPress = ({ which }) => {
    if (which === 13) {
      if (onChange) {
        onChange(!checked);
      }
    }
  };

  return label ? (
    <>
      <Row justify={flexEnd ? 'flex-end' : 'flex-start'}>
        <StyledCheckbox checked={checked} onClick={onChange ? () => onChange(!checked) : null} onKeyPress={handleKeyPress} tabIndex={0} {...props}>
          {checked && <CheckIcon type='check'/>}
        </StyledCheckbox>
        {label && (
          <Children>{label}</Children>
        )}
      </Row>
      {children}
    </>
  ) : (
    <Row justify={flexEnd ? 'flex-end' : 'flex-start'}>
      <StyledCheckbox checked={checked} onClick={onChange ? () => onChange(!checked) : null} onKeyPress={handleKeyPress} tabIndex={0} {...props}>
        {checked && <CheckIcon type='check'/>}
      </StyledCheckbox>
      {children && (
        <Children>{children}</Children>
      )}
    </Row>
  );
};

StyledCheckboxComponent.Group = ({ value = [], onChange, children, ...props }) => {
  const handleChange = (checkboxValue, checked) => {
    if (checked) {
      onChange(value !== null ? [...value, checkboxValue] : [checkboxValue]);
    } else {
      onChange(value.filter((val) => val !== checkboxValue));
    }
  };

  return(
    <StyledCheckboxGroup {...props}>
      {React.Children.map(children, (child) => (
        React.cloneElement(child, { onChange: (checked) => handleChange(child.props.value, checked), checked: value?.includes(child.props.value), value: child.props.value })
      ))}
    </StyledCheckboxGroup>
  );
};

export default StyledCheckboxComponent;