import React, { useState } from 'react';
import App from '@app';
import { Body, PageHeader, Column, Tabs, Breadcrumb, Button, Form, Text } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { Session, TelasNomenclaturas, useSearchParams, InfoUsuario } from '@utils';
import { DelinquencyImport } from './DelinquencyImport';
import { putDelinquencyConfiguration } from '../../../store/ducks/companyDelinquencyConfiguration';

export default function ConfiguracoesUnidades() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const loadingDelinquencyConfigurationSave = useSelector(({ companyDelinquencyConfiguration }) => companyDelinquencyConfiguration.get('loadingDelinquencyConfigurationSave'));

  const { initialTab } = useSearchParams();
  
  const [currentTab, setCurrentTab] = useState(initialTab || Session.get('CONFIG_INDICATORS_TAB') || 'Posição Financeira');

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set('CONFIG_INDICATORS_TAB', newTab);
  };

  const ExtraInadimplencia = () => [
    <Button 
      key="1" 
      type="primary" 
      size='small' 
      context='header' 
      onClick={() => handleSaveDelinquencyConfiguration(form)}
      loading={loadingDelinquencyConfigurationSave}
    >
      Salvar
    </Button>,
  ];

  const handleSaveDelinquencyConfiguration = (form) => {
    form.validateFields().then((values) => {
      dispatch(putDelinquencyConfiguration(values));
    });
  };

  const tabs = [
    InfoUsuario.perm('configuracaoPosicaoFinanceira') && { name: 'Posição Financeira' }
  ].filter(Boolean);

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={companyNomenclature.plural}
          extra={currentTab === 'Posição Financeira' && <ExtraInadimplencia/> }
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/indicadores', name: 'Configurações de indicadores personalizados', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          {tabs.length ? (
            <Column gap='12px'>
              <Tabs 
                options={tabs} 
                current={currentTab} 
                onChange={handleChangeTab}
              />
              {currentTab === 'Posição Financeira' && (
                <DelinquencyImport form={form}/>
              )}
            </Column>
          ) : (
            <Text>Não há indicadores disponíveis para configuração</Text>
          )}
        </PageHeader>
      </Body>
    </App>
  );
}
