import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification, InfoUsuario } from '@utils';
import { 
  getChecklists as getAtividadesChecklistsSdk,
  getAtividadesChecklists as getAtividadesChecklistsForPptSdk,
  updateChecklistStatus as updateChecklistStatusSdk,
  getCountChecklist as getCountChecklistSdk,
  getCountAcceptance as getCountAcceptanceSdk,
  getExportPdf as exportPdfSdk,
} from '@sdk/AtividadesChecklists';

export const FILTERS_KEY = 'ATIVIDADES_CHECKLISTS';

//Action Types
export const Types = {
  GET_CHECKLISTS: 'GET_CHECKLISTS',
  GET_CHECKLISTS_SUCCESS: 'GET_CHECKLISTS_SUCCESS',
  GET_CHECKLISTS_ERROR: 'GET_CHECKLISTS_ERROR',

  GET_CHECKLISTS_FOR_PPT: 'GET_CHECKLISTS_FOR_PPT',
  GET_CHECKLISTS_FOR_PPT_SUCCESS: 'GET_CHECKLISTS_FOR_PPT_SUCCESS',
  GET_CHECKLISTS_FOR_PPT_ERROR: 'GET_CHECKLISTS_FOR_PPT_ERROR',

  UPDATE_CHECKLIST_STATUS: 'UPDATE_CHECKLIST_STATUS',
  UPDATE_CHECKLIST_STATUS_SUCCESS: 'UPDATE_CHECKLIST_STATUS_SUCCESS',
  UPDATE_CHECKLIST_STATUS_ERROR: 'UPDATE_CHECKLIST_STATUS_ERROR',

  GET_COUNT_ACCEPTANCE: 'GET_COUNT_ACCEPTANCE',
  GET_COUNT_ACCEPTANCE_SUCCESS: 'GET_COUNT_ACCEPTANCE_SUCCESS',
  GET_COUNT_ACCEPTANCE_ERROR: 'GET_COUNT_ACCEPTANCE_ERROR',

  EXPORT_PDF: 'EXPORT_PDF',
  EXPORT_PDF_SUCCESS: 'EXPORT_PDF_SUCCESS',
  EXPORT_PDF_ERROR: 'EXPORT_PDF_ERROR',

  RESET_DOWNLOAD_PDF: 'RESET_DOWNLOAD_PDF',
};

//Action Creators
export const getChecklists = (params, loaders) => ({ type: Types.GET_CHECKLISTS, params, loaders });
export const getChecklistsSuccess = (checklists, totalChecklist) => ({ type: Types.GET_CHECKLISTS_SUCCESS, checklists, totalChecklist });
export const getChecklistsError = (error) => ({ type: Types.GET_CHECKLISTS_ERROR, error });

export const getChecklistsForPpt = (params, loaders) => ({ type: Types.GET_CHECKLISTS_FOR_PPT, params, loaders });
export const getChecklistsForPptSuccess = (checklists) => ({ type: Types.GET_CHECKLISTS_FOR_PPT_SUCCESS, checklists });
export const getChecklistsForPptError = (error) => ({ type: Types.GET_CHECKLISTS_FOR_PPT_ERROR, error });

export const updateChecklistStatus = (body) => ({ type: Types.UPDATE_CHECKLIST_STATUS, body });
export const updateChecklistStatusSuccess = (response) => ({ type: Types.UPDATE_CHECKLIST_STATUS_SUCCESS, response });
export const updateChecklistStatusError = (error) => ({ type: Types.UPDATE_CHECKLIST_STATUS_ERROR, error });

export const getCountAcceptance = (params) => ({ type: Types.GET_COUNT_ACCEPTANCE, params });
export const getCountAcceptanceSuccess = (response, totalChecklist) => ({ type: Types.GET_COUNT_ACCEPTANCE_SUCCESS, response, totalChecklist });
export const getCountAcceptanceError = (error) => ({ type: Types.GET_COUNT_ACCEPTANCE_ERROR, error });

export const exportPdf = (params) => ({ type: Types.EXPORT_PDF, params });
export const exportPdfSuccess = (pdfData) => ({ type: Types.EXPORT_PDF_SUCCESS, pdfData });
export const exportPdfError = (error) => ({ type: Types.EXPORT_PDF_ERROR, error });

export const resetDownloadPdf = () => ({ type: Types.RESET_DOWNLOAD_PDF });

//saga
function* fetchChecklists(action) {
  try { 
    const { params, loaders } = action;
    const checklists = yield call(getAtividadesChecklistsSdk, params, loaders);
    const totalChecklist = yield call(getCountChecklistSdk, params);
    yield put(getChecklistsSuccess(checklists, totalChecklist));

  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistsError(err));
  }
}

function* fetchChecklistsForPpt(action) {
  try {
    const { params, loaders } = action;
    const checklists = yield call(getAtividadesChecklistsForPptSdk, params, loaders);
    yield put(getChecklistsForPptSuccess(checklists));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistsForPptError(err));
  }
}

function* fetchUpdateChecklistStatus(action) {
  const { body } = action;
  try {
    const response = yield call(updateChecklistStatusSdk, body);
    yield put(updateChecklistStatusSuccess(response));
  } catch (err) {
    Notification.error(err.message);
    yield put(updateChecklistStatusError(err));
  }
}

function* fetchGetCountAcceptance(action) {
  try {
    const { params } = action;
    const response = yield call(getCountAcceptanceSdk, params);
    const totalChecklist = yield call(getCountChecklistSdk, params);
    yield put(getCountAcceptanceSuccess(response, totalChecklist));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCountAcceptanceError(err));
  }
}

function* fetchExportPdf(action) {
  try {
    const { params } = action;
    const pdfData = yield call(exportPdfSdk, params);
    yield put(exportPdfSuccess(pdfData));
  } catch (err) {
    Notification.error(err.message);
    yield put(exportPdfError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CHECKLISTS, fetchChecklists),
  takeLatest(Types.GET_CHECKLISTS_FOR_PPT, fetchChecklistsForPpt),
  takeLatest(Types.UPDATE_CHECKLIST_STATUS, fetchUpdateChecklistStatus),
  takeLatest(Types.GET_COUNT_ACCEPTANCE, fetchGetCountAcceptance),
  takeLatest(Types.EXPORT_PDF, fetchExportPdf),
];

// Reducer
const initialState = Map({  
  powerPoints: List(),
  totalChecklists: null,
  hasAnyApprovableChecklist:null,

  loadingChecklists: false,
  successChecklists: false,
  errorChecklists: false,

  checklistsForPpt: List(),
  loadingChecklistsForPpt: false,
  successChecklistsForPpt: false,
  errorChecklistsForPpt: false,

  loadingUpdateChecklistStatus: false,
  successUpdateChecklistStatus: false,
  errorUpdateChecklistStatus: false,

  countAcceptance: List(),
  loadingCountAcceptance: false,
  successCountAcceptance: false,
  errorCountAcceptance: false,

  pdfData: null,
  loadingExportPdf: false,
  successExportPdf: false,
  errorExportPdf: false,
});

const handleGetChecklists = (state, action) => {
  return state    
    .set('loadingChecklists', true)
    .set('successChecklists', false)
    .set('errorChecklists', false);
};

const handleGetChecklistsSuccess = (state, action) => {
  const { checklists, totalChecklist } = action;

  return state
    .set('powerPoints', checklists)
    .set('totalChecklists', parseInt(totalChecklist.totalChecklist))
    .set('hasAnyApprovableChecklist', parseInt(totalChecklist.hasAnyApprovableChecklist))
    .set('loadingChecklists', false)
    .set('successChecklists', true)
    .set('errorChecklists', false);
};

const handleGetChecklistsError = (state, action) => {
  return state
    .set('loadingChecklists', false)
    .set('successChecklists', false)
    .set('errorChecklists', action.error);
};

const handleGetChecklistsForPpt = (state, action) => {
  return state    
    .set('loadingChecklistsForPpt', true)
    .set('successChecklistsForPpt', false)
    .set('errorChecklistsForPpt', false);
};

const handleGetChecklistsForPptSuccess = (state, action) => {
  const { checklists } = action;

  return state
    .set('checklistsForPpt', checklists)
    .set('loadingChecklistsForPpt', false)
    .set('successChecklistsForPpt', true)
    .set('errorChecklistsForPpt', false);
};

const handleGetChecklistsForPptError = (state, action) => {
  return state
    .set('loadingChecklistsForPpt', false)
    .set('successChecklistsForPpt', false)
    .set('errorChecklistsForPpt', action.error);
};

const handleUpdateChecklistStatus = (state) => {
  return state  
    .set('loadingUpdateChecklistStatus', true)
    .set('successUpdateChecklistStatus', false)
    .set('errorUpdateChecklistStatus', false);
};

const handleUpdateChecklistStatusSuccess = (state, action) => {
  const { response } = action;
  return state
    .set('powerPoints', state.get('powerPoints').map((item) => {
      if (item.checklistId === response.checklistId) {
        return { ...item, updatedAtAcceptance:response.updatedAt, statusAcceptance: response.status, observationAcceptance: response.observation, userNameAcceptance: InfoUsuario.get('nmUsuario') };
      }
      return item;
    })) 
    .set('loadingUpdateChecklistStatus', false)
    .set('successUpdateChecklistStatus', true)
    .set('errorUpdateChecklistStatus', false);
};

const handleUpdateChecklistStatusError = (state, action) => {
  return state
    .set('loadingUpdateChecklistStatus', false)
    .set('successUpdateChecklistStatus', false)
    .set('errorUpdateChecklistStatus', action.error);
};

const handleGetCountAcceptance = (state) => {
  return state
    .set('loadingCountAcceptance', true)
    .set('successCountAcceptance', false)
    .set('errorCountAcceptance', false);
};

const handleGetCountAcceptanceSuccess = (state, action) => {
  const { response, totalChecklist } = action;
  return state
    .set('countAcceptance', { ...response, Total:totalChecklist.totalChecklist })
    .set('loadingCountAcceptance', false)
    .set('successCountAcceptance', true)
    .set('errorCountAcceptance', false);
};

const handleGetCountAcceptanceError = (state, action) => {
  return state
    .set('loadingCountAcceptance', false)
    .set('successCountAcceptance', false)
    .set('errorCountAcceptance', action.error);
};

const handleExportPdf = (state) => {
  return state
    .set('loadingExportPdf', true)
    .set('successExportPdf', false)
    .set('errorExportPdf', false);
};

const handleExportPdfSuccess = (state, action) => {
  const { pdfData } = action;
  return state
    .set('pdfData', pdfData)
    .set('loadingExportPdf', false)
    .set('successExportPdf', true)
    .set('errorExportPdf', false);
};

const handleExportPdfError = (state, action) => {
  return state
    .set('loadingExportPdf', false)
    .set('successExportPdf', false)
    .set('errorExportPdf', action.error);
};

const handleResetDownloadPdf = (state) => {
  return state
    .set('pdfData', null)
    .set('loadingExportPdf', false)
    .set('successExportPdf', false)
    .set('errorExportPdf', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHECKLISTS: return handleGetChecklists(state, action);
    case Types.GET_CHECKLISTS_SUCCESS: return handleGetChecklistsSuccess(state, action);
    case Types.GET_CHECKLISTS_ERROR: return handleGetChecklistsError(state, action);

    case Types.GET_CHECKLISTS_FOR_PPT: return handleGetChecklistsForPpt(state, action);
    case Types.GET_CHECKLISTS_FOR_PPT_SUCCESS: return handleGetChecklistsForPptSuccess(state, action);
    case Types.GET_CHECKLISTS_FOR_PPT_ERROR: return handleGetChecklistsForPptError(state, action);

    case Types.UPDATE_CHECKLIST_STATUS: return handleUpdateChecklistStatus(state, action);
    case Types.UPDATE_CHECKLIST_STATUS_SUCCESS: return handleUpdateChecklistStatusSuccess(state, action);
    case Types.UPDATE_CHECKLIST_STATUS_ERROR: return handleUpdateChecklistStatusError(state, action);

    case Types.GET_COUNT_ACCEPTANCE: return handleGetCountAcceptance(state, action);
    case Types.GET_COUNT_ACCEPTANCE_SUCCESS: return handleGetCountAcceptanceSuccess(state, action);
    case Types.GET_COUNT_ACCEPTANCE_ERROR: return handleGetCountAcceptanceError(state, action);

    case Types.EXPORT_PDF: return handleExportPdf(state, action);
    case Types.EXPORT_PDF_SUCCESS: return handleExportPdfSuccess(state, action);
    case Types.EXPORT_PDF_ERROR: return handleExportPdfError(state, action);

    case Types.RESET_DOWNLOAD_PDF: return handleResetDownloadPdf(state, action);

    default: 
      return state;
  }
}
