import React, { useEffect, useRef, useState } from 'react';
import { Column, InputMask, Dialer, Text, Switch, Row, Skeleton, Tooltip } from '@components-teammove';
import { SubTitle, Title , FormContainer , ActionButton , IconMDI, SwitchFormItem, FormDialer, FormInputMask } from './styles';
import { useSelector } from 'react-redux';

const PreparationCall = ({ form, onClose, onCalling, info, disabledButton, isConnectionReady }) => {
  const numberInputRef = useRef(null);
  const { number, name, EnableCallRecording } = info;
  const loadingSaveConfigurationVoip = useSelector(({ configuracoesVoip }) => configuracoesVoip.get('loadingSaveConfigurationVoip'));
  const loadingPostSaveCall = useSelector(({ voip }) => voip.get('loadingPostSaveCall'));

  const [isValidNumber, setIsValidNumber] = useState(true);
  const [maskNumber, setMaskNumber] = useState(number && number.replace(/\D/g, '').length === 11 ? 'Celular' : 'Telefone');
  const [valueNumberLength, setValueNumberLength] = useState(number && number.replace(/\D/g, '').length);

  useEffect(() => {
    form.setFieldsValue({ ActiveCallRecord: false });
  }, []);

  useEffect(() => {
    if(number) {
      form.setFieldsValue({ numberCalling: number, ActiveCallRecord: EnableCallRecording === 'Sim' });
    }
  }, [number, EnableCallRecording]);

  useEffect(() => {
    valueNumberLength === 10 || valueNumberLength === 11 ? setIsValidNumber(true) : setIsValidNumber(false);
  }, [valueNumberLength]);

  const handleCalling = () => {
    if(disabledButton || !isValidNumber || !isConnectionReady) return;
    form.validateFields().then((values) => {
      const numberToSend = maskNumber === 'Celular'
        ? values.numberCalling.replace(/\D/g, '').slice(0, 11)
        : values.numberCalling.replace(/\D/g, '').slice(0, 10);
      onCalling(`+55${numberToSend}`);
    });
  };

  const handleKeyUp = (event) => {
    const { value } = event.target;
    const { key } = event;
    const valueFormated = value.replace(/\D/g, '');
    const isCelularValid = maskNumber === 'Celular' && valueFormated.length === 11;
    const isTelefoneValid = maskNumber === 'Telefone' && valueFormated.length === 10;
    const isBackspaceKey = key === 'Backspace';
    const isEnterKey = key === 'Enter';

    if (!/^[0-9]$/.test(key) && !isEnterKey && !isBackspaceKey) {
      return;
    }

    if (isEnterKey && (isCelularValid || isTelefoneValid)) {
      handleCalling();
    }

    if(valueFormated.length === 10 && valueNumberLength === 10) {
      setMaskNumber('Celular');
      form.setFieldsValue({ numberCalling: valueFormated + key });
      setValueNumberLength(valueFormated.length + 1);
    } else {
      if(isBackspaceKey) {
        setMaskNumber('Telefone');
      }
      setValueNumberLength(valueFormated.length);
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('Text');
    const cleanedData = pastedData.replace(/\D/g, '');

    cleanedData.length === 11 ? setMaskNumber('Celular') : setMaskNumber('Telefone');
    numberInputRef.current.value = cleanedData;
    setIsValidNumber(cleanedData.length === 10 || cleanedData.length === 11);
  };

  const handleChangeNumberDialer = (newNumber) => {
    let newNumberFormated = '';

    if(newNumber.length > 10) {
      newNumberFormated = newNumber.replace(/\D/g, '').slice(0, 11);
      setMaskNumber('Celular');
    } else {
      newNumberFormated = newNumber.replace(/\D/g, '').slice(0, 10);
      setMaskNumber('Telefone');
    }

    form.setFieldsValue({ numberCalling: newNumberFormated });
    setValueNumberLength(newNumberFormated.length);
    setTimeout(() => {
      if (numberInputRef.current) {
        numberInputRef.current.focus();
      }
    }, 100);
  };

  return (
    <Column align={loadingSaveConfigurationVoip ? 'start' : 'center'} gap='12px'>
      <SubTitle marginSubTitle>{name}</SubTitle>
      <Title>Realizar ligação</Title>
      <FormContainer form={form}>
        <FormInputMask name='numberCalling'>
          <InputMask
            inputRef={(input) => numberInputRef.current = input}
            placeholder={`(99) ${maskNumber === 'Celular' ? '9' : ''}9999-9999`} 
            mask={`(99) ${maskNumber === 'Celular' ? '9' : ''}9999-9999`}
            autoFocus
            onKeyUp={handleKeyUp}
            onPaste={handlePaste}
            disabled={loadingSaveConfigurationVoip}
          />
        </FormInputMask> 
        <FormDialer shouldUpdate>
          {({ getFieldValue }) => (
            <Dialer
              disabledSpecialNumbers 
              onChange={handleChangeNumberDialer} 
              value={getFieldValue('numberCalling')}
            />
          )}    
        </FormDialer>
      </FormContainer>    
      <Tooltip title={disabledButton ? 'Você deve ativar o microfone nas configurações do seu navegador' : ''}>
        <ActionButton disabled={disabledButton || !isValidNumber || !isConnectionReady || loadingPostSaveCall} callingButton onClick={handleCalling}>
          <IconMDI type='phone'/>
          <Text>Ligar</Text>
        </ActionButton>
      </Tooltip>
      <ActionButton onClick={onClose}>
        <Text>Cancelar</Text>
      </ActionButton>
      {loadingSaveConfigurationVoip ? (
        <Column gap='8px'> 
          <Skeleton.MinorLine/> 
          <Skeleton.MinorLine/> 
          <Skeleton.MinorLine/> 
        </Column>
      ) : (
        <FormContainer form={form}>
          {EnableCallRecording === 'Opcional' && (
            <Row gap='16px' align='center' width='100%'>
              <SwitchFormItem name='ActiveCallRecord' valuePropName='checked'>           
                <Switch/>          
              </SwitchFormItem>
              <Text>Habilitar gravação</Text>
            </Row>        
          )}
          {/* {EnableCallTextTranscription === 'Opcional' && (
            <SwitchFormItem name='ActiveCallTextTranscription'>
              <Row gap='16px' align='center'>
                <Switch/>
                <Text>Habilitar transcrição</Text>
              </Row>
            </SwitchFormItem>
          )}
          {EnableCallTextTranscriptionHighlights === 'Opcional' && (
            <SwitchFormItem name='ActiveCallTranscriptionHighlight'>
              <Row gap='16px' align='center'>
                <Switch/>
                <Text>Habilitar highlights</Text>
              </Row>
            </SwitchFormItem>    
          )} */}
        </FormContainer>
      )}
    </Column>
  );
};

export default PreparationCall;