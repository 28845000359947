import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Empty, List, Text, Overlay, Row } from '@components-teammove';
import { InfoUsuario, moment, ansiToMoment } from '@utils';
import { marcarLida } from '@ducks/notificacoes';
import { getIsComunicado } from '@sdk/Wikis';
import NotificacaoItem from './NotificacaoItem';
import { ContainerNotification } from '../styles';
import { CloseIcon, ScrollContainer, StyledNotificationContainer, BellIcon, BadgeNotification } from './styles';

const Notificacoes = ({ infoUsuario }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const handleOpenModal = () => setVisible(true);

  const notificacoes = useSelector((state) => state.notificacoes.get('notificacoes').reverse().toList());
  const loadingNotificacoes = useSelector((state) => state.notificacoes.get('loadingNotificacoes'));

  const oneMonthAgo = moment().subtract(1, 'month');
  const notificacoesLimitadas = notificacoes
    .filter((notificacao) => ansiToMoment(notificacao.datetime).isAfter(oneMonthAgo))
    .slice(0, 40);

  const numNaoLidas = notificacoesLimitadas.count((item) => !item.lida);

  const handleMarcarLida = (notificacao) => {
    const acceptedPages = {
      WIKI(notificacao) {
        const { id } = notificacao.data;
        getIsComunicado(id).then((isComunicado) => {
          if (isComunicado) {
            history.push(`/comunicado/${id}`);
          } else {
            history.push(`/conteudos/visualizacao/${id}`);
          }
        });
      },
      CHAMADO(notificacao) {
        const { id } = notificacao.data;
        history.push(`/chamados/tickets/${id}`);
      },
    };

    dispatch(marcarLida(notificacao.id));

    const { tipo } = notificacao.data;
    const moveToPage = acceptedPages[tipo];
    if (moveToPage) {
      moveToPage(notificacao);
      handleClose();
    }
  };

  const handleClose = () => {
    setIsAnimating(true); 
    setTimeout(() => {
      setVisible(false); 
      setIsAnimating(false); 
    }, 500);
  };

  return InfoUsuario.hasUser(infoUsuario) ? (
    <ContainerNotification>
      <BadgeNotification count={numNaoLidas} dot offset={[-3, 3]} onClick={handleOpenModal}>
        <Row gap='15px'>
          <BellIcon type='bell-outline'/>
        </Row>
      </BadgeNotification>

      {visible && (
        <Overlay noBackground onClick={handleClose}>
          <StyledNotificationContainer isVisible={visible && !isAnimating} onClick={(e) => e.stopPropagation()}>
            <Row justify='space-between' width='100%' align='center' padding='0 0 0 10px'>
              <Row gap='10px' align='center'>
                <Text>Notificações </Text>
                <BadgeNotification count={numNaoLidas}/>
              </Row>
              <CloseIcon type='x' onClick={handleClose}/>
            </Row>
            <ScrollContainer>
              <List
                size='small'
                grid={{ gutter: 16, column: 1 }}
                noColor
                highlightColor
                loading={loadingNotificacoes}
                dataSource={notificacoesLimitadas}
                renderItem={(item) => (
                  <NotificacaoItem item={item} onMarcarLida={() => handleMarcarLida(item)} />
                )}
                locale={{
                  emptyText: <Empty description='Sem notificações para exibir' />,
                }}
              />
            </ScrollContainer>
          </StyledNotificationContainer>
        </Overlay>
      )}
    </ContainerNotification>
  ) : null;
};

export default Notificacoes;
