import styled from 'styled-components';
import { Input } from '@components-teammove';

export const InputStyled = styled(Input)`
    flex: 1 0 auto;
`;

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;
`;