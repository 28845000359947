import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app/';

import { 
  Icon, 
  Body, 
  PageHeader, 
  Breadcrumb, 
  ActiveFilters, 
  Button,
  Column, 
  Filter, 
  DateControllerHeader,
} from '@components-teammove';

import { TelasNomenclaturas, Filtros, mountDataTreeSelect, InfoUsuario } from '@utils';

import { getUsuarios } from '@sdk/Usuarios';
import { getCategorias } from '@sdk/Chamados';
import { getAllClassificationsWithOptions } from '@sdk/Classificacoes';
import { getCompaniesRanking, getCompaniesGraphic, FILTERS_KEY , PARAMS_KEY } from '@ducks/chamadosDashboard';

import CompaniesGraphic from './CompaniesGraphic';
import CompaniesRankingTable from './CompaniesRankingTable';

import { createInitialModalFilters } from '../../rules';

export default function CategoriesRankingDetails() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const dispatch = useDispatch();
  const usuario = InfoUsuario.get();
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');

  const companiesRanking = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('companiesRanking'));
  const loadingCompaniesRanking = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingCompaniesRanking'));
  const successCompaniesRanking = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successCompaniesRanking'));
  
  const [ visibleFilterModal, setVisibleFilterModal ] = useState(false);

  const [ currentFilters, setCurrentFilters ] = useState(Filtros.get(FILTERS_KEY));
  const [ currentParams, setCurrentParams ] = useState(Filtros.get(PARAMS_KEY));

  const [ filtersModal, setFiltersModal] = useState(createInitialModalFilters(companyNomenclature, ticketNomenclatures));
  const [ categoriasTree, setCategoriasTree] = useState();
  const [ usuarios, setUsuarios] = useState();
  const [ classificationsWithOptions, setClassificationsWithOptions] = useState();

  const [ topRange, setTopRange ] = useState(3);

  const [ isShowingInactiveCategories, setIsShowingInactiveCategories ] = useState(false);
  const [ categoriesValuesWithActiveAndInactive, setCategoriesValuesWithActiveAndInactive ] = useState();
  const [ categoriesValuesOnlyActive, setCategoriesValuesOnlyActive ] = useState();
  const [ limitTable, setLimitTable ] = useState(100);

  const getOptions = () => {
    return classificationsWithOptions?.map((classification) => ({
      type: 'SELECT',
      name: `idsClassificationsItens_${classification.id}`,
      label: classification.titulo + ' - Opções',
      placeholder: `Selecione ${classification.titulo}`,
      options:classification.opcoes.map((item)=> ({ value: item.id, label: item.titulo })),
      dependsOn:'idsClassifications',
      idDepends: classification.id,
    }));  
  };

  useEffect(()=> {
    (async () => {
      getUsuarios()
        .then((resp) => {
          setUsuarios(resp.filter((item) => usuario.hierarquia?.includes(item.key)).map(({ key, title }) => ({ value: key, label: title })));
        });
      getCategorias(false, false)
        .then((resp) => {        
          const response = resp.filter((item)=> item.ativo);
          const mountDataActive = mountDataTreeSelect(response, 'id', 'idCategoria', 'titulo');
          const mountData = mountDataTreeSelect(resp, 'id', 'idCategoria', 'titulo');
          setCategoriesValuesOnlyActive(mountDataActive);
          setCategoriesValuesWithActiveAndInactive(mountData);

          setCategoriasTree(!isShowingInactiveCategories ? mountDataActive : mountData);
        }); 
      getAllClassificationsWithOptions()
        .then((resp)=>{
          setClassificationsWithOptions(resp);
        });  
    })();
  },[]);

  useEffect(() => {
    if (usuarios && categoriasTree && classificationsWithOptions) {
      setFiltersModal([...filtersModal.map((filter) => {
        if (filter.name === 'idUserResponsible') {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'idUserCreation') {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'idCategory') {
          return { ...filter, options: categoriasTree };
        }
        if (filter.name === 'idsClassifications') {
          return { ...filter, 
            options: classificationsWithOptions?.map((classification) => ({
              value: classification.id,
              label: classification.titulo,
            })) 
          };
        }
        return { ...filter };
      }), ...getOptions()]);
    }
  }, [usuarios, categoriasTree, classificationsWithOptions]);

  useEffect(() => {
    dispatch(getCompaniesRanking({ ...currentFilters, ...currentParams }));
  }, []);

  useEffect(() => {
    Filtros.set(PARAMS_KEY, currentParams);
    dispatch(getCompaniesGraphic({ ...currentFilters, ...currentParams }, { limit:3 }));
    dispatch(getCompaniesRanking({ ...currentFilters, ...currentParams }, { limit: limitTable }));
  }, [currentParams]);
  
  useEffect(() => {
    dispatch(getCompaniesRanking({ ...currentFilters, ...currentParams }, { limit: limitTable }));
  }, [limitTable]);

  const handleSearch = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
    return dispatch(getCompaniesRanking({ ...filtros, ...currentParams }, { limit: limitTable }));
  };
  
  const handleSearchChangingNewFilters = (filtros) => {
    if (filtros?.descFiltros) {
      Object.keys(filtros.descFiltros).forEach((key) => {
        if (!filtros.descFiltros[key] || filtros.descFiltros[key].length === 0) {
          delete filtros.descFiltros[key];
        }
      });
    }
    setCurrentFilters(filtros);
    handleSearch(filtros);
  };
  
  const handleChangePeriod = ({ typePeriod, initialDate, formattedTypePeriod }) => {
    setCurrentParams({ ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
  };

  useEffect(() => {
    if(categoriesValuesWithActiveAndInactive) {
      setCategoriasTree(!isShowingInactiveCategories ? categoriesValuesOnlyActive : categoriesValuesWithActiveAndInactive);
    }
  },[isShowingInactiveCategories]);

  const handleActiveCategories = () => {
    setIsShowingInactiveCategories(!isShowingInactiveCategories);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={`Ranking de ${companyNomenclature.plural}`}
          onBack={() => window.history.back()}
          extra={[
            // <Button size='small' type='secondary' key='1' icon={<Icon.MDI type='upload' />} onClick={() => setVisibleFilterModal(true)}>
            // Exportar
            // </Button>,
            <Button size='small' type='secondary' key='2' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilterModal(true)}>
            Filtros
            </Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/chamados', name: `${companyNomenclature.plural}`, current: true  },
                { path: '/chamados/dashboard-chamados', name: `Dashboard de ${companyNomenclature.plural}` },
                { path: '/dashboard-chamados/ranking-companies-details', name: `Ranking de ${companyNomenclature.plural}`, current: true },
              ]}
            />
          )}
        >
          <ActiveFilters filters={filtersModal} filtros={currentFilters} onSearch={handleSearchChangingNewFilters}/>
          <Column gap='28px'>
            <DateControllerHeader 
              onChange={handleChangePeriod}
              typePeriod={currentParams.typePeriod}
              initialDate={currentParams.initialDate}
              formattedTypePeriod={currentParams.formattedRangeDate}
            />

            <Column gap='24px'>

              <CompaniesGraphic currentFilters={currentFilters} currentParams={currentParams} topRange={topRange} setTopRange={setTopRange}/>

              <Column gap='12px'>
                <CompaniesRankingTable
                  limit={limitTable} 
                  setLimit={setLimitTable}
                  companiesTotal={companiesRanking.totalRanking} 
                  companies={companiesRanking.companyRanking} 
                  loadingCompanies={loadingCompaniesRanking} 
                  successCompanies={successCompaniesRanking}
                />
              </Column>
            </Column>
          </Column>

        </PageHeader>
        {visibleFilterModal && (
          <Filter 
            visible={visibleFilterModal} 
            onClose={() => {setVisibleFilterModal(false);}}
            filters={filtersModal}
            filtros={currentFilters}
            onSearch={handleSearchChangingNewFilters}
            onChangeValues={{ idCategory: { change: handleActiveCategories, value: isShowingInactiveCategories } }}
          />
        )}
      </Body>
    </App>
  );
}
