import React, { useEffect, useState } from 'react';
import { Input, Column, View, Form, Text, Table } from '@components-teammove';
import { UploadEdge, UploadIcon, UploadText, ComponentEmptyCover } from './styles';
import { useDispatch } from 'react-redux';
import { InfoUsuario, moment, capitalize } from '@utils';
import { uploadProducts } from '@ducks/contentConfig';
import { authorization } from '../../../../sdk/api';

const { REACT_APP_API } = window.env;

const ImportacaoProdutos = () => {

  const dispatch = useDispatch();

  const [excelUrl, setExcelUrl] = useState();
  const [listsSuffix, setListsSuffix] = useState('');

  useEffect(() => {
    if (excelUrl) {
      dispatch(uploadProducts({ fileUrl: excelUrl, listsSuffix }));
    }
  }, [excelUrl]);

  const handleChangeAttachment = (info) => {
    if (info.file.status === 'done') {
      setExcelUrl(info.file.response);
    }
  };

  return (
    <Column gap='12px'>
      <Form layout='vertical'>
        <Form.Item label='Sufixo das listas de produtos'>
          <Input 
            placeholder={` - ${capitalize(moment().format('MMMM/YY'))}`}
            value={listsSuffix} 
            onChange={(evt) => setListsSuffix(evt.target.value)}
          />
        </Form.Item>
      </Form>
      <Column gap='20px'>
        <Text size='28px' weight='700'>Upload da planilha</Text>
        <Text size='18px' weight='400'>Esperamos que ela venha nesse formato:</Text>
        <Table
          columns={[
            {
              title: 'PRODUTO',
              dataIndex: 'product',
            },
            {
              title: 'PREÇO',
              dataIndex: 'price',
            },
            {
              title: 'ORDEM',
              dataIndex: 'order',
            }
          ]}
          dataSource={[
            { product: 'PRODUTO X', price: 'R$ 5,00', order: '1' },
            { product: 'PRODUTO Y', price: 'R$ 10,00', order: '2' },
          ]}
        />
      </Column>
      <UploadEdge
        name="file"
        showUploadList={false}
        folder={`importacao/produtos/${InfoUsuario.get('cdCliente')}`}
        accept=".xls, .xlsx"
        onChange={handleChangeAttachment}
        URI={REACT_APP_API}
        headers={authorization()}
      >
        <View>
          <ComponentEmptyCover>
            <UploadIcon type='upload' />
            <UploadText>Clique ou arraste para anexar</UploadText>
          </ComponentEmptyCover>
        </View>
      </UploadEdge>
      <Text size='14px' weight='400' italic>Cada página da planilha será tratada como uma lista diferente. O nome da lista será o nome da página + o sufixo (passado no campo acima)</Text>
    </Column>
  );
};

export default ImportacaoProdutos;