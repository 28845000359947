export const formatterToSetStatesInOptions = (
  allFields, 
  setConditionalAsks, 
  setIsObservacaoHintOpen, 
  setIsPlanoHintOpen, 
  setIsPesoDisabled,
  setAnexosOpcao
) => {
  const fieldTypes = [
    { prefix: 'tipo_observacao_opcao_', indexSplit: 3, stateUpdater: setIsObservacaoHintOpen, condition: (value) => value && value !== 'NAO_PERMITE' },
    { prefix: 'gerar_tarefa_opcao_', indexSplit: 3, stateUpdater: setIsPlanoHintOpen, condition: (value) => value && value !== 'NAO_PERMITE' },
    { prefix: 'peso_opcao_', indexSplit: 2, stateUpdater: setIsPesoDisabled, condition: (value) => value && value === 0 },
    { prefix: 'enabled_questions_opcao_', indexSplit: 3, stateUpdater: setConditionalAsks, condition: (value) => value && value.length > 0 },
    { prefix: 'tipo_upload_opcao_', indexSplit: 3, stateUpdater: setAnexosOpcao, condition: (value) => value && value !== 'NAO_PERMITE' },
  ];

  fieldTypes.forEach(({ prefix, indexSplit, stateUpdater, condition }) => {
    Object.keys(allFields).forEach((key) => {
      if (key.includes(prefix)) {
        const index = key.split('_')[indexSplit];
        const value = allFields[key];

        stateUpdater((prevState) => ({
          ...prevState,
          [index]: condition(value)
        }));
      }
    });
  });
};

export const optionsConditionalQuestion = (index, allFields, groups) => {
  const currentQuestionId = allFields.temp_id;
  const currentOptionId = allFields[`temp_id_opcao_${index}`];
  const selectedQuestionsIds = allFields[`enabled_questions_opcao_${index}`] || [];

  const groupOptions = groups.flatMap((group) =>
    group.perguntas
      .filter(
        (question) => !question.idPerguntaPai && question.temp_id !== currentQuestionId
      )
      .map((question) => ({ value: question.temp_id, label: question.titulo }))
  );

  const additionalOptions = selectedQuestionsIds
    .filter((selected) => selected !== currentOptionId && !groupOptions.some((option) => option.value === selected))
    .map((selected) => {
      const question = groups.flatMap((group) => group.perguntas).find((quest) => (quest.temp_id || quest.id) === selected);
      return { value: selected, label: question ? question.titulo : `Pergunta ${selected}` };
    });

  return [...groupOptions, ...additionalOptions];
};