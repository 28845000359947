import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Column, 
  DateControllerHeader,
} from '@components-teammove';

import { Filtros, moment } from '@utils';
import { EmptyGoalsCards } from './EmptyGoalsCards';
import { ActivitiesGoals } from './ActivitiesGoals';
import { ActivitiesGoalsPanel } from './ActivitiesGoalsPanel';
import { getActivityGoalDash, getActivitiesKinds, getCheckIfAnyGoal, PARAMS_KEY } from '@ducks/activitiesGoals';
import { getAtividades } from '@ducks/atividades';
import ActivitiesList from '../List';

export const Goals = ({ filtros, orderBy, setOrderBy, search, viewModeFromNotification }) => {
  const dispatch = useDispatch();

  const [ activeTypesList, setActiveTypesList ] = useState([]);
  const [ activeUsersList, setActiveUsersList ] = useState([]);
  const [ currentParams, setCurrentParams ] = useState({ ...Filtros.get(PARAMS_KEY), typePeriod:Filtros.get(PARAMS_KEY).typePeriod || 'DIARIO', initialDate: Filtros.get(PARAMS_KEY).initialDate || moment().format('YYYY-MM-DD') });
  const checkIfAnyGoal = useSelector(({ activitiesGoals }) => activitiesGoals.get('checkIfAnyGoal'));
  const successCheckIfAnyGoal = useSelector(({ activitiesGoals }) => activitiesGoals.get('successCheckIfAnyGoal'));
  const successSave = useSelector(({ atividades }) => atividades.get('successSave'));

  const handleChangePeriod = ({ typePeriod, initialDate, formattedTypePeriod }) => {
    Filtros.set(PARAMS_KEY, { ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
    setCurrentParams({ ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
  };
  
  useEffect(() => {
    setActiveTypesList([]);
    setActiveUsersList([]);
    dispatch(getActivityGoalDash(currentParams, filtros));
    dispatch(getActivitiesKinds(currentParams, filtros));
  },[currentParams, filtros]);

  useEffect(() => {
    dispatch(getCheckIfAnyGoal());
    if(viewModeFromNotification) {
      setCurrentParams({ ...Filtros.get(PARAMS_KEY), typePeriod: viewModeFromNotification });
    }
  },[]);
 
  const formatTimeUnit = (unit) => {
    return(
      unit === 'DIARIO' ? 'day' : unit === 'SEMANAL' ? 'week' : 'month'
    );
  };

  useEffect(() => {
    setActiveTypesList([]);
    setActiveUsersList([]);
    dispatch(getAtividades({
      filtros: { ...filtros, period: [moment(currentParams.initialDate), moment(currentParams.initialDate).endOf(formatTimeUnit(currentParams.typePeriod))] },
      search: search, 
      orderBy: orderBy?.field, 
      orderDirection: orderBy?.order, 
      limit: 50,
      allowGoals: true,
      useFinishedDateOnFinishedActivities: true,
    }));
  },[currentParams, filtros, search, orderBy, successSave]);

  useEffect(() => {
    if(successSave) {
      dispatch(getAtividades({
        filtros: { ...filtros, period: [moment(currentParams.initialDate), moment(currentParams.initialDate).endOf(formatTimeUnit(currentParams.typePeriod))] },
        search: search, 
        orderBy: orderBy?.field, 
        orderDirection: orderBy?.order, 
        limit: 50,
        allowGoals: true,
        useFinishedDateOnFinishedActivities: true,
      }));
    }
    
  },[successSave]);

  const handleFilterTypesPeriods = (typesPeriodsList) => {
    const updatedFiltros = {
      ...filtros,
      typesActivities: Array.isArray(typesPeriodsList) ? typesPeriodsList : undefined,
      period: [
        moment(currentParams.initialDate), 
        moment(currentParams.initialDate).endOf(formatTimeUnit(currentParams.typePeriod))
      ]
    };

    if (Array.isArray(activeUsersList) && activeUsersList.length > 0) {
      updatedFiltros.users = activeUsersList;
    }

    dispatch(getAtividades({
      filtros: updatedFiltros,
      search: search, 
      orderBy: orderBy?.field, 
      orderDirection: orderBy?.order, 
      limit: 50,
      allowGoals: true,
      useFinishedDateOnFinishedActivities: true
    }));

    dispatch(getActivityGoalDash(currentParams, { ...updatedFiltros, typesActivities: Array.isArray(typesPeriodsList) && typesPeriodsList.length > 0 ? typesPeriodsList : undefined }));
  };

  const handleFilterUsers = (userList) => {
    dispatch(getAtividades({
      filtros: { ...filtros, 
        users: Array.isArray(userList) && userList.length > 0 ? userList : undefined,
        typesActivities: Array.isArray(activeTypesList) ? activeTypesList : undefined,       
        period: [moment(currentParams.initialDate), moment(currentParams.initialDate).endOf(formatTimeUnit(currentParams.typePeriod))] },
      search: search, 
      orderBy: orderBy?.field, 
      orderDirection: orderBy?.order, 
      limit: 50,
      allowGoals: true,
      useFinishedDateOnFinishedActivities: true
    }));

    dispatch(getActivitiesKinds(currentParams, { ...filtros, users: Array.isArray(userList) && userList.length > 0 ? userList : undefined, typesActivities: Array.isArray(activeTypesList) ? activeTypesList : undefined }));
  };

  const handleClickTypeActivity = ({ id, groupActivityKinds }) => {
    const isCardActive = activeTypesList.find((item)=> item.id === id && item.groupActivityKinds.length === groupActivityKinds.length);
    const newList = isCardActive ? 
      activeTypesList.filter(({ id: activeTypeId, groupActivityKinds: activeTypeGroupActivityKinds })=> activeTypeId !== id && activeTypeGroupActivityKinds.length !== groupActivityKinds.length) : 
      [...activeTypesList, { id, groupActivityKinds }];
    setActiveTypesList(newList);
    handleFilterTypesPeriods(newList.flatMap(({ id, groupActivityKinds }) => groupActivityKinds?.length ? groupActivityKinds?.map(({ id }) => id) : id));
  };

  const handleClickUser = (id) => {
    const isCardActive = activeUsersList.find((item)=> item === id);
    const newList = isCardActive ? activeUsersList.filter((item)=> item !== id) : [...activeUsersList, id];
    setActiveUsersList(newList);
    handleFilterUsers(newList);
  };

  return(
    <>
      {!checkIfAnyGoal && successCheckIfAnyGoal ? <EmptyGoalsCards/> : (
        <Column gap='40px'>
          <Column gap='28px'>
            <DateControllerHeader
              renderOptionsAsButtons
              onChange={handleChangePeriod}
              typePeriod={currentParams.typePeriod}
              initialDate={currentParams.initialDate}
              formattedTypePeriod={currentParams.formattedRangeDate}
              defaultOptions={[
                { label: 'Diário', value: 'DIARIO' },
                { label: 'Semanal', value: 'SEMANAL' },
                { label: 'Mensal', value: 'MENSAL' },
              ]}
            />
            <Column gap='12px'>
              <ActivitiesGoalsPanel 
                onClick={handleClickUser}
                activeUsersList={activeUsersList}
              />
              <ActivitiesGoals
                onClick={handleClickTypeActivity}
                activeTypesList={activeTypesList} 
                currentFilters={filtros}
                typePeriod={currentParams.typePeriod} 
                initialDate={currentParams.initialDate}
              />
            </Column>
            <ActivitiesList orderBy={orderBy} setOrderBy={setOrderBy} allowGoals useFinishedDateOnFinishedActivities/>
          </Column>
        </Column> 
      )}
       
    </>
  );
};