import React from 'react';
import { Badge, Row } from '@components-teammove';
import { formatDateString } from '@utils';
import { DadosView, ListItem, TextStyled } from './styles';

const NotificacaoItem = ({ item, onMarcarLida }) => {
  const capitalizeString = (string) => {
    const lowerCaseString = string.toLowerCase();
    return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
  };
  
  return (
    <ListItem lida={item.lida} onClick={onMarcarLida}>
      <DadosView lida={item.lida} gap='4px'>
        <Row width='100%' align='center' justify='space-between'>
          <Row align='center'>
            {!item.lida && <Badge status='processing' />}
            <TextStyled type whiteSpace='normal' strong={!item.lida} ellipsis>
              {capitalizeString(item.data.tipo)}
            </TextStyled>
          </Row>
          <TextStyled date type='secondary'>{formatDateString(item.datetime)}</TextStyled>
        </Row>
        <TextStyled title whiteSpace='normal' strong={!item.lida} ellipsis>
          {item.title}
        </TextStyled>  
        <TextStyled body whiteSpace='normal'>{item.body}</TextStyled>
      </DadosView>
    </ListItem>
  );};

export default NotificacaoItem;
