import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { setNotificacoes, setNotificacao } from '@ducks/notificacoes';
import { InfoUsuario } from '@utils';

const { REACT_APP_NOTIFICATION, REACT_APP_API } = window.env;
let socket = null;

const NotificationService = () => {
  const dispatch = useDispatch();
  const infoUsuario = useSelector((state) => state.login.get('infoUsuario'));
  const successLogout = useSelector((state) => state.login.get('successLogout'));

  useEffect(() => {
    if (InfoUsuario.hasUser(infoUsuario) && (!socket)) {
      // socket = io(REACT_APP_NOTIFICATION, {
      //   query: {
      //     idUsuario: infoUsuario.cdUsuario,
      //     uniqueId: infoUsuario.uniqueId,
      //     api: REACT_APP_API,
      //     key: infoUsuario.key,
      //     token: infoUsuario.token,
      //   },
      // });
      // console.log('lalala');

      // socket.on('connect', () => {
      //   socket.on(infoUsuario.uniqueId, (notificacoes) => {
      //     dispatch(setNotificacoes(notificacoes));
      //   });

      //   socket.on(`${infoUsuario.uniqueId}/new`, (notificacao) => {
      //     dispatch(setNotificacao(notificacao));
      //   });
      // });
    }
  }, [infoUsuario]);

  useEffect(() => {
    if ((successLogout) && (socket)) {
      socket.disconnect();
      socket = null;
    }
  }, [successLogout]);

  return null;
};

export default NotificationService;
